import React from "react";
import styled from "styled-components";
import { PaystackButton } from "react-paystack";
import { useQueryClient } from "react-query";
import { useNavigate } from "react-router-dom";
import colours from "../styles/appColours";
import { toast } from "react-toastify";
import utils from "../utils/utils";


// interface PaystackDetails {
//   amount: number;
//   email: string;
//   reference:string;
//   metadata:any;
// }
const Paystack = ({
  amount,
  email,
  reference,
  paymentType="",
  userId=""
}) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate()
  const publicKey = process.env.REACT_APP_PUBLIC_PAYSTACK_PUBLIC_KEY;
  const payload = localStorage.getItem("NON-SUB-DELIVERY");
  const order = JSON.parse(payload);
  const componentProps = {
    email,
    amount,
    reference,
    publicKey,
    metadata:{
      paymentType,
      userId,
    },
    text: `Pay N${utils.formatAmount(amount / 100)}`,
    onSuccess: () => {
      toast.success("success");
      queryClient.invalidateQueries("USER_PROFILE");
      if(order){
        navigate('/checkout')
      }
    },
    onClose: () => {
      toast.error("Something went wrong. Try again");
    },
  };

  return (
    <>
      <Button {...componentProps} />
    </>
  );
};

export default Paystack;


const Button = styled(PaystackButton)`
  padding: 20px;
  color: ${colours.white};
  background-color: ${colours.black};
  width: 80%;
  margin: 0 auto;
  border: none;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 700;
`;