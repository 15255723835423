import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import {
  QueryClient,
  QueryClientProvider,
} from "react-query";
import { store } from "./redux/store";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { ToastContainer } from "react-toastify";
import ErrorPage from "./pages/ErrorPage";
import "react-toastify/dist/ReactToastify.css";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

const BugsnagApiKey = process.env.REACT_APP_BUGSNAG_API_KEY as string
const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontFamily: "poppins",
          color: "red",
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#47ECF5",
      // darker: '#053e85',
    },
    secondary: {
      main: "#F5F6F6",
    }
  },
  typography: {
    fontFamily: "Red Hat Display",
  },
});
Bugsnag.start({
  apiKey: BugsnagApiKey,
  plugins: [new BugsnagPluginReact()],
});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 2,
      enabled: true,
      cacheTime: 4 * 30 * 60 * 1000,
      refetchOnWindowFocus: false,
      refetchOnMount: false
    }
  }
});


const ErrorBoundary:any = Bugsnag.getPlugin("react")?.createErrorBoundary(React);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
       <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <Provider store={store}>
          <ThemeProvider theme={theme}>
          <ErrorBoundary FallbackComponent={ErrorPage}>
            <App />
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              draggable={false}
              // pauseOnVisibilityChange
              closeOnClick
              pauseOnHover
            />
          </ErrorBoundary>
          </ThemeProvider>
        </Provider>
      </BrowserRouter>
      </QueryClientProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
