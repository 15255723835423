import { useEffect } from "react";
import styled from "styled-components";
import colours from "../styles/appColours";
import Button from "./Button";

function FundWalletScreen() {
    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
    };

  return (
    <Wrapper>
      {/* <Title>Fund Wallet</Title> */}
      <img src={"/fundwallet.png"} width={354} height={354} alt="" />
      <Text>Please input your Card details below to fund your wallet.</Text>
      <Form>
        <Input placeholder="Amount" />
        <Input placeholder="Card number" />
        <SmallInput placeholder="Expiry date" />
        <SmallInput placeholder="CVV" />
      </Form>
      <Button text="Fund Wallet" onSubmit={handleSubmit}></Button>
    </Wrapper>
  );
}

export default FundWalletScreen;

const Wrapper = styled.main`
  background-color: ${colours.background};
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  /* @media (max-width: 425px) {
    height: 93.3vh;
  }
  @media (max-width: 375px) {
    height: 91vh;
  } */
`;


const Text = styled.p`
  color: #4d4949;
  font-size: 1.5rem;
  text-align: center;
  font-weight:300;
`;

const Form = styled.form`
  background-color: #ffffff;
  border: 1px solid #c8c9f9;
  padding: 20px 10px;
  border-radius: 12px;
  @media (max-width: 425px) {
    margin-bottom: 20px;
    font-size: 1rem;
  }
`;

const Input = styled.input`
  width: 100%;
  display: block;
  border-radius: 8px;
  background-color: #f8f7f7;
  height: 40px;
  border: none;
  outline: none;
  margin-bottom: 5px;
  padding-left: 5px;
`;

const SmallInput = styled.input`
  height: 40px;
  border-radius: 8px;
  background-color: #f8f7f7;
  border: none;
  outline: none;
  margin-right: 5px;
  padding-left: 5px;
`;
