import styled from "styled-components";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import utils from "../utils/utils";

const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

const getKeywords = {
  0: {
    keywords: `Wale Adeyemo Visits Nigeria, Max ng, The CEO of eFTD Africa, Africa Growth and Opportunity Act, AGOA, Adewale Adeyemo, Vibranium valley`,
    title: "Wale Adeyemo with the CEO of eFTD Africa",
  },
};

const SingleBlogPost = () => {
  const { blogid }: any = useParams();
  const [data, setData] = useState<any>([]);

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@eftdafrica"
      )
      .then((res) => {
        setData(res?.data?.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  const getData = () => {
    const getSingleOrder = data.filter((blogpost: any) => {
      return blogpost.title === utils.reverseBlogTitle(blogid);
    });
    if (getSingleOrder) return getSingleOrder[0];
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{getData() && parse(getData()?.title)}</title>
        <meta
          name="description"
          content="eFTD is the best parcel and Meal delivery service in Lagos. We are also one of a few Vehicle financing companies in Nigeria"
        />
        <meta name="keywords" content={getKeywords["0"].keywords} />
      </Helmet>
      <Header navlist={placeorderNavList} />
      <Content>
        {getData() && (
          <>
            <h3>{parse(getData()?.title)}</h3>
            <div className="main-content">{parse(getData()?.content)}</div>
            <p className="categories">
              {getData()?.categories.map((category: string) => (
                <span className="category">{category}</span>
              ))}
            </p>
          </>
        )}
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default SingleBlogPost;

const Wrapper = styled.main`
  margin: 0;
  margin-top: 150px;
  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
`;

const Content = styled.main`
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 50%;
  margin: auto;
  text-align: left;
  line-height: 28px;
  font-family: "Red Hat Display";
  font-weight: 400;
  font-size: 18px;
  margin-bottom: 200px;

  h3 {
    font-size: 36px;
    margin: 20px 0;
    line-height: 36px;
  }

  h4 {
    margin-bottom: 20px;
  }

  p {
    margin: 20px 0;
  }
  .categories {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-weight: 400;
  }
  .category {
    background-color: #ec7216;
    background: #f5f6f6;
    padding: 5px;
    text-transform: capitalize;
  }
  .main-content {
    h3 {
      display: none;
    }
    h2 {
      display: none;
    }
    a {
      color: #ec7216;
    }
  }

  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 100px;
    width: 90%;
  }
`;
