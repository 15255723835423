import { createSlice} from "@reduxjs/toolkit";

export interface Environment {
    env: string;
}

const initialState: Environment = {
    env: 'live',
  };

export const EnvironmentSlice = createSlice({
    name: "env",
    initialState,
    reducers: {
        setEnvironment: (state, action) => {
            state.env = action.payload
        }
    }

})

export const { setEnvironment } = EnvironmentSlice.actions;
export default EnvironmentSlice.reducer;