import { useEffect, useState } from "react";
import {useQuery, useMutation} from "react-query"
import { BsArrowLeft, BsArrowDown, BsCompass } from "react-icons/bs";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import axios from "axios";
import ControlledAccordions from "./Accordion";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import BasicModal from "./Modal";
import MapScreen from "./MapScreen";
import { toast } from "react-toastify";
import colours from "../styles/appColours";
import locationService from "../redux/location/locationService";
import { LineWave } from "react-loader-spinner";
import MapModal from "./MapModal";
import Ordersummary from "./Ordersummary";
import utils from "../utils/utils";
import { useNavigate } from "react-router-dom";
import api from "../services/api";
import Spinner from "./Spinner";
import Feedback from "./Feedback";
import Terms from "../pages/Terms";

interface VehicleTypes {
  eBike: string;
  smallVan: string;
  largeVan: string;
}

export interface Vehicles {
  _id: string;
  name: VehicleTypes;
  active: boolean;
  base_fee?: string;
  cost_per_km?: string;
  cost_per_min?: string;
}

interface CountryTypes {
  name: string;
  isoCode: string;
  flag: string;
}

interface StatesType {
    "name": string;
    "isoCode": string;
    "countryCode": string;
    "latitude": string;
    "longitude":string;
}

const del = {
  eBike: "/ebike.png",
  smallVan: "/evan.png",
  largeVan: "/evan.png",
};

const Interstatescreen = () => {
  const apiRequest = api();
  const pickupAddy: any = localStorage.getItem("pickup-address");
  const GoogleApiKey = process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY as string;
  const storedPickup: any = JSON.parse(pickupAddy);
  const navigate = useNavigate();
  const [state, setState] = useState(1);
  const [search, setSearch] = useState("");
  const [deladdress, setDelAddress] = useState("");
  const [dropdown, setDropdown] = useState(false);
  const [box, setBox] = useState("Select box");
  const [open, setOpen] = useState(false);
  const [active, setActive] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalstate, setModalState] = useState("loading");
  const [openModal, setOpenModal] = useState(false);
  const [country, setCountry] = useState<CountryTypes[]>([]);
  const [stateOfCountry, setStateOfCountry] = useState<StatesType[]>([]);
  const [cityOfState, setCityOfState]=useState<any>([])
  const [openPickupModal, setOpenPickupModal] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState<number>(0);
  const [feedbackModal, setFeedbackModal] = useState({
    open: false,
    type: "",
    message: "",
  });

  const [pickupDetails, setPickupDetails] = useState({
    fullAddress: storedPickup?.fullAddress || "",
    senderName: storedPickup?.senderName || "",
    phoneNumber: storedPickup?.phoneNumber || "",
    deliveryVehicleId: storedPickup?.deliveryVehicleId || "",
    state: storedPickup?.state || "",
    latlng: storedPickup?.latlng || "",
    code: "+234",
  });
  const [deliveryDetails, setDeliveryDetails] = useState({
    recepientName: "",
    address1: "",
    address2: "",
    parcelType: "packages",
    insured: false,
    length: "0",
    height: "0",
    width: "0",
    countryCode: "NG",
    state: "",
    cityName: "",
    weight: "",
    email: "",
    code: "+234",
    phoneNumber: "",
    postalCode: "",
    monetaryAmount: "0",
    description: "",
    latlng: "",
  });

  const address = useSelector(
    (state: RootState) => state.location.pickupAddress
  );

  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: GoogleApiKey,
      debounce: 800,
    });


  const { data: vehicles } = useQuery("GET_VEHICLES",  () => apiRequest.getVehicles());
  const { data: user } = useQuery("USER_PROFILE",  () => apiRequest.getUserProfile());

  const getEstimate = useMutation((data: any) =>
    apiRequest.getInterstatEstimate(data)
  );

  const placeOrder = useMutation((data: any) =>
    apiRequest.initiateInterstateOrder(data)
  );

  useEffect(() => {
    if (deliveryDetails?.parcelType === "document") {
      setDeliveryDetails((prevState) => {
        return {
          ...prevState,
          length: "35",
          height: "2",
          width: "27.5",
          weight: "1.5",
          monetaryAmount: "0",
        };
      });
    }
  }, [deliveryDetails?.parcelType]);

  let handlePickupChange = (e: any) => {
    let newFormValues: any = { ...pickupDetails };
    newFormValues[e.target.name] = e.target.value;
    setPickupDetails(newFormValues);
  };

  const handleChange = (e: any) => {
    setDeliveryDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  function handleCheckboxChange(e: any) {
    setDeliveryDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.checked,
    }));
  }

  const handleToggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: deladdress,
          key: GoogleApiKey,
        },
      })
      .then((res) => {
        const info = res.data.results[0]?.geometry?.location;
        const getLatLng = Object.values(info).join();
        setDeliveryDetails({ ...deliveryDetails, latlng: getLatLng });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [deladdress]);

  useEffect(()=> {
    axios.get(`https://countryapi.eftd.africa/country`)
    .then((res) => setCountry(res?.data?.result))
    .catch((err)=>console.log(err))
  },[])

  useEffect(()=> {
    if(deliveryDetails?.countryCode){
      axios.get(`https://countryapi.eftd.africa/state?countryCode=${deliveryDetails?.countryCode}`)
      .then((res) => setStateOfCountry(res?.data?.result))
      .catch((err)=>console.log(err))
    }
  },[deliveryDetails?.countryCode])

  useEffect(()=> {
    if(deliveryDetails?.state){
      axios.get(`https://countryapi.eftd.africa/city?countryCode=${deliveryDetails?.countryCode}&stateCode=${getStateCode(deliveryDetails?.state)}`)
      .then((res) => setCityOfState(res?.data?.result))
      .catch((err)=>console.log(err))
    }
  },[deliveryDetails?.state])

  const pickupInfo = locationService.deliveryInfo([pickupDetails], [address]);
  localStorage.setItem("pickup-address", JSON.stringify(pickupInfo[0]));

  function handleDisable() {
    if (!storedPickup.fullAddress) return true;
    if (!pickupDetails.senderName) return true;
    return false;
  }
  function validateEmail(email: string) {
    const regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;
    return regex.test(email);
  }
  function handleInputValidationPageOne() {
    if (
      !deliveryDetails?.recepientName ||
      !deliveryDetails.countryCode ||
      !search ||
      !deliveryDetails.postalCode ||
      !deliveryDetails.cityName ||
      !deliveryDetails.state ||
      deliveryDetails.state === "Select" ||
      !deliveryDetails.phoneNumber ||
      !deliveryDetails.email ||
      !validateEmail(deliveryDetails.email) ||
      pickupDetails.phoneNumber.length !== 10
    ) {
      const requiredFields = document.querySelectorAll(".required");
      requiredFields.forEach((input: any) => {
        if (input?.value.trim() === "" || input?.value === "Select") {
          input.classList.add("error");
        }
      });
      return true;
    }
    return false;
  }
  function handleInputValidationPageTwo() {
    if (
      !deliveryDetails?.weight ||
      Number(deliveryDetails?.weight) <= 0 ||
      !deliveryDetails.height ||
      !deliveryDetails.width ||
      !deliveryDetails.length ||
      !deliveryDetails.monetaryAmount ||
      !deliveryDetails.description
    ) {
      const requiredFields = document.querySelectorAll(".required");
      requiredFields.forEach((input: any) => {
        if (input?.value.trim() === "" || input?.value === "Select") {
          input.classList.add("error");
        }
      });
      return true;
    }
    return false;
  }

  const HandleEstimateCost = async (e: any) => {
    e.preventDefault();
    const receiverAddress = (search + deliveryDetails.address2).slice(0, 44);
    const receiverDetails = {
      fullAddress: receiverAddress,
      cityName: deliveryDetails.cityName,
      countyName: deliveryDetails.state,
      countryCode: deliveryDetails.countryCode,
      postalCode: deliveryDetails.postalCode,
    };
    const packages = [
      {
        weight: Number(deliveryDetails.weight),
        dimensions: {
          length: Number(deliveryDetails.length),
          width: Number(deliveryDetails.width),
          height: Number(deliveryDetails.height),
        },
      },
    ];
    const monetaryAmount = Number(deliveryDetails?.monetaryAmount) * 100;
    const request = { receiverDetails, packages, monetaryAmount };
    setLoading(true);
    try {
      const response: any = await getEstimate.mutateAsync(request);
      setLoading(false);
      setOpenModal(true);
      setEstimatedCost(response?.price);
      setModalState("estimate");
    } catch (error: any) {
      console.log({error})
      setLoading(false);
      setOpenModal(false);
      setFeedbackModal((prev) => {
        return {
          ...prev,
          open: true,
          type: "error",
          message:
            error?.response?.data?.error?.message ||
            error?.response?.message ||
            error?.response?.data?.error ||
            "Error estimating cost",
        };
      });
    }
  };
  const HandlePlaceOrder = async (e: any) => {
    e.preventDefault();
    setLoading(true);
    const receiverAddress = (search + deliveryDetails.address2).slice(0, 44);
    const pickupDetail = {
      fullAddress: pickupInfo[0]?.fullAddress,
      senderName: pickupInfo[0]?.senderName,
      phoneNumber: pickupInfo[0]?.code + pickupInfo[0]?.phoneNumber,
      deliveryVehicleId: pickupInfo[0]?.deliveryVehicleId,
      state: pickupInfo[0]?.state,
      latlng: pickupInfo[0]?.latlng,
      email: user?.email,
    };
    const receiverDetails = {
      fullAddress: receiverAddress,
      cityName: deliveryDetails.cityName,
      countyName: deliveryDetails.state,
      countryCode: deliveryDetails.countryCode,
      postalCode: deliveryDetails.postalCode,
      phoneNumber: deliveryDetails?.code + deliveryDetails?.phoneNumber,
      recipientName: deliveryDetails?.recepientName,
      email: deliveryDetails?.email,
      latlng: deliveryDetails?.latlng,
    };
    const packages = [
      {
        weight: Number(deliveryDetails.weight),
        dimensions: {
          length: Number(deliveryDetails.length),
          width: Number(deliveryDetails.width),
          height: Number(deliveryDetails.height),
        },
      },
    ];
    const monetaryAmount = Number(deliveryDetails?.monetaryAmount) * 100;
    const description = deliveryDetails?.description;
    setModalState("loading");
    const request = {
      pickupDetails: pickupDetail,
      receiverDetails,
      packages,
      estimatedCost,
      description,
      monetaryAmount,
      channel: "web",
      email: user?.email,
    };

    try {
      const response: any = await placeOrder.mutateAsync(request);
      setLoading(false);
      setOpenModal(true);
      setModalState("order-placed");
    } catch (error: any) {
      setLoading(false);
      setOpenModal(false);
      setFeedbackModal((prev) => {
        return {
          ...prev,
          open: true,
          type: "error",
          message:
            error?.response?.data?.error?.message ||
            error?.response?.message ||
            error?.response?.data?.error ||
            "Error Placing Order",
        };
      });
    }
  };

  const ModalContent = (state: string) => {
    switch (state) {
      case "loading":
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LineWave
              height="100"
              width="100"
              color={colours.primary}
              ariaLabel="line-wave"
            />
          </div>
        );
      case "estimate":
        return (
          <EstimateWrap>
            <p>Your estimated Delivery price is </p>
            <Price>N{utils.formatAmount(estimatedCost)}</Price>
            <p className="kindly-note">
              Kindly note that the item will be reweighed at the hub after
              pickup.
            </p>
            <CashButton onClick={HandlePlaceOrder}>
              {loading ? <Spinner /> : "Find a Rider"}
            </CashButton>
          </EstimateWrap>
        );
      case "order-placed":
        return (
          <OrderPlaced>
            <div>
              <h1>Order Successfully placed!</h1>
              <p className="estimate">
                Estimated Shipping fee is{" "}
                <span>N{utils.formatAmount(estimatedCost)}</span>
              </p>
            </div>
            <div>
              <p className="order-pick">
                Your order will picked up between 7am - 10am on the next working
                day.
              </p>
              <p className="kindly-note">
                Kindly note that the item will be reweighed at the hub after
                pickup.
              </p>
            </div>
            <CashButton onClick={() => navigate("/home")}>
              Return to Home
            </CashButton>
          </OrderPlaced>
        );
      default:
        return (
          <EstimateWrap2>
            <h4>Sorry for the inconvenience</h4>
            <p style={{ width: "100%", borderBottom: "1px solid black" }}></p>
            <p>There is no RIder available now</p>
            {/* <CashButton onClick={() => setOpenOrder(false)}>OK</CashButton> */}
          </EstimateWrap2>
        );
    }
  };

  function validateField(event: any) {
    const input = event.target;
    const value = input.value.trim(); // Trim any leading/trailing white spaces
    if (value === "" || value === "Select") {
      input.classList.add("error");
    } else {
      input.classList.remove("error");
    }
  }

  const inputFields = document.querySelectorAll(".required");

  // Attach event listeners to input fields
  inputFields.forEach((input) => {
    input.addEventListener("blur", validateField);
  });

  const getStateCode = (state:string) => {
   const stateCode = stateOfCountry.filter((stateObj) => state === stateObj?.name)
   return stateCode[0]?.isoCode
  }

  return (
    <Wrapper>
      {state === 1 && (
        <Form>
          <Title>Interstate Shipping</Title>
          <ControlledAccordions
            title={"Pickup Details"}
            deliveryinfo={
              <DeliveryWrapper>
                <InputDesign>
                  <Inputfield
                    className="required"
                    type="text"
                    placeholder="Full Name"
                    onChange={(e) => handlePickupChange(e)}
                    name="senderName"
                    value={pickupDetails.senderName}
                  />
                </InputDesign>
                <InputDesign onClick={() => setOpenPickupModal(true)}>
                  <MapModal
                    width={"100vw"}
                    height={"100vh"}
                    icon={
                      <Inputfield
                        className="required"
                        type="textarea"
                        name="fullAddress"
                        placeholder="Pickup Address"
                        value={
                          pickupInfo[0]?.fullAddress ||
                          pickupDetails.fullAddress
                        }
                        onChange={(e) => handlePickupChange(e)}
                      />
                    }
                    content={<MapScreen closeFunction={() => setOpen(false)} />}
                  />
                </InputDesign>

                <InputDesign>
                  <InputLabel>Select Delivery Vehicle</InputLabel>
                  <InputDiv>
                    {vehicles?.length > 0 ? (
                      vehicles.map((vehicle: Vehicles, index: number) => (
                        <DeliveryVehicle
                          active={pickupDetails?.deliveryVehicleId === vehicle?._id}
                          onClick={() => {
                            setActive(!active);
                            vehicle.active
                              ? setPickupDetails((prevState) => {
                                  return {
                                    ...prevState,
                                    deliveryVehicleId: vehicle?._id,
                                  };
                                })
                              : toast("Vehicle not available right now");
                          }}
                          key={index}
                          style={{ opacity: vehicle.active ? 1 : 0.3 }}
                        >
                          <img
                            src={
                              del[vehicle.name as unknown as keyof typeof del]
                            }
                            width={80}
                            height={60}
                            alt={vehicle.name as unknown as keyof typeof del}
                          />
                        </DeliveryVehicle>
                      ))
                    ) : (
                      <DeliveryVehicle active={active}>
                        loading....
                      </DeliveryVehicle>
                    )}
                  </InputDiv>
                </InputDesign>
                <InputDesign
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ width: "15%" }}>
                    <InputLabel>Code</InputLabel>
                    <Smalldropdown
                      onChange={(e) => handlePickupChange(e)}
                      name="code"
                      value={pickupDetails?.code}
                      disabled={true}
                    >
                      <option value={"+234"}>+234</option>
                    </Smalldropdown>
                  </div>
                  <div style={{ width: "85%" }}>
                    <Inputfield
                      className={`required ${
                        pickupDetails?.phoneNumber.length > 0 &&
                        pickupDetails?.phoneNumber.length !== 10 &&
                        "error"
                      }`}
                      value={utils.formatPhoneNumber(
                        pickupDetails?.phoneNumber
                      )}
                      onChange={(e) => handlePickupChange(e)}
                      placeholder="Phone Number"
                      name="phoneNumber"
                      maxLength={10}
                      onBlur={(evt) => {
                        if (evt.target.value.length !== 10) {
                          const pickupNumbreinput =
                            document.getElementById("pickup-number");
                          pickupNumbreinput?.classList.add("error");
                        }
                      }}
                      id="pickup-number"
                    />
                  </div>
                </InputDesign>
              </DeliveryWrapper>
            }
          />
          <ControlledAccordions
            deliveryinfo={
              <DeliveryWrapper>
                <InputDesign>
                  <Inputfield
                    className="required"
                    placeholder="Recepient full name"
                    type="text"
                    name="recepientName"
                    onChange={handleChange}
                    value={utils.formatText(deliveryDetails.recepientName)}
                  />
                </InputDesign>
                <InputDesign>
                  <InputLabel>Country/Territory</InputLabel>
                  <Smalldropdown
                    value={deliveryDetails.countryCode}
                    onChange={handleChange}
                    name="countryCode"
                    disabled={true}
                  >
                    <option>Select</option>
                    {country.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </Smalldropdown>
                </InputDesign>
                <InputDesign>
                  <Inputfield
                    className="required"
                    type="text"
                    name="address1"
                    onChange={(evt) => {
                      handleSearch(evt);
                      getPlacePredictions({ input: evt.target.value });
                    }}
                    value={search}
                    autoComplete="off"
                    placeholder="Address 1"
                  />
                  <Predict>
                    {!isPlacePredictionsLoading &&
                      placePredictions.map(
                        (
                          item: {
                            description: string;
                          },
                          id: React.Key | null | undefined
                        ) => (
                          <Predictions
                            onClick={() => {
                              setDelAddress(item.description);
                              setSearch(item.description);
                              getPlacePredictions({ input: "" });
                            }}
                            key={id}
                          >
                            <BsCompass />
                            <span>{item.description}</span>
                          </Predictions>
                        )
                      )}
                  </Predict>
                </InputDesign>
                <InputDesign>
                  <Inputfield
                    type="text"
                    name="address2"
                    placeholder="Address 2"
                    onChange={handleChange}
                    value={deliveryDetails?.address2}
                  />
                </InputDesign>
                <InputDesign
                  style={{ display: "flex", flexDirection: "row", gap: "20px" }}
                >
                  <Inputfield
                    className="required"
                    type="text"
                    name="postalCode"
                    placeholder="Postal Code"
                    onChange={handleChange}
                    value={deliveryDetails?.postalCode}
                  />
                  <Smalldropdown
                    value={deliveryDetails.state}
                    onChange={handleChange}
                    name="state"
                    className="required"
                  >
                    <option>Select</option>
                    {stateOfCountry.map(
                      (state:any) => (
                        <option key={state.name} value={state.name}>
                          {state.name}
                        </option>
                      )
                    )}
                  </Smalldropdown>
                  <Smalldropdown
                    value={deliveryDetails.cityName}
                    onChange={handleChange}
                    name="cityName"
                  >
                    <option>Select</option>
                    {cityOfState.map((city:any) => (
                      <option key={city.name} value={city.name}>
                        {city.name}
                      </option>
                    ))}
                  </Smalldropdown>
                </InputDesign>
                <InputDesign
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "20px",
                    alignItems: "flex-end",
                  }}
                >
                  <div style={{ width: "15%" }}>
                    <InputLabel>Code</InputLabel>
                    <Smalldropdown
                      disabled={true}
                      onChange={handleChange}
                      name="code"
                    >
                      <option value={"+234"}>+234</option>
                    </Smalldropdown>
                  </div>
                  <div style={{ width: "85%" }}>
                    <Inputfield
                      className={`required ${
                        pickupDetails?.phoneNumber.length > 0 &&
                        pickupDetails?.phoneNumber.length !== 10 &&
                        "error"
                      }`}
                      onChange={handleChange}
                      name="phoneNumber"
                      placeholder="8000000000"
                      value={utils.AllowOnlyNumbers(deliveryDetails?.phoneNumber)}
                      maxLength={10}
                    />
                  </div>
                </InputDesign>
                <InputDesign>
                  <Inputfield
                    className="required"
                    placeholder="Receivers email address"
                    name="email"
                    onChange={handleChange}
                    value={deliveryDetails?.email}
                    onBlur={(evt) => {
                      if (!validateEmail(evt.target.value)) {
                        const emailinput = document.getElementById("email");
                        emailinput?.classList.add("error");
                      }
                    }}
                    id="email"
                  />
                </InputDesign>
              </DeliveryWrapper>
            }
            title={`Delivery Details`}
          ></ControlledAccordions>
          <Buttonsection>
            <SubmitButton
              onClick={() => {
                if (!handleInputValidationPageOne()) {
                  setState(2);
                }
              }}
              type="submit"
              // disabled={handleInputValidationPageOne()}
            >
              Next
            </SubmitButton>
          </Buttonsection>
        </Form>
      )}
      {state === 2 && (
        <Form>
          <Title
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <BsArrowLeft
              style={{ cursor: "pointer", fontWeight: "900" }}
              onClick={() => setState(1)}
            />{" "}
            <span>Shipment Details</span>
          </Title>
          <DeliveryWrapper>
            <InputDesign>
              <p style={{ textAlign: "left", marginBottom: "20px" }}>
                What are you sending?
              </p>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "20px" }}
              >
                <input
                  onChange={handleChange}
                  type="radio"
                  name="parcelType"
                  checked={deliveryDetails.parcelType === "document"}
                  id="document"
                  value="document"
                  // defaultValue='document'
                />
                <label htmlFor="documents">Documents</label>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="parcelType"
                  checked={deliveryDetails.parcelType === "packages"}
                  id="packages"
                  value="packages"
                />
                <label htmlFor="packages">Packages</label>
              </div>
            </InputDesign>
            <InputDesign>
              <InputLabel>Describe the contents in your shipment</InputLabel>
              <Inputfield
                className="required"
                value={deliveryDetails?.description}
                onChange={handleChange}
                placeholder="Eg Legal, Finance"
                name="description"
              />
            </InputDesign>
            {deliveryDetails.parcelType === "packages" && (
              <InputDesign>
                <InputLabel>Value of Item(Naira)</InputLabel>
                <Inputfield
                  className="required"
                  value={utils.AllowOnlyNumbers(deliveryDetails?.monetaryAmount)}
                  onChange={handleChange}
                  name="monetaryAmount"
                  placeholder="000000"
                />
              </InputDesign>
            )}
            <InputDesign
              style={{ display: "flex", flexDirection: "row", gap: "20px" }}
            >
              <input
                onChange={handleCheckboxChange}
                name="insured"
                type="checkbox"
                checked={deliveryDetails?.insured}
              />
              <span>
                Insure Shipment{" "}
                <strong
                  style={{ cursor: "pointer", color: "#F66D00" }}
                  onClick={() => setOpenTerms(true)}
                >
                  (Terms & Conditions)
                </strong>
              </span>
            </InputDesign>
            {deliveryDetails?.parcelType === "packages" && (
              <>
                <h3 style={{ textAlign: "left" }}>Packaging</h3>
                <InputDesign>
                  <InputLabel>Select Package</InputLabel>
                  <CustomDropdown
                    onClick={handleToggleDropdown}
                    className="dropdown-select"
                  >
                    <span>{box}</span>
                    <BsArrowDown />
                  </CustomDropdown>
                  {dropdown && (
                    <DropdownContent>
                      {[
                        {
                          image: "/box2.png",
                          length: "26",
                          width: "15",
                          height: "15",
                          name: "Box 2(cube)",
                        },
                        {
                          image: "/box5.png",
                          length: "34",
                          width: "33",
                          height: "35",
                          name: "Box 5(Jumbo Small)",
                        },
                        {
                          image: "/box5.png",
                          length: "48.1",
                          width: "40.4",
                          height: "38.9",
                          name: "Box 7",
                        },
                        {
                          image: "/envelope.png",
                          length: "55",
                          width: "45",
                          height: "41",
                          name: "Box 8(Jumbo Large)",
                        },
                        {
                          image: "/envelope.png",
                          length: "35",
                          width: "27.5",
                          height: "2",
                          name: "Card Envelope",
                        },
                      ].map((option) => (
                        <DropdownList
                          className="dropdown-contents"
                          onClick={() => {
                            setDeliveryDetails((prevState) => ({
                              ...prevState,
                              height: option.height,
                              width: option.width,
                              length: option.length,
                            }));
                            setBox(option.name);
                            handleToggleDropdown();
                          }}
                          style={{
                            background:
                              option.name === box ? "#E1F8F9" : "#ffffff",
                          }}
                          key={option.name}
                        >
                          <img
                            width={50}
                            height={50}
                            src={option.image}
                            alt={option.name}
                          />
                          <div>
                            <p>{option.name}</p>
                            <p>{`${option.length} x ${option.width} x ${option.height} cm`}</p>
                          </div>
                        </DropdownList>
                      ))}
                    </DropdownContent>
                  )}
                </InputDesign>
                <InputDesign
                  style={{ display: "flex", flexDirection: "row", gap: "20px" }}
                >
                  <div style={{ width: "100%" }}>
                    <InputLabel>Length</InputLabel>
                    <Inputfield
                      className="required"
                      value={deliveryDetails.length}
                      name="length"
                      placeholder="length"
                      type={"number"}
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputLabel>Height</InputLabel>
                    <Inputfield
                      className="required"
                      value={deliveryDetails.height}
                      name="height"
                      type={"number"}
                      placeholder="height"
                      onChange={handleChange}
                    />
                  </div>
                  <div style={{ width: "100%" }}>
                    <InputLabel>Width</InputLabel>
                    <Inputfield
                      className="required"
                      value={deliveryDetails.width}
                      name="width"
                      placeholder="width"
                      type={"number"}
                      onChange={handleChange}
                    />
                  </div>
                </InputDesign>
                <InputDesign
                  style={{ display: "flex", flexDirection: "row", gap: "20px" }}
                >
                  <div style={{ width: "15%" }}>
                    <InputLabel>Quantity</InputLabel>
                    <Smalldropdown name="height">
                      <option value={1}>1</option>
                      {/* <option value={2}>2</option> */}
                    </Smalldropdown>
                  </div>
                  <div style={{ width: "85%" }}>
                    <InputLabel>Weight</InputLabel>
                    <Inputfield
                      className="required"
                      value={utils.formatWeight(deliveryDetails?.weight)}
                      onChange={handleChange}
                      name="weight"
                      type="text"
                      placeholder="Weight"
                      maxLength={4}
                    />
                  </div>
                </InputDesign>
              </>
            )}
            <Buttonsection>
              <SubmitButton
                disabled={handleInputValidationPageTwo()}
                onClick={() => {
                  if (!handleInputValidationPageTwo()) {
                    setState(3);
                  }
                }}
                type="submit"
              >
                Next
              </SubmitButton>
            </Buttonsection>
          </DeliveryWrapper>
        </Form>
      )}
      {state === 3 && (
        <Form>
          <Stagethree
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1>Order Summary</h1>
            <p
              onClick={() => {
                setState(2);
              }}
            >
              Edit
            </p>
          </Stagethree>
          <Ordersummary
            onSubmit={HandleEstimateCost}
            pickupDetails={{
              senderName: pickupDetails?.senderName,
              fullAddress: pickupInfo[0]?.fullAddress,
              state: pickupDetails?.state,
              code: pickupDetails?.code,
              phoneNumber: pickupDetails?.phoneNumber,
            }}
            deliveryDetails={{
              recepientName: deliveryDetails?.recepientName,
              fullAddress: search + deliveryDetails?.address2,
              state: deliveryDetails?.state,
              code: deliveryDetails?.code,
              phoneNumber: deliveryDetails?.phoneNumber,
              cityName: deliveryDetails?.cityName,
              countryCode: deliveryDetails.countryCode,
              email: deliveryDetails?.email,
            }}
            shipmentDetails={{
              weight: deliveryDetails?.weight,
              height: deliveryDetails?.height,
              width: deliveryDetails?.width,
              monetaryAmount: `NGN ${deliveryDetails?.monetaryAmount}`,
              length: deliveryDetails?.length,
            }}
            buttonText={loading ? <Spinner /> : "Estimate Price"}
            shouldButtonShow={true}
          />
        </Form>
      )}
      <BasicModal
        width={500}
        height={350}
        open={openModal}
        close={() => setOpenModal(false)}
        content={ModalContent(modalstate)}
      />
      <Feedback
        message={feedbackModal?.message}
        feedback={feedbackModal?.type}
        open={feedbackModal?.open}
        close={() =>
          setFeedbackModal((prev) => {
            return { ...prev, open: false };
          })
        }
      />
      <BasicModal
        open={openTerms}
        close={() => setOpenTerms(false)}
        height={"100%"}
        width={"80%"}
        content={
          <TermsWrap>
            <Terms
            />
          </TermsWrap>
        }
      />
    </Wrapper>
  );
};

export default Interstatescreen;

const EstimateWrap = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 0;
  margin: 0;
  gap: 20px;

  .kindly-note {
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #eb5757;
    width: 55%;
  }
`;
const TermsWrap = styled.main`
  height: 100vh;
  overflow: scroll;
`;
const OrderPlaced = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0px;
  margin: 0;
  gap: 15px;

  h1 {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    color: #333333;
  }

  .estimate {
    font-weight: 400;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #333333;
    > span {
      font-weight: 500;
    }
  }
  .order-pick {
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    text-align: center;

    /* Gray 1 */

    color: #333333;
  }
  .kindly-note {
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 19px;
    text-align: center;
    color: #eb5757;
    width: 100%;
  }
`;

const EstimateWrap2 = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 20px 0;
  h4 {
    font-weight: 500;
    font-size: 20px;
  }
  p {
    margin: 10px 0;
  }
`;
const CustomDropdown = styled.div`
  display: block;
  height: 45px;
  border: 1px solid #828282;
  outline: none;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px;
  cursor: pointer;
`;

const Stagethree = styled.div`
  p {
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: #f66d00;
    font-family: "Red Hat Display";
    cursor: pointer;
  }
`;

const DropdownContent = styled.ul`
  width: 100%;
  height: 410px;
  left: 56px;
  top: 584px;
  margin-top: 20px;
  background: #ffffff;
  border: 1px solid #828282;
  border-radius: 5px;
  overflow-y: scroll;
  padding: 20px 0px;
  cursor: pointer;
`;
const DropdownList = styled.li`
  list-style-type: none;
  display: block;
  height: 50px;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 10px 30px;
  cursor: pointer;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Smalldropdown = styled.select`
  display: block;
  height: 45px;
  border: 1px solid #828282;
  outline: none;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  color: #333333;
  font-size: 16px;

  &:focus {
    border: 2px solid #2476f3;
  }

  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;

const CashButton = styled.button`
  margin: 0 auto;
  text-align: center;
  display: block;
  /* width: 450px; */
  width: 60%;
  height: 45px;
  margin-bottom: 40px;
  background-color: ${colours.black};
  color: ${colours.white};
  border-radius: 6px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  @media (max-width: 425px) {
    width: 90%;
  }
  &:disabled {
    background-color: red;
  }
`;

const Title = styled.h3`
  text-align: left;
`;

const Inputfield = styled.input`
  padding-left: 10px;
  display: block;
  height: 45px;
  border: 1px solid #828282;
  outline: none;
  background-color: #ffffff;
  width: 100%;
  border-radius: 5px;
  font-size: 16px;

  color: #333333;

  &:focus {
    border: 2px solid #2476f3;
  }

  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
`;

const Text = styled.p`
  font-size: 14px;
  font-family: cursive;
  color: ${colours.primary};
  margin: 0px;
  text-align: left;
`;

const DeliveryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    flex-direction: column;
  }
`;

const Wrapper = styled.main`
  background-color: ${colours.background};
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 1024px) {
    background-color: ${colours.white};
  }
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
  max-width: 700px;
  padding: 30px;
  margin: 0 auto;
  background-color: ${colours.white};
  border-radius: 30px;
  margin-top: 80px;
  margin-bottom: 70px;
  @media (max-width: 1024px) {
    margin-top: 20px;
    width: 100%;
  }
`;

const SubmitButton = styled.button`
  text-align: center;
  display: block;
  width: 170px;
  height: 45px;
  margin-bottom: 40px;
  color: ${colours.white};
  border: none;
  font-weight: 700;
  background: #13131a;
  border-radius: 5px;
  @media (max-width: 425px) {
    width: 90%;
  }
  &:disabled {
    background-color: ${colours.black};
    opacity: 0.6;
  }
`;

const Buttonsection = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

const Price = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 38px;
  color: ${colours.primary};
  text-align: center;
`;

const InputDesign = styled.div`
  position: relative;
  background-color: #ffffff;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  @media (max-width: 600px) {
    width: 100%;
    border: 1px solid ${colours.secondary};
  }
`;

const InputLabel = styled.div`
  font-size: 12px;
  text-align: left;
  background-color: #ffffff;
  color: #333333;
  @media (max-width: 425px) {
    background-color: #ffffff;
  }
`;

const InputDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  padding: 20px 0px;
  overflow: auto;
  width: 100%;
  @media (max-width: 600px) {
    max-width: 90vw;
    justify-content: space-between;
    padding: 20px;
  }
`;

const DeliveryVehicle = styled.div<{ active: boolean }>`
  background-color: ${colours.background};
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 130px;
  border-radius: 12px;
  cursor: pointer;
  border: 2px solid
    ${(props) =>
      props.active ? `${colours.primary}` : `${colours.background}`};
  @media (max-width: 600px) {
    min-width: 100px;
  }
`;
const Predict = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: white;
  position: absolute;
  width: 100%;
  z-index: 2;
  top: 50px;
  @media screen and (max-width: 600px) {
    left: 0;
  }
`;

const Predictions = styled.p`
  display: flex;
  align-items: center;
  gap: 20px;
  cursor: pointer;
  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
`;
