import styled from "styled-components";
import axios from "axios";
import { ClientJS } from 'clientjs';
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import colours from "../styles/appColours";

function VerifyEmail() {
  const navigate = useNavigate();
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const browser = client.getBrowser();
  const OS =  client.getOS();
  const userInfo: any = localStorage.getItem("eftd-register");
  const userObject: any = JSON.parse(userInfo);
  const token = userObject?.token;
  const email = userObject?.email;

  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const Url = `${baseUrl}api/auth/verify/${token}`;
  const [formData, setFormData] = useState({
    otp1: "",
    otp2: "",
    otp3: "",
    otp4: "",
  });

  const handleChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const inputfocus = (elmnt: any) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 5) {
        elmnt.target.form.elements[next].focus();
      }
    }
  };

  const handleSubmit = (e: any) => {
    const config = {
      headers: {
        "Content-Type": "application/json",
        ...{ deviceId: fingerprint, deviceName: `${browser}/${OS}` }
      },
    };
    e.preventDefault();
    const otp = Object.values(formData).join("");
    axios
      .post(Url, { email, otp }, config)
      .then(() => {
        navigate("/login");
      })
      .catch(() => {
        toast.error("Something went wrong. Try again");
      });
  };
  return (
    <Wrapper onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
          gap: "10px",
        }}
      >
        <img width={50} height={50} src="/logo2.svg" alt="" />
        <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
          eFTD Africa
        </p>
      </div>
      <Top>
        <img src={"/enterotp.png"} height={160} width={160} alt="enter otp" />
        <Title>Verify your Account</Title>
        <Text>Enter the 4-digit code that was sent to your Email.</Text>
        <InputWrapper>
          <Input
            onChange={handleChange}
            required
            pattern=".{1,1}"
            type="text"
            name="otp1"
            tabIndex={1}
            maxLength={1}
            onKeyUp={(e) => inputfocus(e)}
            autoComplete="off"
          />
          <Input
            onChange={handleChange}
            required
            pattern=".{1,1}"
            type="text"
            name="otp2"
            tabIndex={2}
            maxLength={1}
            onKeyUp={(e) => inputfocus(e)}
            autoComplete="off"
          />
          <Input
            onChange={handleChange}
            required
            pattern=".{1,1}"
            type="text"
            name="otp3"
            tabIndex={3}
            maxLength={1}
            onKeyUp={(e) => inputfocus(e)}
            autoComplete="off"
          />
          <Input
            onChange={handleChange}
            required
            pattern=".{1,1}"
            type="text"
            name="otp4"
            tabIndex={4}
            maxLength={1}
            onKeyUp={(e) => inputfocus(e)}
            autoComplete="off"
          />
        </InputWrapper>

        <Button>Proceed</Button>
      </Top>
    </Wrapper>
  );
}

export default VerifyEmail;

export const Top = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px 80px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const Wrapper = styled.form`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  color: #333333;
`;

const Text = styled.p`
  text-align: center;
  width: 80%;
  margin: 0 auto;
  font-weight: 300;
  color: #4d4949;
`;

const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 50px;
`;

const Input = styled.input`
  border: none;
  border: 1px solid #bdbdbd;
  width: 50px;
  height: 50px;
  outline: none;
  padding-left: 10px;
  font-size: 40px;
  border-radius: 6px;
  @media screen and (max-width: 600px) {
    width: 50px;
  }
`;

const Button = styled.button`
  background-color: ${colours.black};
  height: 53px;
  width: 30vw;
  border-radius: 8px;
  border: none;
  color: ${colours.white};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 90vw;
  }
`;
