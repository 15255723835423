import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import LandingFooter from "./footer/Footer";
import Header from "./header/Header";
import {  useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import useOnScreen from "../hooks/UseOnScreen";

const placeorderNavList = [
    { route: "/about", name: "About Us" },
    { route: "/home", name: "Place Order" },
    { route: "/blog", name: "Blog" },
    { route: "/track", name: "Track Order" },
    { route: "/pricing", name: "Pricing" },
  ];

export const Privacy = () => {
  const aboutRef = useRef();
  const mountRef = useRef();
  const onScreen = useOnScreen(aboutRef);
  const mount = useOnScreen(mountRef);
  const controls = useAnimation();
  const control = useAnimation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  useEffect(() => {
    if (mount) {
      control.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.9,
          ease: "easeOut",
        },
      });
    }
  }, [mount, control]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Helmet>
        <title>Eco-friendly logistics company in Africa</title>
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
      </Helmet>
      <Header navlist={placeorderNavList} />
      <Wrapper>
        <Headers>Introduction</Headers>
        <Text>
          Welcome to the <strong>ELECTRONIC–FTD LIMITED's</strong> privacy
          policy.
        </Text>
        <Text>
          <strong>ELECTRONIC–FTD LIMITED</strong> respects your privacy and is
          committed to protecting your personal data. This privacy policy will
          inform you as to how we look after your personal data when you visit
          our website (regardless of where you visit it from) and tell you about
          your privacy rights and how the law protects you. This privacy policy
          is provided in a layered format so you can click through to the
          specific areas set out below. Alternatively, you can download a pdf
          version of the policy here: [LINK].] Please also use the Glossary to
          understand the meaning of some of the terms used in this privacy
          policy.
        </Text>
        <Headers>Important information and who we are</Headers>
        <h1>Purpose of this privacy policy</h1>
        <Text>
          This privacy policy aims to give you information on how{" "}
          <strong>ELECTRONIC–FTD LIMITED</strong> collects and processes your
          personal data through your use of this website, including any data you
          may provide through this website when you sign up to our newsletter or
          purchase a product or service.
        </Text>
        <Text>
          This website is not intended for children and we do not knowingly
          collect data relating to children.
        </Text>
        <Text>
          It is important that you read this privacy policy together with any
          other privacy policy or fair processing policy we may provide on
          specific occasions when we are collecting or processing personal data
          about you so that you are fully aware of how and why we are using your
          data. This privacy policy supplements other notices and privacy
          policies and is not intended to override them.
        </Text>
        <h1>Controller</h1>
        <Text>
          <strong>ELECTRONIC – eFTD LIMITED</strong> is the controller and
          responsible for your personal data (collectively referred to as
          ["COMPANY"], "we", "us" or "our" in this privacy policy). We have
          appointed a data privacy manager who is responsible for overseeing
          questions in relation to this privacy policy. If you have any
          questions about this privacy policy, including any requests to
          exercise, please contact the data privacy manager using the details
          set out below.
        </Text>
        <h1>Contact details</h1>
        <Text>
          If you have any questions about this privacy policy or our privacy
          practices, please contact our data privacy manager in the following
          ways:
        </Text>
        <Text>Full name of legal entity: <strong>Electronic-FTD Limited</strong></Text>
        <Text>Email address: <strong>info@eftdafrica.com</strong></Text>
        <Text>Telephone number: <strong>+2349154368523</strong></Text>
        <h1>
          Changes to the privacy policy and your duty to inform us of changes
        </h1>
        <Text>We keep our privacy policy under regular review.</Text>
        <Text>
          It is important that the personal data we hold about you is accurate
          and current. Please keep us informed if your personal data changes
          during your relationship with us.
        </Text>
        <h1>Third-party links</h1>
        <Text>
          This website may include links to third-party websites, plug-ins and
          applications. Clicking on those links or enabling those connections
          may allow third parties to collect or share data about you. We do not
          control these third-party websites and are not responsible for their
          privacy statements. When you leave our website, we encourage you to
          read the privacy policy of every website you visit.
        </Text>
        <Headers>The data we collect about you</Headers>
        <Text>
          Personal data, or personal information, means any information about an
          individual from which that person can be identified. It does not
          include data where the identity has been removed (anonymous data).
        </Text>
        <Text>
          We may collect, use, store and transfer different kinds of personal
          data about you which we have grouped together as follows:
        </Text>
        <Text>
          - <strong>Identity Data</strong> includes first name, maiden name,
          last name, username or similar identifier, marital status, title, date
          of birth and gender.
        </Text>
        <Text>
          - <strong>Contact Data</strong> includes billing address, delivery
          address, email address and telephone numbers.
        </Text>
        <Text>
          - <strong>Financial Data</strong> includes bank account and payment
          card details.
        </Text>
        <Text>
          - <strong>Transaction Data</strong> includes details about payments to
          and from you and other details of products and services you have
          purchased from us.
        </Text>
        <Text>
          - <strong>Technical Data</strong> includes internet protocol (IP)
          address, your login data, browser type and version, time zone setting
          and location, browser plug-in types and versions, operating system and
          platform, and other technology on the devices you use to access this
          website.
        </Text>
        <Text>
          - <strong>Profile Data</strong> includes your username and password,
          purchases or orders made by you, your interests, preferences, feedback
          and survey responses.
        </Text>
        <Text>
          - <strong>Usage Data</strong> includes information about how you use
          our website, products and services.
        </Text>
        <Text>
          - <strong>Marketing and Communications Data</strong> includes your
          preferences in receiving marketing from us and our third parties and
          your communication preferences.
        </Text>
        <Text>
          We also collect, use and share <strong>Aggregated Data</strong> such
          as statistical or demographic data for any purpose. Aggregated Data
          could be derived from your personal data but is not considered
          personal data in law as this data will <strong>not</strong> directly
          or indirectly reveal your identity. For example, we may aggregate your
          Usage Data to calculate the percentage of users accessing a specific
          website feature. However, if we combine or connect Aggregated Data
          with your personal data so that it can directly or indirectly identify
          you, we treat the combined data as personal data which will be used in
          accordance with this privacy policy.
        </Text>
        <Text>
          We do not collect any{" "}
          <strong>Special Categories of Personal Data</strong> about you (this
          includes details about your race or ethnicity, religious or
          philosophical beliefs, sex life, sexual orientation, political
          opinions, trade union membership, information about your health, and
          genetic and biometric data). Nor do we collect any information about
          criminal convictions and offences.
        </Text>
        <h1>If you fail to provide personal data</h1>
        <Text>
          Where we need to collect personal data by law, or under the terms of a
          contract we have with you, and you fail to provide that data when
          requested, we may not be able to perform the contract we have or are
          trying to enter into with you (for example, to provide you with goods
          or services). In this case, we may have to cancel a product or service
          you have with us but we will notify you if this is the case at the
          time.
        </Text>

        <Headers>How is your personal data collected?</Headers>
        <Text>
          We use different methods to collect data from and about you including
          through:
        </Text>
        <Text>
          - <strong>Direct interactions</strong>.You may give us your Identity,
          Contact and Financial Data by filling in forms or by corresponding
          with us by post, phone, email or otherwise. This includes personal
          data you provide when you:
        </Text>
        <ul>
          <li>apply for our products or services;</li>
          <li>create an account on our website;</li>
          <li> subscribe to our service or publications;</li>
          <li> request marketing to be sent to you;</li>
          <li> enter a competition, promotion or survey;</li>
          <li>give us feedback or contact us.</li>
        </ul>
        <Text>
          - <strong>Automated technologies or interactions.</strong> As you
          interact with our website, we will automatically collect Technical
          Data about your equipment, browsing actions and patterns. We collect
          this personal data by using cookies, server logs and other similar
          technologies. Please see our cookie policy for further details.
        </Text>
        <Text>
          <strong>Third parties or publicly available sources.</strong> We will
          receive personal data about you from various third parties and public
          sources as set out below:
        </Text>
        <Text>Technical Data from the following parties:</Text>
        <ul>
          <li>analytics providers such as Google </li>
          <li> advertising networks based inside Nigeria;</li>
          <li>search information providers</li>
        </ul>
        <Text>
          - Contact, Financial and Transaction Data from providers of technical,
          payment and delivery services
        </Text>
        <Text>
          - Identity and Contact Data from data brokers or aggregators.
        </Text>
        <Text>
          - Identity and Contact Data from publicly available sources .
        </Text>
        <Headers>How we use your personal data</Headers>
        <Text>
          We will only use your personal data when the law allows us to. Most
          commonly, we will use your personal data in the following
          circumstances:
        </Text>
        <Text>
          - Where we need to perform the contract we are about to enter into or
          have entered into with you.
        </Text>
        <Text>
          - Where it is necessary for our legitimate interests (or those of a
          third party) and your interests and fundamental rights do not override
          those interests.
        </Text>
        <Text>- Where we need to comply with a legal obligation.</Text>
        <Text>
          Generally, we do not rely on consent as a legal basis for processing
          your personal data although we will get your consent before sending
          third party direct marketing communications to you via email or text
          message. You have the right to withdraw consent to marketing at any
          time by contacting us.
        </Text>
        <h1>Purposes for which we will use your personal data</h1>
        <Text>
          We have set out below, in a table format, a description of all the
          ways we plan to use your personal data, and which of the legal bases
          we rely on to do so. We have also identified what our legitimate
          interests are where appropriate.
        </Text>
        <Text>
          Note that we may process your personal data for more than one lawful
          ground depending on the specific purpose for which we are using your
          data. Please contact us if you need details about the specific legal
          ground we are relying on to process your personal data where more than
          one ground has been set out in the table below.
        </Text>
        {/* A table goes here */}
        <h1>Marketing</h1>
        <Text>
          We strive to provide you with choices regarding certain personal data
          uses, particularly around marketing and advertising.
        </Text>
        <h1>Promotional offers from us</h1>
        <Text>
          We may use your Identity, Contact, Technical, Usage and Profile Data
          to form a view on what we think you may want or need, or what may be
          of interest to you. This is how we decide which products, services and
          offers may be relevant for you (we call this marketing).
        </Text>
        <Text>
          You will receive marketing communications from us if you have
          requested information from us or purchased [goods or services] from us
          and you have not opted out of receiving that marketing.
        </Text>
        <h1>Third-party marketing</h1>
        <Text>
          We will get your express opt-in consent before we share your personal
          data with any third party for marketing purposes.
        </Text>
        <h1>Opting out</h1>
        <Text>
          You can ask us or third parties to stop sending you marketing messages
          at any time by logging into the website and checking or unchecking
          relevant boxes to adjust your marketing preferences or by following
          the opt-out links on any marketing message sent to you <strong></strong>or<strong></strong> by
          contacting us at any time.
        </Text>
        <Text>
          Where you opt out of receiving these marketing messages, this will not
          apply to personal data provided to us as a result of a product/service
          purchase, warranty registration, product/service experience or other
          transactions.
        </Text>
        <h1>Cookies</h1>
        <Text>
          You can set your browser to refuse all or some browser cookies, or to
          alert you when websites set or access cookies. If you disable or
          refuse cookies, please note that some parts of this website may become
          inaccessible or not function properly.
        </Text>

        <h1>Change of purpose</h1>
        <Text>
          We will only use your personal data for the purposes for which we
          collected it, unless we reasonably consider that we need to use it for
          another reason and that reason is compatible with the original
          purpose. If you wish to get an explanation as to how the processing
          for the new purpose is compatible with the original purpose, please
          contact us.
        </Text>
        <Text>
          If we need to use your personal data for an unrelated purpose, we will
          notify you and we will explain the legal basis which allows us to do
          so.
        </Text>
        <Text>
          Please note that we may process your personal data without your
          knowledge or consent, in compliance with the above rules, where this
          is required or permitted by law.
        </Text>
        <Headers>Disclosures of your personal data</Headers>
        <Text>
          We may share your personal data with the parties set out below for the
          purposes set out in the table{" "}
          <strong>Purposes for which we will use your personal data</strong>{" "}
          above.
        </Text>
        <Text>- Internal Third Parties</Text>
        <Text>- External Third Parties</Text>
        <Text>
          - Third parties to whom we may choose to sell, transfer or merge parts
          of our business or our assets. Alternatively, we may seek to acquire
          other businesses or merge with them. If a change happens to our
          business, then the new owners may use your personal data in the same
          way as set out in this privacy policy.
        </Text>
        <Text>
          We require all third parties to respect the security of your personal
          data and to treat it in accordance with the law. We do not allow our
          third-party service providers to use your personal data for their own
          purposes and only permit them to process your personal data for
          specified purposes and in accordance with our instructions.
        </Text>
        <Headers>Data security</Headers>
        <Text>
          We have put in place appropriate security measures to prevent your
          personal data from being accidentally lost, used or accessed in an
          unauthorised way, altered or disclosed. In addition, we limit access
          to your personal data to those employees, agents, contractors and
          other third parties who have a business need to know. They will only
          process your personal data on our instructions and they are subject to
          a duty of confidentiality.
        </Text>
        <Text>
          We have put in place procedures to deal with any suspected personal
          data breach and will notify you and any applicable regulator of a
          breach where we are legally required to do so.
        </Text>

        <Headers>Data retention</Headers>
        <h1>How long will you use my personal data for?</h1>
        <Text>
          We will only retain your personal data for as long as reasonably
          necessary to fulfil the purposes we collected it for, including for
          the purposes of satisfying any legal, regulatory, tax, accounting or
          reporting requirements. We may retain your personal data for a longer
          period in the event of a complaint or if we reasonably believe there
          is a prospect of litigation in respect to our relationship with you.
        </Text>
        <Text>
          To determine the appropriate retention period for personal data, we
          consider the amount, nature and sensitivity of the personal data, the
          potential risk of harm from unauthorised use or disclosure of your
          personal data, the purposes for which we process your personal data
          and whether we can achieve those purposes through other means, and the
          applicable legal, regulatory, tax, accounting or other requirements.
        </Text>
        <Headers>Your legal rights</Headers>
        <Text>
          Under certain circumstances, you have rights under data protection
          laws in relation to your personal data. Please click on the links
          below to find out more about these rights:
        </Text>
        <ul>
          <li>Request access to your personal data</li>
          <li>Request correction of your personal data</li>
          <li>Object to processing of your personal data</li>
          <li>Request restriction of processing your personal data</li>
          <li>Request transfer of your personal data</li>
          <li>Right to withdraw consent</li>
        </ul>
        <Text>
          If you wish to exercise any of the rights set out above, please
          contact us.
        </Text>
        <h1>No fee usually required</h1>
        <Text>
          You will not have to pay a fee to access your personal data (or to
          exercise any of the other rights). However, we may charge a reasonable
          fee if your request is clearly unfounded, repetitive or excessive.
          Alternatively, we could refuse to comply with your request in these
          circumstances.
        </Text>
        <h1>What we may need from you</h1>
        <Text>
          We may need to request specific information from you to help us
          confirm your identity and ensure your right to access your personal
          data (or to exercise any of your other rights). This is a security
          measure to ensure that personal data is not disclosed to any person
          who has no right to receive it. We may also contact you to ask you for
          further information in relation to your request to speed up our
          response.
        </Text>
        <h1>Time limit to respond</h1>
        <Text>
          We try to respond to all legitimate requests within one month.
          Occasionally it could take us longer than a month if your request is
          particularly complex or you have made a number of requests. In this
          case, we will notify you and keep you updated.
        </Text>

        <Headers>Glossary</Headers>
        <h1>LAWFUL BASIS</h1>

        <Text>
          <strong>Legitimate Interest</strong> means the interest of our business
          in conducting and managing our business to enable us to give you the
          best service/product and the best and most secure experience. We make
          sure we consider and balance any potential impact on you (both
          positive and negative) and your rights before we process your personal
          data for our legitimate interests. We do not use your personal data
          for activities where our interests are overridden by the impact on you
          (unless we have your consent or are otherwise required or permitted to
          by law). You can obtain further information about how we assess our
          legitimate interests against any potential impact on you in respect of
          specific activities by contacting us.
        </Text>
        <Text>
          <strong>Performance of Contract</strong> means processing your data
          where it is necessary for the performance of a contract to which you
          are a party or to take steps at your request before entering into such
          a contract.
        </Text>
        <Text>
          <strong>Comply with a legal obligation</strong> means processing your
          personal data where it is necessary for compliance with a legal
          obligation that we are subject to.
        </Text>
        <h1>THIRD PARTIES</h1>
        <h1>Internal Third Parties</h1>

        <Text>
          Other companies in the <strong>TOTALENERGIES STAFF</strong> Housing Cooperative
          Multipurpose Society Limited Group acting as joint controllers or
          processors and who are based in Nigeria and provide IT and system
          administration services and undertake leadership reporting.
        </Text>
        <h1>External Third Parties</h1>
        <Text>
          - Service providers acting as processors based Nigeria who provide IT
          and system administration services.
        </Text>
        <Text>
          - Professional advisers acting as processors or joint controllers
          including lawyers, bankers, auditors and insurers based in Nigeria who
          provide consultancy, banking, legal, insurance and accounting
          services.
        </Text>
        <h1>YOUR LEGAL RIGHTS</h1>
        <Text>You have the right to:</Text>
        <Text>
          <strong>Request access</strong> to your personal data (commonly known as a "data
          subject access request"). This enables you to receive a copy of the
          personal data we hold about you and to check that we are lawfully
          processing it.
        </Text>
        <Text>
          <strong>Request correction</strong> of the personal data that we hold about you.
          This enables you to have any incomplete or inaccurate data we hold
          about you corrected, though we may need to verify the accuracy of the
          new data you provide to us.
        </Text>
        <Text>
          <strong>Request erasure</strong> of your personal data. This enables you to ask us
          to delete or remove personal data where there is no good reason for us
          continuing to process it. You also have the right to ask us to delete
          or remove your personal data where you have successfully exercised
          your right to object to processing (see below), where we may have
          processed your information unlawfully or where we are required to
          erase your personal data to comply with local law. Note, however, that
          we may not always be able to comply with your request of erasure for
          specific legal reasons which will be notified to you, if applicable,
          at the time of your request.
        </Text>
        <Text>
          <strong>Object to processing</strong> of your personal data where we are relying on
          a legitimate interest (or those of a third party) and there is
          something about your particular situation which makes you want to
          object to processing on this ground as you feel it impacts on your
          fundamental rights and freedoms. You also have the right to object
          where we are processing your personal data for direct marketing
          purposes. In some cases, we may demonstrate that we have compelling
          legitimate grounds to process your information which override your
          rights and freedoms.
        </Text>
        <Text><strong>Request restriction of processing</strong> of your personal data. This enables you to ask us to suspend the processing of your personal data in the following scenarios:</Text>
        <Text>- If you want us to establish the data's accuracy.</Text>
        <Text>- Where our use of the data is unlawful but you do not want us to erase it.</Text>
        <Text>- Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.</Text>
        <Text>- You have objected to our use of your data but we need to verify whether we have overriding legitimate grounds to use it.</Text>
        <Text><strong>Request the transfer</strong> of your personal data to you or to a third party. We will provide to you, or a third party you have chosen, your personal data in a structured, commonly used, machine-readable format. Note that this right only applies to automated information which you initially provided consent for us to use or where we used the information to perform a contract with you.</Text>
        <Text><strong>Withdraw consent at any time</strong> where we are relying on consent to process your personal data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or services to you. We will advise you if this is the case at the time you withdraw your consent.</Text>
      </Wrapper>
      <LandingFooter />
    </Container>
  );
};

const Container = styled.main`
  background-color: F5F6F6;
`;

const Wrapper = styled.div`
  width: 90%;
  margin: 100px auto;
  padding: 20px;

  h1 {
    text-align: left;
    font-size: 18px;
    margin: 10px auto;
  }

  ul > li {
    /* list-style: none; */
    text-align: left;
    margin-bottom: 10px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  margin-bottom: 20px;
`;

const Headers = styled.h1`
  color: #fe7f20;
  font-size: 24px;
  text-align: left;
`;