import React from 'react'
import styled from 'styled-components'
import Header from '../components/header/Header'
import colours from '../styles/appColours';

export default function ErrorPage() {
  const placeorderNavList = [
    { route: "/about", name: "About Us" },
    { route: "/home", name: "Place Order" },
    { route: "/home", name: "Track Order" },
  ];
  return (
    <Wrapper>
      <Header navlist={placeorderNavList} />
      <VerticalCenter>
        <Container>
          <TextCenter id="notfound">
            <img width={300} src="/logo1.png" alt="logo" />
            <h2>Oops! an Error occured</h2>
            <Text>
              Please Contact{" "}
              <Mailto href="mailto:eftdltd@gmail.com">support</Mailto> and
              someone would attend to you immediately
            </Text>
            <Links href="/home">Back to homepage</Links>
          </TextCenter>
        </Container>
      </VerticalCenter>
    </Wrapper>
  );
}

const Wrapper = styled.main`
    background-color: ${colours.background};
    height: 100vh;
`

const VerticalCenter = styled.div`
  display: flex;
  align-items: center;
  height: calc(100vh - 100px);
  justify-content: center;
`;
    
const Container = styled.div``
const Links = styled.a`
    width: 300px;
    height: 40px;
    padding: 5px 10px;
    background-color: ${colours.black};
    border-radius: 8px;
    font-size: 20px;
    text-align: center;
    color: #ffffff;
`
const Mailto = styled.a`
    color: ${colours.primary};
    font-weight: 600;
`
    

const TextCenter = styled.div`
display: flex;
flex-direction: column;
align-items: center;
    gap: 10px;
`

const Text = styled.p`
    font-size: 20px;
`