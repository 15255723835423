import React, { useState } from "react";

function CopyContent({ content }: any) {
  const [isCopied, setIsCopied] = useState(false);

  function handleCopy() {
    navigator.clipboard.writeText(content);
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  }

  return (
    <>
      {isCopied ? (
        <span className="copied">Copied!</span>
      ) : (
        <div style={{ width: '100%',display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
          <span style={{fontSize: '14px'}}>{content}</span>
          <img onClick={handleCopy} style={{ cursor: 'pointer'}} src="/copy.svg" alt="" />
        </div>
      )}
    </>
  );
}

export default CopyContent;
