import PlaceOrderScreen from "../components/PlaceOrderScreen";
import ResponsiveDrawer from "../components/Sidebar";

const Placeorder = () => {
  return (
    <ResponsiveDrawer title="Send a Package" content={<PlaceOrderScreen/>}/>
  );
};

export default Placeorder;

