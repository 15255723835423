import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import colours from "../styles/appColours";

const NewPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const token = localStorage.getItem("eftd-forgotpasswordtoken");

  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const handleChange = (e: any) => {
    setError("");
    setNewPassword(e.target.value);
  };

  const handleConfirmChange = (e: any) => {
    setConfirmPassword(e.target.value);
  };

  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords must match");
    }
    if (newPassword.length < 8) {
      setError("password must have atleast 8 character");
    }
    axios
      .post(
        baseUrl + "api/auth/change-forgot-password",
        { newPassword },
        config
      )
      .then((response) => {
        if (response.data) {
          navigate("/login");
        }
      })
      .catch((err) => {
        toast(err?.message);
      });
  };
  return (
    <Wrapper onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
          gap: "10px",
        }}
      >
        <img width={50} height={50} src="/logo2.svg" alt="" />
        <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
          eFTD Africa
        </p>
      </div>
      <Top>
        {error ? <Error>{error}</Error> : ""}
        <InputWrapper>
          <Input
            type="text"
            name="newpassword"
            onChange={handleChange}
            placeholder="New Password"
          />
        </InputWrapper>
        <InputWrapper>
          <Input
            type="text"
            name="confirmnewpassword"
            placeholder="Confirm New Password"
            onChange={handleConfirmChange}
          />
        </InputWrapper>
        <Button>Proceed</Button>
      </Top>
    </Wrapper>
  );
};

export default NewPassword;

const Top = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 20px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
const Wrapper = styled.form`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;

const Error = styled.p`
  color: red;
`;
const InputSection = styled.div`
  padding-top: 20px;
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const Button = styled.button`
  background-color: ${colours.black};
  height: 53px;
  width: 100%;
  border-radius: 8px;
  border: none;
  color: ${colours.white};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;
