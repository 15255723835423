import React, { ReactNode } from "react";
import styled from "styled-components";

type Props = {
  children?: ReactNode;
  text: ReactNode;
  style?: any;
  onSubmit?: any
};

const Button = ({ onSubmit, style, children, text }: Props) => {
  return <AuthButtons onSubmit={onSubmit} style={style}>{text}</AuthButtons>;
};

export default Button;

const AuthButtons = styled.button`
  height: 53px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 90vw;
  }
`;
