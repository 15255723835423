import { MarkerF, GoogleMap, useLoadScript } from "@react-google-maps/api";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import Loader from "./Loader";
import { useDispatch } from "react-redux";
import { BsCompass } from "react-icons/bs";
import { getPickupLocation } from "../redux/location/location";

import locationService from "../redux/location/locationService";
import colours from "../styles/appColours";

const containerStyle = {
  width: "100%",
  height: "100%",
};
const GoogleApiKey = process.env.REACT_APP_PUBLIC_GOOGLE_API_KEY as string;
const libraries:['places','drawing'] = ['places','drawing']
function MapScreen({ id, closeMap }: any) {
   const pickupAddy: any = localStorage.getItem("pickup-address");
   const storedPickup: any = JSON.parse(pickupAddy);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: GoogleApiKey,
    libraries: libraries,
  });
  const { placePredictions, getPlacePredictions, isPlacePredictionsLoading } =
    usePlacesService({
      apiKey: GoogleApiKey,
      debounce: 800,
    });
  const [map, setMap] = React.useState(null);
  const [search, setSearch] = useState("");
  const [address, setAddress] = useState(storedPickup?.fullAddress);
  const [center, setCenter] = useState({
    lat: 6.5244,
    lng: 3.3792,
  });

  const dispatch = useDispatch();
  const onLoad = React.useCallback(function callback(map: any) {
    const bounds = new window.google.maps.LatLngBounds(center);
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map: any) {
    setMap(null);
  }, []);
  const handleSearch = (e: any) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    axios
      .get("https://maps.googleapis.com/maps/api/geocode/json", {
        params: {
          address: address,
          key: GoogleApiKey,
        },
      })
      .then((res) => {
        const info = res.data.results[0]?.geometry?.location;
        const getAddressFormat = locationService.getLocation(
          res?.data?.results,
          id
        );
        dispatch(getPickupLocation(getAddressFormat));
        setCenter((prevState) => ({
          ...prevState,
          lat: info?.lat,
          lng: info?.lng,
        }));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [address]);


  return (
    <Wrapper>
      <Header>
        <InputWrapper>
          <img src={"/searchicon.png"} height={15} width={15} alt="search" />
          <Input
            onChange={(evt) => {
              getPlacePredictions({ input: evt.target.value });
              handleSearch(evt);
            }}
            value={search}
            type="text"
            placeholder="Search for a location"
          />
        </InputWrapper>
        <Predict>
        {!isPlacePredictionsLoading &&
          placePredictions.map(
            (
              item: {
                description: string;
              },
              id: React.Key | null | undefined
            ) => (
              <Predictions
                onClick={() => {
                  setAddress(item.description);
                  setSearch(item.description);
                  getPlacePredictions({ input: "" });
                }}
                key={id}
              >
                <BsCompass />
                <span>{item.description}</span>
              </Predictions>
            )
          )}
      </Predict>
      </Header>
      
      <MapWrapper>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={12}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={{
              zoomControl: false,
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
              <MarkerF
                title="The marker should show"
                position={center}
              />
              {/* <DrawingManager
                 drawingMode={google.maps.drawing.OverlayType.POLYGON}
                 options={
                  {
                    drawingControl: true,
                  }
                 }
              /> */}
          </GoogleMap>
        ) : (
          <Loader />
        )}
      </MapWrapper>
    </Wrapper>
  );
}

export default MapScreen;

const Wrapper = styled.main`
  height: 100vh;
  background-color: #e5e5e5;
  position: relative;
`;

const Header = styled.div`
  /* background-color: #ffffff; */
  /* height: 80px; */
  display: flex;
  flex-direction:column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  background-color: transparent;
  position: absolute;
  top: 20px;
  left: 10%;
  z-index: 2;
  width: 80%;
  border-radius: 10px;

  @media screen and (max-width: 600px) {
    width: 90%;
    left: 5%;
    top: 40px;
  }
`;
const Back = styled.div`
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  height: 38px;
`;
const Predict = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background-color: transparent;
  width:80%;
  /* position: absolute; */
  z-index: 2;
  /* top: 70px;
  left: 10%; */
  @media screen and (max-width: 600px) {
    left: 0;
  }
`;

const Predictions = styled.p`
  padding: 10px 20px;
  border: 0.5px solid #ffffff;
  cursor: pointer;
  background: #000000;
  opacity: 0.8;
  color: #ffffff;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding-left: 20px;
  }
`;
const Input = styled.input`
  border: none;
  outline: none;
  background-color: #ffffff;
  width: 100%;
`;

const InputWrapper = styled.form`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  gap: 30px;
  height: 45px;
  border: 1px solid ${colours.secondary};
  width: 80%;
  border-radius: 16px;
  padding: 0px 15px;
  @media screen and (max-width: 600px) {
    width: 90%;
  }
`;

const MapWrapper = styled.div`
  height: calc(100% - 80px);
  height: 100%;
  width: 100%;
`;
