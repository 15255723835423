import { useState } from "react";
import Button from "../components/Button";
import axios from "axios";
import styled from "styled-components";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const ForgotPassword = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const [email, setEmail] = useState("");
  const handleChange = (e: any) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    axios
      .post(baseUrl + "api/auth/forgot-password", { email })
      .then((response) => {
        if (response.data) {
          localStorage.setItem(
            "eftd-forgotpasswordtoken",
            (response?.data?.data?.data?.token)
          );
          navigate("/forgotpasswordotp");
        }
      })
      .catch((err) => {
        toast.error(
          err?.response?.data?.error?.message || "Invalid Credentials"
        );
        console.log(err);
      });
  };


  return (
    <Wrapper onSubmit={handleSubmit}>
       <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
          gap: "10px",
        }}
      >
        <img width={50} height={50} src="/logo2.svg" alt="" />
        <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
          eFTD Africa
        </p>
      </div>
      <Top>
        {/* <Icon>
          <BsArrowLeft fontSize={"24px"} onClick={handleBack} />
        </Icon> */}
        <Text>Request Password Reset</Text>
        <div style={{ textAlign: "left" }}>
        <label>Email</label>
        <InputWrapper>
          <Input
            type="text"
            name="email"
            onChange={handleChange}
            placeholder="Enter email here"
            required
            pattern="[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-z]{2,}$"
          />
        </InputWrapper>
        </div>    
        <Button style={{ color: "#ffffff", width: "100%", backgroundColor: "#000" }} text="Request Reset" />
      </Top>
    </Wrapper>
  );
};

export default ForgotPassword;

export const Top = styled.section`
   display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;
export const Wrapper = styled.form`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Text = styled.p`
  color: #333333;
  font-weight: 300;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  font-size: 24px;
`;

export const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;

export const Icon = styled.p`
  width: 40vw;
  text-align: left;
  cursor: pointer;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;