import axios from "axios";
import { useState } from "react";
import styled from "styled-components";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import utils from "../utils/utils";
const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

const datas = {
  shipments: [
    {
      id: "2164397034",
      service: "express",
      origin: {
        address: {
          addressLocality: "LAGOS - NIGERIA",
        },
        servicePoint: {
          url: "http://www.dhl.com.ng/en/country_profile.html",
          label: "Origin Service Area",
        },
      },
      destination: {
        address: {
          addressLocality: "RICHMOND, VA - USA",
        },
        servicePoint: {
          url: "http://www.dhl-usa.com/en/country_profile.html",
          label: "Destination Service Area",
        },
      },
      status: {
        timestamp: "2023-05-07T23:15:00",
        location: {
          address: {
            addressLocality: "BRUSSELS - BELGIUM",
          },
        },
        statusCode: "transit",
        status: "transit",
        description: "Scheduled to depart on the next planned movement",
      },
      estimatedTimeOfDelivery: "2023-05-10",
      estimatedTimeOfDeliveryRemark: "By End of Day",
      details: {
        proofOfDelivery: {
          signatureUrl:
            "https://proview.dhl.com/proview/adhocnotify?id=H%2FuNlVLtE%2BweJV%2BISrT6CpFIIeeuSJqyq5s8AKmHCQk%3D&appuid=G4uWyg2k2zMljhQ7T95pBQ%3D%3D&locale=en_NG&token=gxupFLLLi%2B8z1UiT9DaiRLA0V4qA124LF2O22zX5298%3D",
          documentUrl:
            "https://proview.dhl.com/proview/adhocnotify?id=H%2FuNlVLtE%2BweJV%2BISrT6CpFIIeeuSJqyq5s8AKmHCQk%3D&appuid=G4uWyg2k2zMljhQ7T95pBQ%3D%3D&locale=en_NG&token=gxupFLLLi%2B8z1UiT9DaiRLA0V4qA124LF2O22zX5298%3D",
        },
        proofOfDeliverySignedAvailable: false,
      },
      events: [
        {
          timestamp: "2023-05-07T23:15:00",
          location: {
            address: {
              addressLocality: "BRUSSELS - BELGIUM",
            },
          },
          description: "Scheduled to depart on the next planned movement",
        },
        {
          timestamp: "2023-05-06T19:18:00",
          location: {
            address: {
              addressLocality: "ACCRA - GHANA",
            },
          },
          description:
            "Shipment has departed from a DHL facility ACCRA - GHANA",
        },
        {
          timestamp: "2023-05-06T15:58:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description:
            "Shipment has departed from a DHL facility LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-06T15:25:00",
          location: {
            address: {
              addressLocality: "ACCRA - GHANA",
            },
          },
          description: "Shipment is in transit to destination",
        },
        {
          timestamp: "2023-05-06T10:52:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description: "Processed at LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-06T09:44:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description: "Arrived at DHL Sort Facility LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-05T19:10:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description: "Arrived at DHL Sort Facility LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-05T17:29:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description:
            "Shipment has departed from a DHL facility LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-05T17:11:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description: "Processed at LAGOS - NIGERIA",
        },
        {
          timestamp: "2023-05-05T13:48:00",
          location: {
            address: {
              addressLocality: "LAGOS - NIGERIA",
            },
          },
          description: "Shipment picked up",
        },
      ],
    },
  ],
};
export const TrackingNoAuthPage = () => {
  const { switchStatusText, getDate, getTime } = utils;
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const token = localStorage.getItem("token");
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  const [orderid, setOrderId] = useState("");
  const [displayId, setDisplayId]=useState('')
  const [trackingDetails, setTrackingDetails] = useState<any>([]);
  const [error, setError] = useState("");

  const TrackOrder = (e: any) => {
    e.preventDefault();
    axios
      .get(`${baseUrl}api/order/track/${orderid}`, config)
      .then((res) => {
        setTrackingDetails(res?.data?.data)
        setDisplayId(orderid)
        })
      .catch((err) => console.log(err));
  };
  const data = datas.shipments[0].events;


  return (
    <Wrapper>
      <Header navlist={placeorderNavList} />
      <Content>
        <Track>
          <h1>Track your order</h1>
          <p>
            Enter your Receipt number that was generated after you placed an
            order
          </p>
          <div>
            <input
              placeholder="Order ID"
              type="text"
              onChange={(e) => {
                setError("");
                setOrderId(e.target.value);
              }}
            />
            <button onClick={TrackOrder}>Track</button>
          </div>
          <Error>{error}</Error>
        </Track>
      </Content>
      {trackingDetails?.orderType === 'domestic' && (
        <OrderDetails>
          <h1>ORDER #{displayId}</h1>
          {trackingDetails?.order_status_history.length > 0 ? (
            trackingDetails?.order_status_history?.map((details: any, index: any) => (
              <div>
                <div
                  style={{
                    backgroundColor: "#CEF8CD",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/check4.svg" alt="" />
                </div>

                <div>
                  <h2>{switchStatusText(details?.status)}</h2>
                  <p>{`${getDate(details?.time)} ${getTime(details?.time)}`}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No order</p>
          )}
        </OrderDetails>
      )}
      {trackingDetails?.orderType === 'cross-border' && <OrderDetails>
        <h1>ORDER #{displayId}</h1>
        {trackingDetails?.order_status_history.length < 1 && trackingDetails?.shipmentEvents.length < 1 && (<p> There is no order</p>) }
        {trackingDetails?.order_status_history.length > 0 && (
            trackingDetails?.order_status_history?.map((details: any, index: any) => (
              <div>
                <div
                  style={{
                    backgroundColor: "#CEF8CD",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/check4.svg" alt="" />
                </div>

                <div>
                  <h2>{switchStatusText(details?.status)}</h2>
                  <p>{`${getDate(details?.time)} ${getTime(details?.time)}`}</p>
                </div>
              </div>
            ))
          )}
        {trackingDetails?.shipmentEvents && trackingDetails?.shipmentEvents.length > 0 ? (trackingDetails?.shipmentEvents?.map((details: any) => (
          <div>
            <p>{`${getDate(details?.timestamp)} ${getTime(
              details?.timestamp
            )}`}</p>
            <div
              style={{
                backgroundColor: "#CEF8CD",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/check4.svg" alt="" />
            </div>

            <div>
              <h2>{details?.description}</h2>
              <h3>{details?.location?.address?.addressLocality}</h3>
            </div>
          </div> 
        ))): null}
      </OrderDetails>
     }
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.main`
  padding: 0;
  background-color: #f5f6f6;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    background: #ffffff;
  }
`;

const Content = styled.section`
  background: #ffffff;
  border-radius: 10px;
  width: 60%;
  margin: 150px auto 30px;
  height: 100%;
  padding: 30px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const OrderDetails = styled.section`
  background: #ffffff;
  border-radius: 10px;
  width: 60%;
  margin-bottom: 100px;
  text-align: left;
  padding: 20px 40px;

  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    margin-top: 50px;
    font-family: "Red Hat Display";
    font-style: normal;
    line-height: 21px;
    font-size: 14px;

    h2 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    p {
      font-weight: 400;
      color: #828282;
    }

    @media screen and (max-width: 600px) {
      flex-direction: column;
      align-items:flex-start ;
      gap: 10px;
  }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 30px;
  }
`;

const Track = styled.form`
  background: #1c1d21;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
    width: 100%;
    gap: 10px;
    background-color: #ffffff;
  }

  button {
    height: 48px;
    padding: 0px 20px;
    background-color: #000000;
    color: #ffffff;
    border: none;
  }

  > h1 {
    color: #ffffff;
  }
  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    margin-top: 5px;
    text-align: left;
  }

  input {
    width: 100%;
    background: #ffffff;
    height: 48px;
    /* border: 1px solid #545454; */
    /* border-radius: 10px; */
    border: none;
    padding-left: 20px;
    outline: none;
  }
`;

const Error = styled.span`
  color: red;
`;
