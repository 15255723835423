import { createSlice} from "@reduxjs/toolkit";

export interface Location {
  deliveryAddress: any | null;
}

const initialState: Location = {
  deliveryAddress: [],
};

export const DeliveryLocationSlice = createSlice({
  name: "deliverylocation",
  initialState,
  reducers: {
    getDeliveryLocation: (state, action) => {
      const location = state.deliveryAddress.find(
        (add: { id: any }) => add.id === action.payload.id
      );
      if (!location) {
        state.deliveryAddress.push(action.payload);
        return;
      }
      if (location) {
        const getIndex = state.deliveryAddress.indexOf(location);
        state.deliveryAddress.splice(getIndex, 1, action.payload);
      }
    },
    deleteDeliveryLocation: (state, action) => {
      state.deliveryAddress.splice(action.payload, 1);
    },
  },
});

export const { getDeliveryLocation, deleteDeliveryLocation } =
  DeliveryLocationSlice.actions;
export default DeliveryLocationSlice.reducer;
