
import Interstatescreen from "../components/Interstate";
import ResponsiveDrawer from "../components/Sidebar";

const Interstate = () => {
  return (
    <ResponsiveDrawer title="Home > Interstate" content={<Interstatescreen/>}/>
  );
};

export default Interstate;