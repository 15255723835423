import React, {useState} from 'react'
import { Helmet } from 'react-helmet'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import colours from '../styles/appColours'

export default function TrackOrder() {
  const navigate = useNavigate()
  const [orderid, setOrderId] = useState('')
  const [error, setError] = useState('')
  const NavigateOrder = () => {
    if (orderid.length !== 8) {
      setError('Not a valid order id')
      return
    };
    navigate(`/order/${orderid}`);
  };
  return (
    <Wrapper>
      <Helmet>
          <title>Track Order - eFTD Africa</title>
          <meta
            name="description"
            content="Official website of eFTD logistics, An Eco-friendly logistics company in Nigeria,Fast and reliable delivery service in Lagos, using Zero emission eletric two-wheeler. eFTD also has an EV Charging station in Nigeria."
          />
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
        </Helmet>
      <Content>
        <Header>Delivery Tracking</Header>
        <Image>
          <img
            src="/ebike.png"
            alt="delivery bike"
            width={"100%"}
            height={"100%"}
          />
        </Image>
        <div>
          {error ? <Error>{error}</Error> : ""}
          <Input onChange={(e) => {
            setError('')
            setOrderId(e.target.value)} 
          }/>
        </div>
        <Button onClick={NavigateOrder}>Track</Button>
      </Content>
    </Wrapper>
  );
}

const Wrapper = styled.main`
  background-color: ${colours.background};
  height: 100%;
  min-height: 100vh;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 6vh;
`

const Header = styled.h1`
  font-size: 48px;
  font-weight: 500;
`
const Error = styled.p`
  color: red;
`;
const Image = styled.div`
  width: 20vh;
  height: 20vw;

`

const Input = styled.input`
  display: block;
  height: 53px;
  width: 500px;
  border-radius: 8px;
  background-color: #fafafa;
  outline: none;
  border: 1px solid ${colours.secondary};
  font-size: 20px;
  padding-left: 20px;
  @media (max-width: 1024px) {
    width: 30vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

const Button = styled.button`
  background-color: ${colours.secondary};
  height: 53px;
  width: 25vw;
  border-radius: 8px;
  border: none;
  color: ${colours.black};
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 90vw;
  }
`;