import { useEffect, useState } from "react";
import styled from "styled-components";
import Header from "../components/header/Header";
import Footer from "../components/footer/Footer";
import { MdEmail, MdPhoneIphone } from "react-icons/md";
import { send } from "emailjs-com";
import { Helmet } from "react-helmet";
import ReactPixel from 'react-facebook-pixel';
import ReactGA from "react-ga";
import BasicModal from "../components/Modal";
import { useLocation, useNavigate } from "react-router-dom";
const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

const Landingpage = () => {
  const location = useLocation();
  const getHash = location.hash;
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const UserId = process.env.REACT_APP_EMAILJS_USERID;
  const ServicesId = process.env.REACT_APP_EMAILJS_SERVICEID as string;
  const TemplateId = process.env.REACT_APP_EMAILJS_TEMPLATEID as string;
  const [loading, setLoading] = useState(false);
  const [toSend, setToSend] = useState({
    customer_Email: "",
    customer_Name: "",
  });
  const [state, setState] = useState("air");
  const [message, setMessage] = useState("");
  const onSubmit = (e: any) => {
    e.preventDefault();
    setLoading(true);
    send(ServicesId, TemplateId, toSend, UserId)
      .then((response: any) => {
        setLoading(false);
        setMessage("success");
      })
      .catch((err: any) => {
        setMessage("failed");
        setLoading(false);
      });
  };
  const handleChange = (e: any) => {
    setToSend({ ...toSend, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (getHash === "#last-mile-delivery") {
      setState("air");
      return;
    }
    if (getHash === "#battery-swapping") {
      setState("land");
      return;
    }
    if (getHash === "#charging-stations") {
      setState("sea");
      return;
    }
    if (getHash === "#ebike-sales") {
      setState("water");
      return;
    }
  }, [getHash]);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactPixel.pageView();
  }, []);
  return (
    <div>
      <Wrapper>
        <Helmet>
          <title>eFTD Africa</title>
          <meta
            name="description"
            content="eFTD Africa is Nigeria's leading eco-friendly last-mile delivery company which focuses on the use of electric motorcycles for its third-party transportation and last-mile delivery services. We specialize in running quick deliveries within Lagos as well as reliable interstate and international deliveries"
          />
          <meta
            name="keywords"
            content="lastmile delivery, Last-mile delivery, Delivery service, delivery service, Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
          />
        </Helmet>
        <Header navlist={placeorderNavList} />
        <ImageBackground>
          <TextWrapping>
            <LandingHeading>
              Get the Last Mile Delivery Service You Can Count On
            </LandingHeading>
            <LandingText>
              Join our 2,000+ family, who currently enjoy fast and seamless
              doorstep delivery service across the globe
            </LandingText>
            <AppWrapper>
              <button
                className="join-button"
                onClick={() => navigate("/signup")}
              >
                Create a free account
              </button>
              {/* <a target="_blank" href="https://www.google.com" rel="noreferrer">
                <AndroidButton className="join-button">
                  <img src="/androidicon.svg" alt="android icon" />
                  <span> Get app on Android</span>
                </AndroidButton>
              </a> */}
            </AppWrapper>
            {/* <RealTimeWrapper>
              <RealTime>
                <img
                  width={150}
                  height={150}
                  src="/19.png"
                  loading="lazy"
                  alt=""
                />
                <h1>RELIABILITY</h1>
                <LandingText className="realtime-text">
                  For almost a decade, clients have trusted our professionals to
                  deliver packages safely and at the promised time.
                </LandingText>
              </RealTime>
              <RealTime>
                <img
                  src="/18.png"
                  width={150}
                  height={150}
                  loading="lazy"
                  alt=""
                />
                <h1>SUSTAINABILITY</h1>
                <LandingText className="realtime-text">
                  We deliver anywhere with a 100% carbon-neutral footprint
                  through electric motorcycles.
                </LandingText>
              </RealTime>
            </RealTimeWrapper> */}
          </TextWrapping>

          <BoxImage>
            <img
              style={{ borderRadius: "12px" }}
              src="/background.jpeg"
              height={"100%"}
              width={"100%"}
              alt=""
              loading="lazy"
            />
          </BoxImage>
        </ImageBackground>
        <Mainserviceswrapper>
          <Mainservices>
            <h2>Our Services</h2>
            {/* <p>
              We provide services in the field of road, sea, rail, air
              transportation and a full range of warehousing services.
            </p> */}
          </Mainservices>
        </Mainserviceswrapper>
        <Videoswrapper>
          <Buttonwrap>
            <button
              style={{
                backgroundColor: state === "air" ? "#F66D00" : "inherit",
              }}
              onClick={() => setState("air")}
              id="last-mile-delivery"
            >
              <img loading="lazy" src="/bikeicon.svg" alt="" />
              Last-mile Delivery
            </button>
            <button
              style={{
                backgroundColor: state === "land" ? "#F66D00" : "inherit",
              }}
              onClick={() => setState("land")}
              id="battery-swapping"
            >
              <img loading="lazy" src="/batteryswappingicon.svg" alt="" />
              Battery Swapping
            </button>
            <button
              style={{
                backgroundColor: state === "sea" ? "#F66D00" : "inherit",
              }}
              onClick={() => setState("sea")}
              id="charging-stations"
            >
              <img loading="lazy" src="/batterychargingicon.svg" alt="" />
              Charging Stations
            </button>
            <button
              style={{
                backgroundColor: state === "water" ? "#F66D00" : "inherit",
              }}
              onClick={() => setState("water")}
              id="ebike-sales"
            >
              <img loading="lazy" src="/sustainable.svg" alt="" />
              eBike Sales and Financing
            </button>
          </Buttonwrap>
          {state === "air" && (
            <Videocontent>
              <div>
                <h3>Last-mile Delivery</h3>
                <p>
                  We are experts at providing reliable last-mile delivery
                  service to our customers. With our electric motorcycles with
                  swappable batteries "Zumi", we deliver your parcels faster,
                  safer and more efficiently than you have ever experienced.
                </p>
                <AppWrapper>
                  <button
                    className="join-button"
                    onClick={() => navigate("/signup")}
                  >
                    Create a free account
                  </button>
                </AppWrapper>
              </div>
              <div>
                <img
                  style={{ border: "none", objectFit: "contain" }}
                  width="100%"
                  height="400px"
                  loading="lazy"
                  alt=""
                  src="/individual.png"
                />
              </div>
            </Videocontent>
          )}
          {state === "land" && (
            <Videocontent>
              <div>
                <h3>Locate swap stations near you</h3>
                <p>
                  With this service, we help riders reduce the charging time of
                  e- bikes, making it an excellent alternative for electric
                  two-wheeler users who want to experience uninterrupted speed
                  and convenience.
                </p>
                <AppWrapper>
                <a
                    target="_blank"
                    href="https://www.google.com/maps/d/viewer?ll=6.494756099999965%2C3.3578150999999856&z=10&mid=1PPR2uBCoSAifHw8zQsHufHhACh1XLsY"
                    rel="noreferrer"
                  >
                    <button className="join-button">View Swap Stations </button>
                  </a>
                </AppWrapper>
              </div>
              <div className="imageWrapper">
                <img
                  style={{
                    border: "none",
                    borderRadius: "10px",
                    objectFit: "contain",
                  }}
                  width="100%"
                  height="400px"
                  loading="lazy"
                  alt=""
                  src="/batteryswap.png"
                />
              </div>
            </Videocontent>
          )}
          {state === "sea" && (
            <Videocontent>
              <div>
                <h3>EV Charging Stations</h3>
                <p>
                  Our network of charging stations across various cities in
                  Nigeria makes it easy for EV riders to recharge depleted
                  batteries in no time.
                </p>
                <AppWrapper>
                <a
                    target="_blank"
                    href="https://www.google.com/maps/d/viewer?ll=6.494756099999965%2C3.3578150999999856&z=10&mid=1PPR2uBCoSAifHw8zQsHufHhACh1XLsY"
                    rel="noreferrer"
                  >
                   <button className="join-button">View Charging Stations </button>
                  </a>
                </AppWrapper>
              </div>
              <div className="edit-image">
                <img
                  style={{
                    borderRadius: "12px",
                    border: "none",
                    objectFit: "cover",
                  }}
                  width="100%"
                  height="400px"
                  loading="lazy"
                  alt="swap station"
                  src="/swapstation.jpeg"
                />
              </div>
            </Videocontent>
          )}
          {state === "water" && (
            <Videocontent>
              <div>
                <h3>eBike Sales and Financing</h3>
                <p>
                  Beyond outright sales of our electric motorcycles, we also
                  offer a robust vehicle financing scheme that allows you to
                  become an owner in 6-12 months. This comes with a flexible
                  payment plan, insurance and other benefits. If you would like
                  to know more about this, kindly click the button below.
                </p>
                <AppWrapper>
                  <a
                    target="_blank"
                    href="https://docs.google.com/forms/d/e/1FAIpQLSecF74duHW8DQuKSLPRlVGRyEUHJm-4hiMD2AQi7cXNfLDiQA/viewform?usp=sharing"
                    rel="noreferrer"
                  >
                    <button className="join-button">Yes, I'm interested</button>
                  </a>
                </AppWrapper>
              </div>
              <div className="edit-image">
                <img
                  style={{
                    borderRadius: "12px",
                    border: "none",
                    objectFit: "cover",
                  }}
                  width="100%"
                  height="400px"
                  loading="lazy"
                  alt="ebike"
                  src="/bike.png"
                />
              </div>
            </Videocontent>
          )}
        </Videoswrapper>
        <OverallWrap>
          <WhyBest>
            <div className="left-side">
              <h2 className="large-header">
                Why is eFTD the best Logistics Choice in Africa?{" "}
              </h2>
            </div>
            <div className="right-side">
              <div>
                <div>
                  <img
                    src="/start.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Reliability</h1>
                <p>
                  For almost a decade, clients have trusted our professionals to
                  deliver packages safely and at the promised time.
                </p>
              </div>
              <div>
                <div>
                  <img
                    src="/lightning.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Sustainability</h1>
                <p>
                  We deliver anywhere with a 100% carbon-neutral footprint
                  through electric motorcycles.
                </p>
              </div>
            </div>
          </WhyBest>
        </OverallWrap>
        <SpecialWrapper>
          <Specialfeautures>
            <h1>Why choose us?</h1>
            <div>
              <Features>
                <div>
                  <img
                    src="/fastfood.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Fast Food Delivery</h1>
                <LandingText className="features">
                  Our mobile app enables our team of professional riders execute
                  orders quickly. This helps you have your meal delivered while
                  it&#39;s fresh and hot so you can enjoy it with your loved
                  ones.
                </LandingText>
              </Features>
              <Features>
                <div>
                  <img
                    src="/subscription.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Game-changing Subscription Plans</h1>
                <LandingText className="features">
                  Revolutionize your business delivery solutions with our
                  tailored packages. Choose a subscription plan that fits your
                  business needs, increase reliability, and enjoy seamless
                  delivery at a fraction of the cost.
                </LandingText>
              </Features>
              <Features>
                <div>
                  <img
                    src="/customersupport.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Active Customer Service</h1>
                <LandingText className="features">
                  We make life easier for businesses and their customers by
                  ensuring that our customer representatives are always
                  available to communicate your orders&#39; progress and
                  process.
                </LandingText>
              </Features>
              <Features>
                <div>
                  <img
                    src="/tracking.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Real-time Tracking</h1>
                <LandingText className="features">
                  Our app offers real-time tracking, chat support, and all the
                  necessary features to get your parcel to your doorstep easily.
                </LandingText>
              </Features>
              <Features>
                <div>
                  <img
                    src="/doorstepdelivery.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>Doorstep Delivery</h1>
                <LandingText className="features">
                  Enjoy the convenience of doorstep delivery locally and
                  internationally. No matter the distance, we bring your
                  packages directly to your doorstep, eliminating the need for
                  extra trips or pickups. Experience hassle-free logistics and
                  peace of mind, knowing your deliveries are just a step away.
                </LandingText>
              </Features>
              <Features>
                <div>
                  <img
                    src="/internationalshipping.svg"
                    width={"100%"}
                    height={"100%"}
                    loading="lazy"
                    alt=""
                    className="services-image"
                  />
                </div>
                <h1>International Shipping</h1>
                <LandingText className="features">
                  Experience a world of convenience and affordability with our
                  international delivery service. Say goodbye to the hassle of
                  customs and inflated shipping costs. From distant shores to
                  your doorstep, we bring you the best prices in the industry
                </LandingText>
              </Features>
            </div>
          </Specialfeautures>
        </SpecialWrapper>

        <Solutionswrapper>
          <Solutions>
            <div>
              <h1>Meet Zumi</h1>
              <img width={400} height={400} alt="zumi bike" src="/ebike.png"  loading="lazy"/>
              <p>
                In a large crowd of other last-mile delivery companies in
                Africa, we choose to stand out by rendering our services with
                our electric motorcycles called Zumi for the following reasons;
              </p>
            </div>
            <section>
              <div>
                <span>
                  <img
                    width={"60%"}
                    height={"100%"}
                    src="/sustainable.svg"
                    loading="lazy"
                    alt=""
                    className="our-solutions-image"
                  />
                </span>
                <h1>Sustainable & Affordable</h1>
                <p>
                  Regardless of her upfront cost, Zumi is cheaper to maintain
                  over time when compared to its gasoline counterparts, thanks
                  to the absence of fuel and maintenance costs.
                </p>
              </div>
              <div>
                <span>
                  <img
                    width={"60%"}
                    height={"100%"}
                    src="/easy.svg"
                    loading="lazy"
                    alt=""
                    className="our-solutions-image"
                  />
                </span>
                <h1>Environmental Friendly</h1>
                <p>
                  The motorcycle has zero emission rate, making it a safer
                  option for achieving climate neutrality, and makes the
                  environment habitable for all which is the crux of our
                  service.
                </p>
              </div>
              <div>
                <span>
                  <img
                    width={"60%"}
                    height={"100%"}
                    src="/envfriendly.svg"
                    loading="lazy"
                    alt=""
                    className="our-solutions-image"
                  />
                </span>
                <h1>Easy to Ride</h1>
                <p>
                  Despite their durability and apparent advantages over
                  traditional road motorcycles, Zumi can be handled and driven
                  by a novice at motorcycling, requiring zero new or unique
                  skills.
                </p>
              </div>
            </section>
          </Solutions>
        </Solutionswrapper>
        {/* <Logowrapper>
          <div>
            Logo
            <div
              className="card-circle"
            ></div>
          </div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
          <div>Logo</div>
        </Logowrapper> */}
        <Questionswrapper>
          <Questions>
            <img src="/26.png" alt="" width={"40%"} loading="lazy" />
            <div>
              <h1>Got Questions?</h1>
              <h3>We are waiting to hear from you!</h3>
              <p>
                Our excellent customer support representatives are always
                available to provide you with the necessary support or
                information you may need.
              </p>
              <button className="neon">
                <span className="neon-phone-icon">
                  {" "}
                  <MdEmail />
                </span>
                info@eftdafrica.com
              </button>
              <button className="white">
                <span className="white-phone-icon">
                  <MdPhoneIphone />
                </span>
                +234-915-436-8523
              </button>
            </div>
          </Questions>
        </Questionswrapper>
        {/* <EmaillistWrapper>
          <Emaillist>
            <h1>Join the Waitlist</h1>
            <p>
              We are working efficiently to serve you better, to keep up to date
              on our services, kindly join the waitlist to be one of the first
              people notified.
            </p>
            <div>
              <input
                name="customer_Email"
                value={toSend.customer_Email}
                onChange={handleChange}
                autoComplete="off"
                pattern="[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-zA-Z]{2,4}"
                required
                placeholder="Enter your Email Address"
                type="email"
              />
              <button disabled={loading} onClick={onSubmit}>
                {loading ? "Loading..." : "Join"}
              </button>
            </div>
            {message === "success" && (
              <p className="success">Thanks for joining our waiting list.</p>
            )}
            {message === "failed" && (
              <p className="error">
                Oops something went wrong, please try again..
              </p>
            )}
          </Emaillist>
        </EmaillistWrapper> */}

        {/* <LoginSection>
          <ImageWrapper>
            <img
              src={"/femalerider.jpeg"}
              width={"100%"}
              height={"100%"}
              alt="handling parcel"
            />
          </ImageWrapper>

          <TextButton>
            <Text>
              Ultimately, eFTD aims to make your remote and immediate logistics
              needs easier while keeping the environment safer and the
              atmosphere cleaner.
            </Text>
          </TextButton>
        </LoginSection> */}
        <Footer />
      </Wrapper>
      <BasicModal
        content={<>hello</>}
        open={openModal}
        close={() => {
          setOpenModal(false);
        }}
        height={"100"}
        width={undefined}
      />
    </div>
  );
};

export default Landingpage;

const Wrapper = styled.main`
  margin: 0;
  margin-top: 150px;
  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
`;
const ImageBackground = styled.div`
  display: flex;
  justify-content: space-between;
  width: 90%;
  margin: 50px auto 25px;
  gap: 1%;
  max-width: 1920px;

  @media screen and (max-width: 991px) {
    align-items: flex-start;
    flex-direction: column;
    gap: 20px;
    height: 100%;
    background-image: none;
    text-align: left;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    text-align: center;
    align-items: center;
    margin: 20px auto 30px;
  }
`;
const RealTimeWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 40px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;

const AndroidButton = styled.button`
  padding: 22px 92px;
  margin-top: 20px;
  background: #ffffff;
  font-family: "Red Hat Display";
  color: #333333;
  border: 1px solid #333333;
  display: flex;
  align-items: center;
  gap: 10px;
`;

const RealTime = styled.div`
  width: 40%;
  border: 1px solid #ffffff;
  text-align: left;

  h1 {
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
  }
  @media screen and (max-width: 991px) {
    width: 50%;
    text-align: center;
    padding: 20px;

    .realtime-text {
      text-align: center;
    }
  }
  @media screen and (max-width: 600px) {
    text-align: center;
    margin-bottom: 20px;
    width: 80%;
  }
`;
const Features = styled.div`
  width: 32%;
  min-width: 320px;
  border: 1px solid #ffffff;
  text-align: left;
  padding: 20px 40px;
  background: #f5f6f6;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;

  h1 {
    font-weight: 600;
    margin: 20px 0px 10px 0px;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: #121212;
    text-align: center;
  }
  .features {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #333333;
  }
  > div {
    width: 40px;
    /* background-color: #000; */
  }
  @media screen and (max-width: 1024px) {
    width: 48%;
    text-align: left;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }

    .features {
      text-align: left;
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    text-align: left;
    margin-bottom: 20px;

    h1 {
      font-size: 24px;
      line-height: 32px;
    }

    .features {
      text-align: left;
    }
  }
`;
const LandingHeading = styled.h3`
  color: #000000;
  line-height: 90px;
  font-size: 70px;
  width: 100%;
  margin: 0;
  text-align: left;
  @media screen and (max-width: 1440px) {
    font-size: 48px;
    line-height: 58px;
    width: 100%;
    text-align: left;
  }
  @media screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
    width: 90%;
    margin: 0 auto;
    text-align: center;
  }
`;

const LandingText = styled.p`
  width: 80%;
  text-align: left;
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;

  /* eFTD- Dark 2 */

  color: #545454;
  @media screen and (max-width: 1440px) {
    font-size: 18px;
    width: 100%;
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    text-align: center;
  }
`;
const AppWrapper = styled.div`
  /* text-align: left; */
  /* width: 500px; */
  margin: 40px 0;
  @media screen and (max-width: 600px) {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
`;

const WhyBest = styled.div`
  max-width: 1920px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  /* height: 200px; */
  color: #ffffff;
  text-align: left;

  .left-side {
    width: 90%;
    padding: 50px 30px 50px 0px;
    h2 {
      width: 75%;
      margin-bottom: 40px;
      font-weight: 900;
      font-size: 54px;
      line-height: 71px;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      width: 90%;
      text-align: left;
    }
  }
  .right-side {
    width: 90%;
    display: flex;
    justify-content: space-around;

    > div {
      border: 0.5px solid #f5f6f6;
      border-radius: 10px;
      width: 49%;
      text-align: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      padding: 50px;
      > div {
        width: 50px;
        height: 50px;
      }
      h1 {
        margin-top: 20px;
        margin-bottom: 10px;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
      }
      p {
        font-weight: 400;
        font-size: 20px;
        /* width: 90%; */
        text-align: center;
      }
    }
  }
  @media screen and (max-width: 991px) {
    width: 100%;
    flex-direction: column;

    .left-side {
      padding: 0px 10px;
      width: 100%;
      margin-bottom: 60px;
      h2 {
        width: 100%;
      }
    }
    .right-side {
      flex-direction: column;
      padding: 0px 10px;
      width: 100%;
      margin-bottom: 30px;

      > div {
        width: 90%;
        margin: 20px auto;
        padding: 30px;
      }
    }
  }
  @media screen and (max-width: 768px) {
    width: 100%;
    flex-direction: column;

    .left-side {
      padding: 0px 10px;
      width: 100%;
      margin-bottom: 60px;
      h2 {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 10px;
        text-align: center;
      }
      p {
        font-size: 16px;
      }
    }
    .right-side {
      padding: 0px 10px;
      width: 100%;
      margin-bottom: 30px;
      h1 {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      p {
        font-size: 16px;
      }
    }
  }
`;

const SpecialWrapper = styled.section`
  background-color: #ffffff;
  padding: 100px 0px;
`;
const Mainserviceswrapper = styled.section`
  background-color: #000000;
  padding: 60px 0px 30px 0px;
`;

const Videoswrapper = styled.section`
  margin: -300px 0px 200px 0px;
  height: 500px;

  @media screen and (max-width: 991px) {
    margin: -320px 0px 20px 0px;
    height: 100%;
  }
`;

const Solutionswrapper = styled.section`
  background-color: #fde8d8;
  padding: 100px 0px;

  @media screen and (max-width: 600px) {
    padding: 20px 0px 0px 0px;
  }
`;
const EmaillistWrapper = styled.section`
  background-color: #e1f8f9;
  padding: 100px 0px;

  @media screen and (max-width: 600px) {
    padding: 30px 0px;
  }
`;

const Emaillist = styled.div`
  max-width: 1440px;
  margin: 0 auto;

  > h1 {
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 42px;
    text-align: center;
    color: #121212;
    margin-bottom: 10px;
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    text-align: center;
    color: #121212;
    width: 60%;
    margin: auto;
  }
  .success {
    color: green;
    font-size: 14px;
    margin-top: 20px;
  }

  .failed {
    color: red;
    font-size: 14px;
    margin-top: 20px;
  }
  > div {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    input {
      width: 45%;
      height: 80px;
      background: #ffffff;
      border: 1px solid #828282;
      border-radius: 10px;
      margin-right: 20px;
      padding-left: 20px;
      outline: none;
    }
    button {
      width: 20%;
      height: 80px;
      color: #ffffff;
      background: #04030b;
      border-radius: 10px;
      font-weight: 700;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #ffffff;
      border: none;
    }
  }
  @media screen and (max-width: 600px) {
    > p {
      width: 90%;
    }
    > div {
      input {
        height: 50px;
        width: 60%;
      }
      button {
        height: 50px;
        width: 30%;
      }
    }
  }
`;

const Questionswrapper = styled.section`
  background-color: #f4f9ff;
  padding: 0px 0px 0px 0px;
`;

const Questions = styled.div`
  max-width: 1440px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  > img {
    object-fit: contain;
    aspect-ratio: 6/6;
  }
  > div {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0px 20px;
    margin-top: 80px;
    gap: 10px;
    > h1 {
      margin-top: 0px;
      margin-bottom: 10px;
      color: #121212;
      font-weight: 900;
      font-size: 32px;
      line-height: 42px;
      text-align: center;
    }
    > p {
      max-width: 470px;
      margin-bottom: 30px;
      color: #4b4b4b;
      font-size: 18px;
      line-height: 28px;
    }
    > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 300px;
      padding: 6px 20px 6px 6px;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border: none;
      border-radius: 100px;
      background-color: #31eaf4;
      color: #fff;
      font-size: 20px;
      font-weight: 500;
      text-decoration: none;
    }

    .white {
      color: #001c34;
      background-color: #fff;
      border: 1px solid #001c34;
    }

    .neon-phone-icon {
      display: flex;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
      color: #31eaf4;
    }

    .white-phone-icon {
      box-shadow: 1px 1px 3px 0 #000;
      display: flex;
      width: 40px;
      height: 40px;
      margin-right: 10px;
      -webkit-box-pack: center;
      -webkit-justify-content: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-box-align: center;
      -webkit-align-items: center;
      -ms-flex-align: center;
      align-items: center;
      border-radius: 50%;
      background-color: #fff;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    > div {
      padding: 20px;
    }
    > img {
      width: 100%;
    }
  }
`;

const Logowrapper = styled.section`
  background-color: #2c2d32;
  color: #ffffff;
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  > div {
    width: 20%;
    height: 265px;
    border-right: 1px solid #ffffff;
    border-bottom: 1px solid #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;

    .card-circle {
      position: absolute;
      left: auto;
      top: -15%;
      right: -15%;
      bottom: auto;
      z-index: 1;
      width: 10%;
      height: 10%;
      border-radius: 100%;
      background-color: #ec7216;
    }

    @media screen and (max-width: 991px) {
      height: 140px;
    }
  }
`;

const Solutions = styled.div`
  max-width: 1920px;
  margin: auto;

  > div {
    h1 {
      max-width: 860px;
      margin: 0px auto 20px;
      color: #001c34;
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      text-transform: capitalize;
    }
    > img {
      width: 400px;
      height: 400px;
    }
    p {
      max-width: 680px;
      margin-right: auto;
      margin-bottom: 0px;
      margin-left: auto;
      font-weight: 500;
      font-size: 24px;
      line-height: 32px;
      text-align: center;
      color: #000000;
    }
  }

  > section {
    /* border-top: 1px solid #a5a5a5; */
    width: 80%;
    margin: 100px auto;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      width: 30%;
      border-radius: 10px;
      padding: 20px;
      background: linear-gradient(to right, white 50%, #31eaf4 50%);
      transition: all 0.5s ease-in;
      background-position: left bottom;
      background-size: 200% 100%;

      /* > img {
        height: 70px;
        margin-right: 20px;
      } */

      > span {
        height: 50px;
        width: 50px;
        background-color: #000000;
        border-radius: 50%;
        margin-right: 20px;
        position: absolute;
        left: 45%;
        top: -30px;
      }

      > h1 {
        margin-top: 15px;
        margin-bottom: 15px;
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #121212;
      }
      > p {
        margin-bottom: 0px;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;

        /* eFTD- Dark 2 */

        color: #545454;
      }

      @media screen and (max-width: 991px) {
        width: 100%;
        padding: 20px 10px;
        margin: 20px auto;
      }
      @media screen and (max-width: 600px) {
        flex-direction: column;
        padding: 30px;
        margin: 20px auto;

        > img {
          height: 50px;
          width: 50px;
          margin-bottom: 30px;
        }

        > div {
          padding: 30px;

          > h1 {
            font-size: 20px;
          }
          > p {
            font-size: 16px;
          }
        }
      }
    }
    > div:hover {
      background-position: right bottom;
    }
  }

  @media screen and (max-width: 991px) {
    flex-direction: column;
  }

  @media screen and (max-width: 991px) {
    > div {
      > h1 {
        font-size: 24px;
        line-height: 32px;
      }
      > p {
        padding: 20px;
      }
    }
  }
  @media screen and (max-width: 600px) {
    width: 90%;
    margin: 40px auto;
    border-top: none;
    > div {
      > img {
        width: 300px;
        height: 300px;
      }
      /* border: 1px solid #a5a5a5; */
    }
    > section {
      width: 100%;
    }
  }
`;

const Buttonwrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1920px;
  margin: auto;
  margin-bottom: 75px;
  cursor: pointer;
  gap: 40px;

  > button {
    /* margin-right: 40px; */
    padding: 15px 30px;
    border: 1px solid #fff;
    background-color: transparent;
    color: #fff;
    font-size: 16px;
    font-weight: 600;
    border-radius: 12px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    cursor: pointer;

    @media screen and (max-width: 768px) {
      width: 100%;
    }
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    gap: 20px;
  }
`;
const Videocontent = styled.div`
  padding: 64px;
  background-color: #fff;
  box-shadow: 1px 1px 47px 0 hsl(0deg 0% 51% / 14%);
  width: 60%;
  margin: auto;
  display: flex;
  align-items: center;
  text-align: left;
  > div {
    width: 50%;

    h3 {
      margin-top: 0px;
      margin-bottom: 25px;
      color: #001c34;
      font-size: 48px;
      line-height: 58px;
      font-weight: 600;
      text-transform: capitalize;
    }
    p {
      max-width: 535px;
      margin-bottom: 35px;
      color: #4b4b4b;
      font-size: 20px;
      line-height: 30px;
    }
  }
  @media screen and (max-width: 1440px) {
    width: 80%;
    > div {
      h3 {
        font-size: 24px;
        line-height: 30px;
      }
    }
    .edit-image {
      img {
        width: 90%;
        height: 100%;
      }
    }
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    text-align: center;
    padding: 20px;

    > div {
      width: 100%;
      h3 {
        font-size: 28px;
        line-height: 38px;
      }
      p {
        margin: 20px auto;
      }
      img {
        width: 90%;
        height: 100%;
      }
    }
  }
`;

const Mainservices = styled.section`
  height: 400px;
  color: #ffffff;
  text-align: center;
  > h2 {
    margin-top: 0px;
    margin-bottom: 18px;
    font-weight: 600;
    font-weight: 900;
    font-size: 54px;
    line-height: 71px;

    color: #ffffff;
  }
  > p {
    max-width: 750px;
    margin-right: auto;
    margin-bottom: 0px;
    margin-left: auto;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #ffffff;
  }
  @media screen and (max-width: 991px) {
    flex-direction: column;
    height: 400px;

    > h2 {
      font-size: 32px;
      line-height: 40px;
    }
  }

  @media screen and (max-width: 600px) {
    > h1 {
      font-size: 28px;
      line-height: 36px;
    }
    > p {
      font-size: 16px;
      line-height: 24px;
    }
  }
`;

const Specialfeautures = styled.section`
  margin: 0 auto;
  max-width: 1920px;

  > h1 {
    margin-bottom: 60px;
    text-align: center;
    width: 100%;
    padding-left: 30px;
    font-weight: 600;
    font-style: normal;
    font-weight: 900;
    font-size: 32px;
    line-height: 42px;
    text-transform: uppercase;

    /* Dark 1 */

    color: #121212;
  }

  > div {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  @media screen and (max-width: 1440px) {
    padding: 50px;
  }
  @media screen and (max-width: 768px) {
    padding: 20px;
    > h1 {
      width: 100%;
      font-size: 32px;
      line-height: 40px;
      padding-left: 0px;
    }

    > div {
      flex-direction: column;
    }
  }
  @media screen and (max-width: 600px) {
    > h1 {
      font-size: 24px;
      line-height: 32px;
    }
  }
`;

const TextWrapping = styled.div`
  width: 50%;
  padding: 20px 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  @media screen and (max-width: 991px) {
    width: 100%;
    height: 100%;
    padding: 0;
    gap: 20px;
    justify-content: center;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
    padding: 0;
    gap: 20px;
    align-items: center;
    justify-content: center;
  }
`;
const BoxImage = styled.div`
  width: 50%;
  > img {
    aspect-ratio: 9/6;
    object-fit: cover;
    /* aspect-ratio: 6/6;
    object-fit: contain;
    border-radius: 16px; */

    /* @media screen and (max-width: 1024px) {
      width: 100%;
      object-fit: contain;
      aspect-ratio: 4/4;
      z-index: 3;
    } */
    @media screen and (max-width: 600px) {
      object-fit: cover;
      width: 90%;
    }
  }

  @media screen and (max-width: 1024px) {
    object-fit: cover;
    width: 100%;
  }
  /*
  @media only screen and (min-device-width: 700px) and (max-device-width: 1020px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
    width: 100%;
  } */
`;

const LoginSection = styled.section`
  display: flex;
  align-items: flex-start;
  width: 90%;
  margin: 100px auto 50px auto;
  gap: 30px;
  max-width: 1920px;
  @media screen and (max-width: 600px) {
    flex-direction: column;
  }
`;
const OverallWrap = styled.section`
  width: 100%;
  padding: 0px 0px 100px 0px;
  background-color: #000000;
  @media screen and (max-width: 991px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 600px) {
    padding: 20px 0;
  }
`;
const CardWrapper = styled.section`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 90%;
  background-color: #000000;
  margin-bottom: 150px;
  margin: 0 auto;
  border-radius: 8px;
  max-width: 1920px;
  @media screen and (max-width: 1200px) {
    justify-content: space-between;
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const TextButton = styled.article`
  width: 50%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
  @media screen and (max-width: 600px) {
    width: 100%;
    margin: auto;
  }
`;

const ImageWrapper = styled.div`
  width: 50%;
  > img {
    aspect-ratio: 4/4;
    object-fit: cover;
    border-radius: 16px;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 100%;
  }
`;

const Text = styled.p`
  color: #8c8888;
  font-weight: 300;
  line-height: 40px;
  text-align: left;
  margin-top: 0;
  font-size: 24px;
`;
