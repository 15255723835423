import * as React from "react";
import {useQuery} from "react-query"
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { BsPerson } from "react-icons/bs";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import styled from "styled-components";
import { Link, useLocation } from "react-router-dom";
import colours from "../styles/appColours";
import authService from "../redux/auth/authService";
import Switch from "@mui/material/Switch";
import { useDispatch , useSelector} from "react-redux";
import { setEnvironment } from "../redux/environment/env";
import { RootState } from "../redux/store";
import api from "../services/api";

const drawerWidth = 250;
const sidebarInfo = [
  { name: "Home", route: "/home", icon: "/dashboardhome.svg" },
  // { name: "Place an order", route: "/placeorder", icon: "/placeordericon.png" },
  { name: "Wallet", route: "/wallet", icon: "/walleticon.png" },
  {
    name: "Order History",
    route: "/history",
    icon: "/orderhistoryicon.svg",
  },
  {
    name: "Subscriptions",
    route: "/subscription",
    icon: "/subscriptionicon.png",
  },
  { name: "Settings", route: "/profile", icon: "/settings.svg" },
];

interface Props {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window;
  title?: string;
  content?: React.ReactNode;
}

export default function ResponsiveDrawer(props: Props) {
  const { window, title, content } = props;
  const apiRequest = api();
  const { data: user } = useQuery("USER_PROFILE",  () => apiRequest.getUserProfile());
  const [dropdown, setDropdown] = React.useState(false);
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const [pathname, setPathname] = React.useState(
    "/" + location.pathname.split("/")[1]
  );
  const env = useSelector(
    (state: RootState) => state.env.env
  );

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const handleDropDown = () => {
    setDropdown(!dropdown);
  };

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let env
    if (event.target.checked){
      env = 'live'
    }else {
      env = 'test'
    }
    dispatch(setEnvironment(env));
    setChecked(event.target.checked);
  };

  const drawer = (
    <div>
      {/* <Toolbar /> */}
      <Logo>
        <img width={30} height={30} src="/logo2.svg" alt="" />
        <p>eFTD Africa</p>
      </Logo>
      <Divider />
      <div style={{display:'flex', flexDirection:'column', justifyContent: 'space-between', alignItems: 'space-between'}}>
      <List>
        {sidebarInfo.map((text, index) => (
          <ListItems pathname={pathname} key={index} to={text.route}>
            <ListItem
              style={{
                margin: "20px auto",
                color: colours.black,
                width: "80%",
                height: "40px",
                fontWeight: "800",
              }}
              button
            >
              <ListItemIcon
                style={{
                  margin: "0px",
                  minWidth: "37px",
                }}
              >
                <img src={text.icon} width={20} height={20} alt={text.icon} />
              </ListItemIcon>
              <ListItemText
                style={{
                  margin: "0px",
                  fontWeight: "700 !important",
                }}
                className="sidebar-text"
                primary={text.name}
                sx={{ fontSize: "1.2rem", fontWeight: "800" }}
              />
            </ListItem>
          </ListItems>
        ))}
      </List>
      <SwitchWrapper>
          <Switch
            color="warning"
            checked={env === 'live' ? true : false}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <p>Live Mode</p>
        </SwitchWrapper>
      </div>
    </div>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  return (
    <Box sx={{ display: "flex", margin: "0px" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        color="secondary"
        style={{ boxShadow: "none" }}
        sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${600}px` },
          // display: { sm: "none" },
        }}
      >
        <Toolbar>
          <IconButton
            color="default"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              padding: "0px",
            }}
            variant="h6"
            noWrap
            component="header"
          >
            <span style={{ fontWeight: "700" }}>{title}</span>
            <div
              style={{
                display: "flex",
                padding: "0px",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img width={18} height={18} src="/alarm.svg" alt="" />
              </p>
              <p
                style={{
                  width: "40px",
                  height: "40px",
                  borderRadius: "50%",
                  background: "#000000",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <BsPerson style={{ color: "white" }} />
              </p>
              <p
                style={{ fontSize: "14px", fontWeight: "500" }}
              >{user?.firstName ? `${user?.firstName} ${user?.lastName}` : `${user?.businessName}`}</p>
              <div className="dropdown" onClick={handleDropDown}>
                <img width={10} height={10} src="/dropdown.svg" alt="" />
              </div>
              {dropdown && (
                <div className="dropdown-content">
                  <p className="dropdown-content-profile">
                    <Link to={"/profile"}>My Profile</Link>
                  </p>
                  <p
                    onClick={() => {
                      authService.logout();
                      document.location.reload();
                    }}
                  >
                    Logout
                  </p>
                </div>
              )}
            </div>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          margin: "0px",
          padding: "0px",
          width: { md: `calc(100% - ${drawerWidth}px)` },
        }}
      >
        <Toolbar
          sx={{
            display: { xs: "block", sm: "none" },
          }}
        />
        {content}
      </Box>
    </Box>
  );
}

const ListItems = styled(Link)<{ pathname: string }>`
  color: #000000;
  height: 30px;
  margin: 10px 0;
  display: flex;
  gap: 0px;
  align-items: center;
  /* padding-left: 40px; */
  cursor: pointer;
  border-right: 3px solid
    ${(props) =>
      props.to === props.pathname ? `${colours.primary}` : "#ffffff"};
  /* background-color: ${(props) =>
    props.to === props.pathname ? `${colours.background}` : `#ffffff`}; */
`;

const SwitchWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin:0px auto;
  background: ${colours.background};

  p {
    color: #000000;
    font-weight: 600;
  }
`;

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80%;
  margin: 20px auto;
  gap: 10px;
  p {
    font-weight: 600;
  }
  img {
  }
`;
