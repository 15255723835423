import { useEffect, useRef } from 'react';
function usePageVisibility() {
  const leaveTimeRef = useRef(null);
  const handleVisibilityChange = () => {
    if (document.hidden) {
      // The page is not visible, user has left
      leaveTimeRef.current = new Date();
    } else {
      // The page is visible, user has returned
      const returnTime = new Date();
      const awayTime = returnTime - leaveTimeRef.current;

      // If the user was away for more than 5 minutes
      if (awayTime > 24 * 60 * 60 * 1000) {
        // Refresh the page
        window.location.reload();
        // Or prompt the user to refresh
        // if (window.confirm('You have been away for a while. Would you like to refresh the page?')) {
        //   window.location.reload();
        // }
      }
    }
  };
  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);
}
export default usePageVisibility