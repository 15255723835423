import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useState, ReactNode } from "react";
import styled from "styled-components";
import colours from "../styles/appColours";

type Props = {
  deliveryinfo: ReactNode;
  deletes?: ReactNode;
  title: string;
};
export default function ControlledAccordions({
  title,
  deletes,
  deliveryinfo,
}: Props) {
  const [expanded, setExpanded] = useState<any>(true);

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  const handleClick = () => {
    setExpanded(!expanded)
  }

  return (
    <div>
      <Accordion
        defaultExpanded={true}
        expanded={expanded}
        // onChange={handleChange("panel1")}
        sx={{
          backgroundColor: colours.white,
          border: "none",
          boxShadow: "none",
        }}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          onClick={handleClick}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
          sx={{
            width: "100%",
            backgroundColor:'#F0F0F0',
            color: colours.black,
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Typography sx={{ width: "95%", flexShrink: 0,textAlign:"left", fontWeight: '700' }}>{title}</Typography>
          <Typography sx={{ color: "text.secondary" }}>{deletes}</Typography>
        </AccordionSummary>
        <AccordionNewDetails>
          {/* <Typography>{deliveryinfo}</Typography> */}
          {deliveryinfo}
        </AccordionNewDetails>
      </Accordion>
    </div>
  );
}

const AccordionNewDetails = styled(AccordionDetails)`
  background-color: ${colours.white};
  @media (max-width: 425px) {
    background-color: ${colours.white};
  }
`;
