import styled from "styled-components";
import { Link } from "react-router-dom";
import {useQuery} from "react-query"
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import api from "../services/api";
import utils from "../utils/utils";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import BasicModal from "./Modal";

const Servicesarray = [
  {
    img: "/sendpackage.png",
    text: "Shipping within Lagos",
    route: "/placeorder",
    background: "#EAEFFF",
  },
  {
    img: "/sendinterstate.png",
    text: "Interstate Shipping",
    route: "/interstate",
    background: "#E1F8F9",
  },
  {
    img: "/sendinternational.png",
    text: "International Shipping",
    route: "/international",
    background: "#FFEBEC",
  },
];

const Homescreen = () => {
  const env = useSelector(
    (state: RootState) => state.env.env
  );
  const apiRequest =  api()
  const navigate = useNavigate();
  const [orderid, setOrderId] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState(false)
  const { switchOrderStatus, getTime } = utils;
  const NavigateOrder = (e: any) => {
    e.preventDefault();
    if (orderid.length < 8) {
      setError("Not a valid order id");
      return;
    }
    navigate(`/order/${orderid}`);
  };

  const { data: order } = useQuery("LATEST_ORDER",  () => apiRequest.getLatestOrder(env), {
    onError: () => toast.error("Error fetching order")
  });


  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
    ReactPixel.pageView() 
  }, []);


  const getPickupTime = (data: any) => {
    const pickup = data?.filter(
      (status: { status: string }) => status.status === "pickup"
    );
    if (pickup.length > 0) return getTime(pickup[0].time);
    return "";
  };

  return (
    <Wrapper>
      <Content>
        <Track onSubmit={NavigateOrder}>
          <h1>Track your order</h1>
          <p>
            Enter your Receipt number that was generated after you placed an
            order
          </p>
          <input
            placeholder="Order ID"
            type="text"
            onChange={(e) => {
              setError("");
              setOrderId(e.target.value);
            }}
          />
          <Error>{error}</Error>
        </Track>
        <Services>
          <h1>Our Services</h1>
          <div>
            {/* {Servicesarray.map((service, index) => {
              return(
              <Link key={service.route + index} to={service.route}>
                <p style={{ background: service.background }}>
                  <img src={service.img} alt={service.text} />
                  <span>{service.text}</span>
                </p>
              </Link>
              )})} */}
             <Link to={'/placeorder'}>
                <p style={{ background:  "#EAEFFF"}}>
                  <img src={"/sendpackage.png"} alt={"/sendpackage.png"} />
                  <span>Shipping within Lagos</span>
                </p>
              </Link>
              <Link to={'/interstate'}>
                <p style={{ background:  "#E1F8F9"}}>
                  <img src={"/sendinterstate.png"} alt={"/sendpackage.png"} />
                  <span>Interstate Shipping</span>
                </p>
                </Link>
                <p onClick={()=>setState(true)} style={{ background:  "#FFEBEC"}}>
                  <img src={"/sendinternational.png"} alt={"/sendpackage.png"} />
                  <span>International Shipping</span>
                </p>
          </div>
        </Services>
        <Current>
          <h1>Current Order</h1>
          {order?.length > 0 ? (
            <div className="current-order">
              <div className="top">
                <div className="top-left">
                  <span className="image-container">
                    <img src="/homescreenbox.svg" alt="" />
                  </span>
                  <div>
                    <p>
                      <strong>Box</strong>
                    </p>
                    <p>{order[0]?.orderId}</p>
                  </div>
                </div>
                <div>
                  <p>
                    Estimate:{" "}
                    <strong>
                      N{(order[0]?.estimatedCost / 100).toLocaleString()}
                    </strong>
                  </p>
                  <p>
                    Pickup Time:{" "}
                    {getPickupTime(order[0]?.order_status_history) ||
                      "Not picked up yet"}
                  </p>
                </div>
              </div>
              <div className="bottom">
                <div className="address-wrapper">
                  <p className="dir">From:</p>
                  <p className="address">
                    {order[0]?.pickUpDetails[0]?.fullAddress}
                  </p>
                </div>
                <div className="address-wrapper">
                  <p className="dir">To:</p>
                  <p className="address">
                    {order[0]?.deliveryDetails[0]?.fullAddress}
                  </p>
                </div>
              </div>
              <div className="button-div">
                <button className="status">
                  {switchOrderStatus(order[0]?.orderStatus)}
                </button>
              </div>
            </div>
          ) : (
            "No orders placed"
          )}
        </Current>
      </Content>
      <BasicModal close={()=>setState(false)} open={state} content={
        <div style={{display:'flex', height: '100%',padding: '50px', alignItems:'center', flexDirection:'column', justifyContent: 'space-between'}}>
          <img width={100} height={100} src="/logo2.svg" alt="" />
          <p style={{ fontSize: '16px',fontWeight:'600'}}>Coming Soon</p>
          <button style={{width: '90%', border:'none', background:'#000000', color:'#ffffff', padding: '15px 0px', fontSize: '16px',fontWeight:'600', borderRadius:'4px'}} onClick={()=>setState(false)}>Ok</button>
        </div>
      } width={400} height={300}/>
    </Wrapper>
  );
};

export default Homescreen;

const Wrapper = styled.main`
  padding: 0;
  background-color: #f5f6f6;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 600px) {
    background: #ffffff;
  }
`;

const Content = styled.section`
  background: #ffffff;
  border-radius: 10px;
  width: 60%;
  margin: auto;
  /* height: 88%; */
  margin-top: 80px;
  padding: 30px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const Track = styled.form`
  background: #1c1d21;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 30px;

  > h1 {
    color: #ffffff;
  }
  > p {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: #ffffff;
    margin-top: 5px;
    text-align: left;
  }

  > input {
    width: 100%;
    background: #ffffff;
    height: 48px;
    border: 1px solid #545454;
    border-radius: 10px;
    margin-top: 30px;
    padding-left: 20px;
    outline: none;
  }
`;

const Current = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;
  > h1 {
    font-size: 16px;
    line-height: 21px;

    /* Gray 1 */

    color: #333333;
    margin-bottom: 20px;
  }

  .current-order {
    border: 1px solid #e0e0e0;
    border-radius: 6px;
    width: 100%;
    padding: 20px;

 
  }
  .top {
    padding: 10px 20px;
    border-bottom: 1px solid #e0e0e0;
    display: flex;
    justify-content: space-between;
    text-align: left;

    @media screen and (max-width: 600px) {
      flex-direction: column-reverse;
  }
  }
  .bottom {
    padding: 10px 20px;
    /* border-left: 1px solid #e0e0e0; */
    margin-top:10px;
  }

  .top-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .image-container {
    background: #1c1d21;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  .button-div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 10px 20px;

  }
  .status {
    /* display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center; */
    padding: 10px 20px;
    background: #eaeae9;
    border-radius: 5px;
    border: none;
    outline: none;
    font-weight: 600;
  }
  .address {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    /* Gray 1 */

    color: #333333;
    text-align: left;
  }
  .dir {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    /* identical to box height */

    /* Gray 3 */

    color: #828282;
  }
  .address-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;
  }
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 30px;

  h1 {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    text-align: center;
    color: #333333;
    margin-bottom: 30px;
    padding-left: 5px;
  }
  > div {
    width: 100%;
    p {
      width: 100%;
      background: #eaefff;
      border-radius: 10px;
      text-align: left;
      padding: 12px 20px;
      margin-bottom: 10px;
      display: flex;
      align-items: center;
      gap: 20px;
      cursor: pointer;

      > span {
        font-weight: 500;
        font-size: 16px;
        line-height: 21px;
        text-align: center;
        color: #333333;
      }
    }
  }
`;

const Error = styled.span`
  color: red;
`;


