import CheckoutContent from "../components/CheckoutContent";
import ResponsiveDrawer from "../components/Sidebar";

const Checkout = () => {
  return (
    <ResponsiveDrawer title="Complete Order &#x1F600;" content={<CheckoutContent />} />
  );
};

export default Checkout;
