import { Helmet } from "react-helmet";
import Homescreen from "../components/Homescreen";
import ResponsiveDrawer from "../components/Sidebar";

export default function Home() {
  return (
    <>
      <Helmet>
        <title>eFTD</title>
        <meta
          name="description"
          content="Official website of eFTD logistics, An Eco-friendly logistics company in Nigeria,Fast and reliable delivery service in Lagos, using Zero emission eletric two-wheeler. eFTD also has an EV Charging station in Nigeria. "
        />
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
      </Helmet>
      <ResponsiveDrawer title="Welcome &#x1F600;" content={<Homescreen />} />
    </>
  );
}
