import axios from "axios";

const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL;
console.log(process.env.NODE_ENV)

const tokenName = "token"
const refreshToken = 'refreshToken'

const register = async (userData: any) => {
  const response = await axios.post(baseUrl + "api/auth/register", userData);

  if (response.data) {
    localStorage.setItem(tokenName, response.data.token);
  }

  return response.data;
};


const login = async (userData: any, header: any) => {
  const config = {
    headers: {
      "Content-Type": "application/json",
      ...header
    },
  };
  const response = await axios.post(
    baseUrl + "api/auth/login",
    userData,
    config
  );
  localStorage.setItem("token", response?.headers?.token);
  localStorage.setItem(refreshToken, response?.headers?.refreshtoken);
  return response.data;
};

const logout = () => {
  localStorage.removeItem(tokenName);
  localStorage.removeItem("user")
};

const authService = {
  register,
  login,
  logout,
};

export default authService;
