import { createSlice } from "@reduxjs/toolkit";


export interface Location {
    pickupAddress : any | null

}

const initialState: Location = {
  pickupAddress: {},
};


export const LocationSlice = createSlice({
    name: "location",
    initialState,
    reducers: {
        getPickupLocation: (state, action) => {
            state.pickupAddress = action.payload
        }
    }
})


export const { getPickupLocation } = LocationSlice.actions;
export default LocationSlice.reducer;
