import React, { useRef, useEffect } from 'react'
import LandingFooter from '../footer/Footer';
import Header from '../header/Header';
import { motion, useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import ReactPixel from 'react-facebook-pixel';
import useOnScreen from '../../hooks/UseOnScreen';
import './about.css'
import styled from 'styled-components';

const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

const About = () => {
  const aboutRef:any = useRef();
  const mountRef:any = useRef()
  const onScreen = useOnScreen(aboutRef);
  const mount = useOnScreen(mountRef)
   const controls = useAnimation();
   const control = useAnimation();
   useEffect(() => {
     ReactGA.pageview(window.location.pathname);
     ReactPixel.pageView() 
   }, []);
    useEffect(() => {
      if (onScreen) {
        controls.start({
          x: 0,
          opacity: 1,
          transition: {
            duration: 0.5,
            ease: "easeOut",
          },
        });
      }
    
    }, [onScreen, controls]);
      useEffect(() => {
        if (mount) {
          control.start({
            x: 0,
            opacity: 1,
            transition: {
              duration: 0.9,
              ease: "easeOut",
            },
          });
        }
      }, [mount, control]);
  return (
    <main className="main-body">
      <Helmet>
        <title>About Us - Eco-friendly logistics company in Africa</title>
        <meta
          name="description"
          content="eFTD is the best parcel and Meal delivery service in Lagos. We are also one of a few Vehicle financing companies in Nigeria"
        />
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
      </Helmet>
      <Header navlist={placeorderNavList} />
      <section className="page-opener">
        <p className='about-us-text'>About us</p>
      </section>
      <div className="main-about">
        <motion.div
          initial={{ opacity: 0, y: 100 }}
          animate={{
            opacity: 1,
            y: 0,
            transition: { duration: 0.7, ease: "easeIn" },
          }}
        >
          <h1 className="sub-header">
            Leading eco-friendly logistics company in Africa
          </h1>
          <p className="first-text more">
            We are a team of result-oriented logistics experts who have served
            in the industry for nearly a decade. Within this period, we
            supervised high delivery rates, reliability and excellent customer
            service to individuals and business owners. We pride ourselves as
            the number one tech-powered, sustainable logistics company in
            Africa. With the aid of modern and innovative technology, eFTD
            specializes in running speedy local, interstate and international
            delivery service as well as fleet management. In addition to this,
            we empower riders with electric vehicle financing, EV battery
            charging and swapping services. As we spread our tents across
            Africa, we strategically position ourselves close to you to make
            sure we are always available at your service.
          </p>
          <Storywrapper>
            <div className="center">
              <img loading='lazy' height={'100%'} width={'100%'} src="/teamall.jpeg" alt="" />
            </div>
          </Storywrapper>
        </motion.div>
        <Numberswrapper>
          <OurNumbers>
            <div className="boxwrapper">
              <div className="box">
                <h4>1M+</h4>
                <p>Items Delivered</p>
              </div>
              <div>
                <div className="box">
                  <h4>125</h4>
                  <p>Countries covered</p>
                </div>
                <div className="box">
                  <h4>5000+ </h4>
                  <p>Swaps</p>
                </div>
              </div>
            </div>
            <div className="content">
              <h1>They Say Numbers Don’t Lie….</h1>
              <p>
               We have delivered a substantial measure of items for some of the world's largest retailers, saving them time, energy, and money.
              </p>
            </div>
          </OurNumbers>
        </Numberswrapper>
        <Officewrapper>
          <Ouroffice>
            <h1>Visit any of our offices around you</h1>
            <div className="cardwrapper">
              <div className="card">
                <img loading='lazy' src="/ikejaoffice.jpeg" alt="" />
                <h1>Ikeja, Lagos.</h1>
                <p>42 Local Airport Road Ikeja, Lagos.</p>
                <p>Email : info@eftdafrica.com</p>
              </div>
              <div className="card">
                <img loading='lazy' src="/service.jpeg" alt="" />
                <h1>Surulere, Lagos.</h1>
                <p>1, Bassie Ogamba Str, Off Adeniran Ogunsanya</p>
                <p>Email : info@eftdafrica.com</p>
              </div>
            </div>
          </Ouroffice>

        </Officewrapper>
        <Missionwrapper>
        <div className="mission">
          <div className="mission-image">
            <img loading='lazy' style={{objectFit:'cover', borderRadius: '10px'}} className="main-image" width={"100%"} height={"100%"} src="/individual.png" alt="" />
          </div>
          <div className="mission-info">
            <h1 className="sub-header">Our Mission</h1>
            <p className="first-text">
            To enable sustainable transportation and logistics services through electric mobility solutions.
            </p>
            <h1 className="sub-header">Our Vision</h1>
            <p className="first-text">
            To create a sustainable ecosystem for e-mobility services in Africa.
            </p>
          </div>
        </div>
        </Missionwrapper>
      </div>
      <LandingFooter />
    </main>
  );
}

export default About;

const Storywrapper = styled.section`
  max-width: 1440px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 50px auto 100px;
  gap: 1%;
  > div {
    width: 25%;
    > img {
      width: 100%;
      height: 100%;
    }
  }
  .center {
    width: 100%;
    height: 100%;
    > img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  @media screen and (max-width: 991px) {
    height: 320px;
  }
  @media screen and (max-width: 600px) {
    margin-top: 40px;
    flex-direction: column;
    padding: 20px;
    height: 100%;
    > div {
      width: 100%;
      > img {
        width: 100%;
      }
    }
    .center {
      width: 100%;
    
    }
  }
`;

const Numberswrapper = styled.section`
  padding-top: 110px;
  padding-bottom: 110px;
  background-color: #1c1d21;
  width: 100%;
  height: 500px;
  margin-bottom: 120px;
  @media screen and (max-width: 1024px) {
    height: 100%;
  }
  @media screen and (max-width: 600px) {
   height: 100%;
   padding: 20px;
  }
`;

const Officewrapper = styled.section`
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;
  @media screen and (max-width: 600px) {
   padding: 20px;
  }
`
const Missionwrapper = styled.section`
  padding-top: 110px;
  padding-bottom: 110px;
  width: 100%;
  background: #E1F8F9;
  @media screen and (max-width: 1024px) {
   padding: 20px;
  }
`
const Ouroffice = styled.div`
  max-width: 1440px;
  margin: 0px auto;
  padding: 20px;
  > h1 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 60px;
    text-align: left;
    width: 55%;
    padding-left: 30px;
    border-left: 5px solid #31eaf4;
    font-weight: 600;
  }
  .cardwrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 30px;
  }
  .card {
    text-align: left;
    width: 50%;
    img {
      height: 600px;
      aspect-ratio: 16/9;
      object-fit: cover;
      width: 100%;
      border-radius: 10px;
    }
    h1 {
      margin-top: 0px;
    margin-bottom: 20px;
    color: #001c34;
    font-size: 24px;
    line-height: 34px;
    font-weight: 600;
    }
    p {
      margin-bottom: 15px;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 600px) {
    > h1 {
      width: 100%;
      font-size: 24px;
      line-height: 32px;
    }
    .cardwrapper {
      flex-direction: column;
    }
    .card {
      width: 100%;
      > img {
        height: 300px;
      }
    }
  }
`

const OurNumbers = styled.div`
  max-width: 1440px;
  margin: auto;
  color: #ffffff;
  display: flex;
  justify-content: center;
  gap: 150px;
  .boxwrapper {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  .content {
    text-align: left;
    h1 {
      /* max-width: 550px; */
    margin-top: 0px;
    margin-bottom: 25px;
    color: #fff;
    font-size: 48px;
    line-height: 58px;
    font-weight: 600;
    }
    p {
      margin-bottom: 0px;
    color: #fff;
    font-size: 20px;
    line-height: 30px;
    max-width: 500px;
    }
  }
  .box {
    display: flex;
    flex-direction: column;
    width: 265px;
    height: 270px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff;
    box-shadow: 1px 1px 47px 0 hsl(0deg 0% 51% / 14%);
    margin-bottom: 30px;

    h4 {
      color: #F66D00;
    font-size: 60px;
    line-height: 70px;
    }

    p {
      color: #4b4b4b;
    }
  }
  @media screen and (max-width: 1024px) {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;

  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 40px;
    padding: 40px 0px;
    .boxwrapper {
      flex-direction: column;
      gap: 0px;
    }
    .box {
      margin-bottom: 0px;
      width: 100%;
    }
    .content {
     
      h1 {
        font-size: 24px;
        line-height: 28px;
      }
      p {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

`;
