import React from "react";
import { Link } from "react-router-dom";
import { ClientJS } from 'clientjs';
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../components/Button";
import routes from "../routes/routes";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";
import authService from "../redux/auth/authService";
import { getUserInfo } from "../redux/auth/auth";
import Loader from "../components/Loader";
import colours from "../styles/appColours";
import Spinner from "../components/Spinner";

function Login() {
  const client = new ClientJS();
  const fingerprint = client.getFingerprint();
  const browser = client.getBrowser();
  const OS =  client.getOS();
  const gettoken = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [showName, setShowName] = useState({
    email: false,
    password: false,
  });
  let token: any = null;
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      const result = await authService.login(
        {
          password: formData?.password,
          email: formData?.email.trim(),
        },
        { deviceId: fingerprint, deviceName: `${browser}/${OS}` }
      );
      localStorage.setItem("user", JSON.stringify(result));
      dispatch(getUserInfo(result?.result));
      navigate("/home");
    } catch (err: any) {
      toast.error(
        err?.response?.data?.error?.message || "Invalid Credentials"
      );
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (gettoken) {
      navigate("/home");
    }
  }, [gettoken]);

  useEffect(() => {
    if (formData?.email === "") {
      setShowName((prev) => {
        return { ...prev, email: false };
      });
    }
    if (formData?.password === "") {
      setShowName((prev) => {
        return { ...prev, password: false };
      });
    }
  }, [formData.email, formData.password]);
  if (gettoken) {
    return <Loader />;
  }
  return (
    <Wrapper onSubmit={handleSubmit}>
      <Link to="/">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
            gap: "10px",
          }}
        >
          <img width={50} height={50} src="/logo2.svg" alt="" />

          <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
            eFTD Africa
          </p>
        </div>
      </Link>

      <Top>
        <Title>Log in to your account</Title>
        <Form>
          <div style={{ textAlign: "left" }}>
            {showName?.email && <label>Email</label>}
            <InputWrapper>
              <Input
                type="text"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setShowName((prev) => {
                    return { ...prev, email: true };
                  });
                }}
                required
                placeholder="Enter your Email Address"
                // pattern="[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[A-Za-z]{2,}$"
              />
            </InputWrapper>
          </div>

          <div style={{ textAlign: "left" }}>
            {showName?.password && <label>Password</label>}
            <InputWrapper>
              <Input
                type={passwordShown ? "text" : "password"}
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  setShowName((prev) => {
                    return { ...prev, password: true };
                  });
                }}
                placeholder="Password"
                required
              />
              <EyeIcon onClick={handleToggle}>
                {!passwordShown ? <BsEyeFill /> : <BsEyeSlash />}
              </EyeIcon>
            </InputWrapper>
          </div>
        </Form>
        <ForgotPassword>
          <Link to={"/forgotpassword"}>Forgot Password</Link>
        </ForgotPassword>
        <Button
          style={{ color: "#ffffff", width: "100%", backgroundColor: "#000" }}
          text={isLoading ? <Spinner /> : "Login to your account"}
        ></Button>
      </Top>
      <CreateAccount>
        New to eFTD?{" "}
        <Link to={routes.signup}>
          <Links>Sign up</Links>
        </Link>
      </CreateAccount>
      {/* <AuthFooter /> */}
    </Wrapper>
  );
}

export default Login;

//styles
const Wrapper = styled.form`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
  background-color: #ffffff;
  label {
    color: #bdbdbd;
  }
`;

export const TermsCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
`;

export const Icon = styled.p`
  width: 40vw;
  text-align: left;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const ForgotPassword = styled.p`
  color: #f66d00;
  font-size: 18px;
  font-style: normal;
  font-weight: 900;
  line-height: 21px;
  letter-spacing: 0em;
  width: 100%;
  text-align: right;
`;
export const Links = styled.span`
  color: ${colours.primary};
`;

const CreateAccount = styled.div`
  width: 100%;
`;

export const Top = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Bottom = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
`;
