import styled from "styled-components";
import colours from "../styles/appColours";
import axios from "axios";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import utils from "../utils/utils";

const statusObject = {
  processing: 1,
  pickup: 2,
  'in-transit': 3,
  delivered: 4
}
const getNumber = (status:string):number => {
  return statusObject[status as unknown as keyof typeof statusObject];
} 

const OrderScreen = () => {
  const { switchStatusText, getDate, getTime } = utils;
    const {orderId} = useParams()
    const [order, setOrder] = useState([])
    const [trackingDetails, setTrackingDetails] = useState<any>([]);
    const token = localStorage.getItem("token");
    const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
     useEffect(() => {
       axios
       .get(`${baseUrl}api/order/track/${orderId}`, config)
         .then((response) =>{
           setTrackingDetails(response?.data?.data)
     })
         .catch((err) => console.log(err));
     }, [orderId]);


  return (
    <OrderscreenWrapper>
      <Bottom>
      {trackingDetails?.orderType === 'domestic' && (
        <OrderDetails>
          <h1>ORDER #{orderId}</h1>
          {trackingDetails?.order_status_history.length > 0 ? (
            trackingDetails?.order_status_history?.map((details: any, index: any) => (
              <div>
                <div
                  style={{
                    backgroundColor: "#CEF8CD",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/check4.svg" alt="" />
                </div>

                <div>
                  <h2>{switchStatusText(details?.status)}</h2>
                  <p>{`${getDate(details?.time)} ${getTime(details?.time)}`}</p>
                </div>
              </div>
            ))
          ) : (
            <p>No order</p>
          )}
        </OrderDetails>
      )}
      {trackingDetails?.orderType === 'cross-border' && <OrderDetails>
        <h1>ORDER #{orderId}</h1>
        {trackingDetails?.order_status_history.length < 1 && trackingDetails?.shipmentEvents.length < 1 && (<p> There is no order</p>) }
        {trackingDetails?.order_status_history.length > 0 && (
            trackingDetails?.order_status_history?.map((details: any, index: any) => (
              <div>
                <div
                  style={{
                    backgroundColor: "#CEF8CD",
                    borderRadius: "50%",
                    width: "30px",
                    height: "30px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <img src="/check4.svg" alt="" />
                </div>

                <div>
                  <h2>{switchStatusText(details?.status)}</h2>
                  <p>{`${getDate(details?.time)} ${getTime(details?.time)}`}</p>
                </div>
              </div>
            ))
          )}
        {trackingDetails?.shipmentEvents.length > 0 && (trackingDetails?.shipmentEvents?.map((details: any) => (
          <div>
            <p>{`${getDate(details?.timestamp)} ${getTime(
              details?.timestamp
            )}`}</p>
            <div
              style={{
                backgroundColor: "#CEF8CD",
                borderRadius: "50%",
                width: "30px",
                height: "30px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img src="/check4.svg" alt="" />
            </div>

            <div>
              <h2>{details?.description}</h2>
              <h3>{details?.location?.address?.addressLocality}</h3>
            </div>
          </div> 
        )))}
      </OrderDetails>
     }
      </Bottom>
    </OrderscreenWrapper>
  );
};

export default OrderScreen;

const OrderscreenWrapper = styled.main`
  height: 100%;
  min-height: 100vh;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center; */
  padding-top: 30px;
  background-color: ${colours.background};

  @media (max-width: 425px) {
    /* height: 93.3vh; */
    /* gap: 50px; */
  }
  @media (max-width: 375px) {
    /* height: 91vh; */
    gap: 30px;
  }
`;


const Bottom = styled.div`
  /* height: 40vh; */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background-color: ${colours.white};
  margin-top: 40px;
  @media screen and (max-width: 425px) {
    background-color: ${colours.background};
  }
`;



const OrderDetails = styled.section`
  background: #ffffff;
  border-radius: 10px;
  width: 60%;
  margin-bottom: 100px;
  text-align: left;
  padding: 20px 40px;


  > div {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 30px;
    margin-top: 50px;
    font-family: "Red Hat Display";
    font-style: normal;
    line-height: 21px;
    font-size: 14px;
 

    h2 {
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }

    p {
      font-weight: 400;
      color: #828282;
    }
  }

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 30px;
  }
`;