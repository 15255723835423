import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import styled from "styled-components";
import colours from "../styles/appColours";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "16px",
  //   border: "2px solid #000",
  //   boxShadow: 24,
  // p: 4,
};

export default function MapModal({
  icon,
  close,
  content,
  width,
  height,
}: any) {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <div onClick={handleOpen}>{icon}</div>
      <Modal
        open={open}
        onClose={close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Wrapper>
          <BackButton onClick={handleClose}>Done</BackButton>
          <Box sx={{ ...style, width: width, height: height }}>{content}</Box>
        </Wrapper>
      </Modal>
    </div>
  );
}


const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100vh;
  width: 100vw;
`


const BackButton = styled.button`
    position: absolute;
    z-index: 5;
    bottom: 20px;
    /* left: 45%; */
    height: 50px;
    width: 300px;
    font-size: 24px;
    background-color: ${colours.primary};
    color: ${colours.white};
    border:none;
    border-radius: 8px;
    /* padding: 20px 100px; */
`