import { createSlice } from "@reduxjs/toolkit";

//@ts-ignore
// const user: string | null = JSON.parse(localStorage.getItem("token"));


interface User {
  subscriptionPlan: string;
  subscriptionExpires: string;
  _id: string;
  firstName: string;
  lastName: string;
  businessName: string;
  accountType: string;
  email: string;
  phoneNumber: string;
  isVerified: boolean;
}

export interface InitialState {
  user: User | null;
  token: string | null;
}

const initialState: InitialState = {
  user: null,
  token: null
};

export const UserSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    getUserInfo: (state, action) => {
      state.user = action.payload;
    },
  },
});

export const { getUserInfo } = UserSlice.actions;
export default UserSlice.reducer;
