import React, { useRef, useEffect } from "react";
import styled from "styled-components";
import LandingFooter from "./footer/Footer";
import Header from "./header/Header";
import { useAnimation } from "framer-motion";
import { Helmet } from "react-helmet";
import ReactGA from "react-ga";
import useOnScreen from "../hooks/UseOnScreen";

const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

export const RiderTerms = () => {
  const aboutRef = useRef();
  const mountRef = useRef();
  const onScreen = useOnScreen(aboutRef);
  const mount = useOnScreen(mountRef);
  const controls = useAnimation();
  const control = useAnimation();
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  useEffect(() => {
    if (onScreen) {
      controls.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.5,
          ease: "easeOut",
        },
      });
    }
  }, [onScreen, controls]);
  useEffect(() => {
    if (mount) {
      control.start({
        x: 0,
        opacity: 1,
        transition: {
          duration: 0.9,
          ease: "easeOut",
        },
      });
    }
  }, [mount, control]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Container>
      <Helmet>
        <title>Eco-friendly logistics company in Africa</title>
        <meta
          name="keywords"
          content="Sustainability, What is the meaning of ICE?, What type of battery does an EV use?, EV battery capacity, Higher purchase dispatch bike,Greenhouse gases,Life cycle emission"
        />
      </Helmet>
      <Header navlist={placeorderNavList} />
      <Wrapper>
        <Headers>Riders Terms and Conditions</Headers>
        <Text>
          These Terms and Conditions (hereinafter referred to as "the
          Agreement") set forth the rights and obligations of the riders
          (hereinafter referred to as "the Riders") and eFTD Africa (hereinafter
          referred to as "the Company")
        </Text>
        <Headers>Allocation of Bikes </Headers>
        <Text>The Company shall assign a Bike to the Rider.</Text>
        <Text>
          The assigned Bike to the Rider shall at all times be the property of
          the company.
        </Text>
        <Headers>Rider's Responsibility:</Headers>

        <Text>
          The Rider shall be responsible for delivering packages and goods in a
          timely and professional manner.
        </Text>
        <Text>
          The Rider shall adhere to all applicable laws and regulations while on
          the road.
        </Text>
        <Text>
          The Rider shall maintain their mode of transportation, ensuring it is
          in a safe and roadworthy condition.
        </Text>
        <Text>
          The Rider shall at times while operating the Bike properly wear safety
          helmets and hairnets at all time.
        </Text>
        <Text>
          Under no circumstances shall the Rider operate the assigned Bike while
          under the influence of drugs or alcohol or when his physical or mental
          condition may be impaired
        </Text>
        <Text>
          The Rider shall not use the bike or permit the same to be used
          contrary to any Federal, State, or Local Government statute or
          regulation for the time being in force.
        </Text>
        <Text>
          All fees incurred due to parking and traffic violations shall solely
          be the responsibility of the Rider.
        </Text>
        <Text>
          While operating the Bike, the Rier shall have a valid Rider’s
          license/permit (Rider’s card) as required by the Law of Lagos State
          and Nigeria in his possession with proper endorsements where
          applicable.
        </Text>
        <Text>
          The Rider shall report all operational problems as soon as detected to
          the Company and also ensure that all guidelines and procedures issued
          by the Company for the Bike shall be strictly adhered.
        </Text>
        <Text>
          Any repairs on the Bike can only be carried out by the Company.
        </Text>

        <Text>
          The Rider shall be fully and solely liable for all cost of repairs
          which are as a result of the act, omission, or negligence of the Rider
          .
        </Text>
        <Text>
          The Rider shall not hold himself out as the owner of the Bike nor
          sell, assign, transfer, pledge, mortgage, let, underlet or otherwise
          deal with the motorcycle or any interest in it or in this agreement or
          create any lien upon the Bike for repairs or otherwise, or commit
          suffer any act of bankruptcy, or enter into any arrangement with
          creditors.
        </Text>
        <Text>
          The Rider shall not make any alteration to the bike without the
          consent of the Company. Any alteration, renewals, or additions shall
          become the property of the Company
        </Text>
        <Text>
          The Rider may without prejudice to his right to determine the
          agreement as set out in this agreement determine this agreement at any
          time by delivering up the motorcycle to the Company at the Company’s
          authorized address, but in such case, the Rider shall remain liable
          for any arrears payment for and any damage to the motorcycle.
        </Text>

        <Text>
          The Rider may be required to work on Saturdays, Sundays, and holidays.
          This will be discussed with the Rider and the requisite operational
          and payment terms will be confirmed prior.
        </Text>
        <Text>
          The Rider shall desist and not partake in any kind of civil
          disobedience, disturbance and/or protests. If found, the Rider will be
          immediately removed from the premises.
        </Text>
        <Headers>Contractual Relationship</Headers>
        <Text>
          The Agreement establishes an independent contractor relationship
          between the Company and the Rider. Nothing in this Agreement shall be
          considered as creating an employment relationship, agency,
          partnership, or joint venture between the parties.
        </Text>

        <h1>Acceptance and Rejection of Jobs:</h1>
        <Text>
          The Company shall allocate jobs to the Rider, who can accept or reject
          based on their availability.
        </Text>
        <Text>
          The Rider shall provide a reasonable explanation in case of job
          rejection.
        </Text>
        <Text>
          The Company may suspend or terminate the Agreement if the Rider
          consistently rejects an excessive number of jobs without a valid
          reason.
        </Text>
        <Headers>Payment:</Headers>
        <Text>
          The Company shall provide payment to the Rider based on the
          agreed-upon rates for completed deliveries.
        </Text>
        <Text>
          Payment shall be made by the Company on a weekly or monthly basis,
          subject to the Company&#39;s payment schedule.
        </Text>
        <Headers>Rider&#39;s Personal Data:</Headers>
        <Text>
          The Rider agrees to provide accurate and up-to-date personal
          information to the Company for identification and verification
          purposes.
        </Text>
        <Text>
          The Company shall handle the Rider&#39;s personal data in compliance
          with applicable data protection laws.
        </Text>
        <Headers>Insurance:</Headers>
        <Text>
          The Rider shall be responsible for obtaining appropriate insurance
          coverage for themselves, their mode of transportation, and any
          third-party liability arising during deliveries.
        </Text>
        <Text>
          The Company may provide insurance coverage for the Rider, subject to
          the terms and conditions of the insurance policy.
        </Text>
        <Headers>Guarantor</Headers>
        <Text>
          The Rider shall provide a guarantor who is acceptable to the Company
          and the guarantor shall be required to complete the Guarantor’s Form
          which shall stipulate the terms and conditions of the Guarantee.
        </Text>
        <Text>
          The guarantor shall be jointly and severally liable for any payment,
          damage,
        </Text>
        <Text></Text>
        <Headers>Company’s Responsibility</Headers>
        <Text>
          The Company will be responsible for regular routine maintenance of the
          Bike
        </Text>
        <Text>
          The company will provide the Rider with brandedwork gears such as
          helmet, vest etc . These items ahll remain the property of the Company
          which shall be handed over to the company on termination of these
          terms and conditions
        </Text>
        <Text>
          The Company will train the Rider on the operation of the Bike .
        </Text>
        <Headers>Termination:</Headers>
        <Text>
          The Agreement may be terminated by either party with written notice,
          specifying the reason for termination, and providing a reasonable
          notice period.
        </Text>
        <Text>
          The Company reserves the right to terminate the Agreement immediately,
          without notice, in case of a breach of the Agreement by the Rider.
        </Text>
        <Headers>Intellectual Property:</Headers>
        <Text>
          Any trademarks, trade names, logos, or other intellectual property of
          the Company shall remain the exclusive property of the Company. The
          Rider shall not use or reproduce such intellectual property without
          prior written consent.
        </Text>
        <Headers>Governing Law and Jurisdiction:</Headers>
        <Text>
          This Agreement shall be governed and construed in accordance with the
          laws of the Federal Republic of Nigeria.
        </Text>
        <Text>
          Any dispute arising between the parties shall be subject to the
          exclusive jurisdiction of the courts in Lagos State.
        </Text>
        <Headers>Entire Agreement:</Headers>
        <Text>
          This Agreement constitutes the entire understanding between the
          parties and supersedes any prior agreements, understandings, or
          representations, whether oral or written.
        </Text>
      </Wrapper>
      <LandingFooter />
    </Container>
  );
};

const Container = styled.main`
  background-color: F5F6F6;
`;

const Wrapper = styled.div`
  width: 90%;
  margin: 100px auto;
  padding: 20px;

  h1 {
    text-align: left;
    font-size: 18px;
    margin: 10px auto;
  }

  ul > li {
    /* list-style: none; */
    text-align: left;
    margin-bottom: 10px;
  }
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  margin-bottom: 20px;
`;

const Headers = styled.h1`
  color: #fe7f20;
  font-size: 24px;
  text-align: left;
`;
