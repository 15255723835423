import dayjs from "dayjs";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
// import countryList from '../Database/country.json'
// import stateList from '../Database/state.json';
// import cityList from "../Database/city.json"

dayjs.extend(LocalizedFormat);

export const getDate = (date: string) => dayjs(date).format("D-MM-YYYY");

export const getFormattedDate = (date: string) =>
  dayjs(date).locale("en").format("dddd, D [of] MMM. YYYY");

export const getTime = (date: string) => dayjs(date).format("LT");

const formatPhoneNumber = (str: string) => {
  // if (str.length < 11) {
  //   return "String must be at least 11 digits long";
  // }
  if (str.startsWith("0")) {
    return str.slice(1);
  }
  if (str.startsWith("234")) {
    return str.slice(3);
  }
  if (str.startsWith("+234")) {
    return str.slice(4);
  }
  return str;
};

const formatAmount = (value: number) => {
  const formattedNum = value.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  return formattedNum;
};

const formatDescription = (text: string[]): string[] => {
  const getstring = text[0].split("\n");
  return getstring;
};

// return inputValue.replace(new RegExp(/([0-9])$/ + "|.", "gi"), function (c) {
  //   return /^([0-9]|1[0-5])$/g.test(c) ? c : "";
  //   return /^(?:\d*\.\d{1,2}|\d+)$/.test(c) ? c : "";
  // });
const formatWeight = (inputValue: string) => {
 return  inputValue.replace(/[^0-9.]/g, "");
};

const AllowOnlyNumbers = (inputValue: string) => {
 return  inputValue.replace(/[^0-9]/g, "");
};

const formatText = (value: string) => {
  return value.replace(new RegExp(/([A-Za-z ])$/ + "|.", "gi"), function (c) {
    return /([A-Za-z ])$/g.test(c) ? c : "";
  });
};

const formatEmail = (value: string) => {
  return value.replace(new RegExp(/([A-Za-z ])$/ + "|.", "gi"), function (c) {
    return /([A-Za-z0-9._%+-@])$/g.test(c) ? c : "";
  });
};

const switchstatus = (status: string) => {
  switch (status) {
    case "delivered":
      return "#22A74C";
    case "processing":
      return "#E1F8F9";
    case "in-transit":
      return "#FFF5D7";
    case "pickup":
      return "#FFE8E8";
    case "cancelled":
      return "#FCCEAA";
    case "ordered":
      return "#EAEFFF";
    case "failed-after-payment":
      return "#EAEFFF";
    default:
      return;
  }
};

const paymenttype = (type: string) => {
  switch (type) {
    case "interstate":
      return "ORDER_INTERSTATE";
    case "international":
      return "ORDER_INTERNATIONAL";

    default:
      return "ORDER";
  }
};

const switchStatusText = (type: string) => {
  switch (type) {
    case "pickup":
      return "Rider picked up";
    case "processing":
      return "Order placed";
    case "in-transit":
      return "Package in Transit to Delivery Location";
    case "delivered":
      return "Package Delivered";
    case "cancelled":
      return "Cancelled";
    case "ordered":
      return "Order handed to third party logistics";
    case "failed-after-payment":
      return "Payment Successful";
    case "verified":
      return "Admin Verified";
  }
};

const switchOrderStatus = (type: string) => {
  switch (type) {
    case "pickup":
      return "Picked Up";
    case "processing":
      return "Processing";
    case "verified":
      return "Admin Verified";
    case "in-transit":
      return "In Transit";
    case "delivered":
      return "Delivered";
    case "cancelled":
      return "Cancelled";
    case "ordered":
      return "Payment Successful";
    case "failed-after-payment":
      return "Payment Successful";
  }
};
interface MyObject {
  timestamp: string;
  location: {
    address: {
      addressLocality: string;
    };
  };
  description: string;
}

function sortArrayByDays(arr: MyObject[]): any[] {
  // Create a new array to store the sorted objects
  let sortedArr: any[] = [];

  // Loop through the original array and group the objects by day
  let groups: { [day: string]: MyObject[] } = {};
  arr.forEach((obj) => {
    // Extract the day from the timestamp
    let day = obj.timestamp.slice(0, 10);
    // Add the object to the corresponding group
    if (groups[day]) {
      groups[day].push(obj);
    } else {
      groups[day] = [obj];
    }
  });

  // Loop through the groups and add them to the sorted array
  // Object.keys(groups).sort().forEach(day => {
  //   sortedArr.push(...groups[day]);
  // });

  return [groups];
}
const findEntryByCode = (source:any, code:any) => {
  if (code && source != null) {
      const codex = source.findIndex((c:any) => {
          return c.isoCode === code;
      });
      return codex !== -1 ? source[codex] : undefined;
  }
  return undefined;
};

export const findStateByCodeAndCountryCode = (source: any, code:any, countryCode:any) => {
  if (code && countryCode && source != null) {
      const codex = source.findIndex((c:any) => {
          return c.isoCode === code && c.countryCode === countryCode;
      });
      return codex !== -1 ? source[codex] : undefined;
  }
  return undefined;
};
export function defaultKeyToCompare(entity:any) {
  return entity.name;
}
export const compare = (a:any, b:any, 
// eslint-disable-next-line no-unused-vars
keyToCompare = defaultKeyToCompare) => {
  if (keyToCompare(a) < keyToCompare(b))
      return -1;
  if (keyToCompare(a) > keyToCompare(b))
      return 1;
  return 0;
};
export const convertArrayToObject = (keys:any, arr:any) => {
  const result = arr.map((subArr:any) => {
      return Object.fromEntries(keys.map((key:any, index:any) => [key, subArr[index]]));
  });
  return result;
};


// function getCountryByCode(isoCode:any) {
//   if (!isoCode)
//       return undefined;
//   return findEntryByCode(countryList, isoCode);
// }
// Get a list of all countries.
// function getAllCountries() {
//   return countryList;
// }
function sortByIsoCode(countries: any) {
  return countries.sort((a:any, b:any) => {
      return compare(a, b, (entity) => {
          return entity.isoCode;
      });
  });
}
// export function getAllStates() {
//   return stateList;
// }
// Get a list of states belonging to a specific country.
// export function getStatesOfCountry(countryCode = '') {
//   if (!countryCode)
//       return [];
//   // get data from file or cache
//   const states = stateList.filter((value) => {
//       return value.countryCode === countryCode;
//   });
//   return states.sort(compare);
// }
// export function getStateByCodeAndCountry(stateCode:any, countryCode:any) {
//   if (!stateCode)
//       return undefined;
//   if (!countryCode)
//       return undefined;
//   return findStateByCodeAndCountryCode(stateList, stateCode, countryCode);
// }
// to be deprecate
// export function getStateByCode(isoCode:any) {
//   // eslint-disable-next-line no-console
//   console.warn(`WARNING! 'getStateByCode' has been deprecated, please use the new 'getStateByCodeAndCountry' function instead!`);
//   if (!isoCode)
//       return undefined;
//   return findEntryByCode(stateList, isoCode);
// }
function sortStateByIsoCode(countries:any) {
  return countries.sort((a:any, b:any) => {
      return compare(a, b, (entity) => {
          return `${entity.countryCode}-${entity.isoCode}`;
      });
  });
}

const KEYS = [
  "name",
  "countryCode",
  "stateCode",
  "latitude",
  "longitude"
];
let convertedCityList:any = [];
// Get a list of all cities.
// function getAllCities(keys:any) {
//     if (convertedCityList.length) {
//         return convertedCityList;
//     }
//     const cityJSON = cityList;
//     convertedCityList = convertArrayToObject(keys, cityJSON);
//     return convertedCityList;
// }

// function getCitiesOfState(countryCode:any, stateCode:any) {
//   if (!stateCode)
//       return [];
//   if (!countryCode)
//       return [];
//   const cityList = getAllCities(KEYS);
//   const cities = cityList.filter((value:any) => {
//       return value.countryCode === countryCode && value.stateCode === stateCode;
//   });
//   return cities.sort(compare);
// }

function getBlogTitle(input:string){
  
  return input.split(' ').join('+')
}
function reverseBlogTitle(input:string){
  return input.split('+').join(' ')
}

const utils = {
  formatPhoneNumber,
  formatAmount,
  switchstatus,
  paymenttype,
  formatWeight,
  AllowOnlyNumbers,
  formatText,
  formatEmail,
  getDate,
  getTime,
  formatDescription,
  switchStatusText,
  sortArrayByDays,
  switchOrderStatus,
  sortByIsoCode,
  getBlogTitle,
  reverseBlogTitle,
};

export default utils;
