import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "5px",
  border: 'none',
  outline: 'none'
  //   border: "2px solid #000",
  //   boxShadow: 24,
  // p: 4,
};
interface ModalType {
  close: ()=> void;
  open: boolean;
  content: any;
  width: any;
  height: any;
}

export default function BasicModal({
  close,
  open,
  content,
  width,
  height,
}: ModalType) {

  return (
    <div>
      <Modal
        open={open}
        onClose={close}
        className="modal-background"
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style, width: width, height: height }}>
          {content}
        </Box>
      </Modal>
    </div>
  );
}
