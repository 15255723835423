interface Address{
    latlng: string
    state: string
    fullAddress: string
    id: number;
}

export const getLocation = (address: any, id: number): Address => {
  let latlng,
    state = "",
    fullAddress = "";
  latlng = Object.values(address[0]?.geometry?.location).join();
  const addressArray = address[0]?.address_components;

  for (let i = 0; i < addressArray.length; i++) {
    if (addressArray[i].types.includes("administrative_area_level_1")) {
      state = addressArray[i]?.long_name;
    }
  }
  fullAddress = address[0]?.formatted_address;
  return { latlng, state, fullAddress, id };
};

export const deliveryInfo = (arr1:any, arr2:any) => {
  const response = arr1.map((item: any, index: number) => {
   const value =  {...item, ...arr2[index]}
   const {id, ...result} = value
   return result
  })
  return response
}



const locationService = {
  getLocation,
  deliveryInfo,
};

export default locationService;



