const colours = {
  home: "#ebf0fc",
  primary: "#FE7F20",
  white: "#ffffff",
  black:"#000000",
  background: " #F5F6F6",
  secondary: "#E1F8F9",
  third: "#FCAF76",
};

export default colours;
