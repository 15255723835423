import OrderScreen from "../components/OrderScreen";
import ResponsiveDrawer from "../components/Sidebar";

const Order = () => {
  return (
    <ResponsiveDrawer title="Track your order" content={<OrderScreen />} />
  );
};

export default Order;
