import styled from "styled-components";
import axios from "axios";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import {useQuery} from "react-query"
import { Link } from "react-router-dom";
import colours from "../styles/appColours";
import Loader from "./Loader";
import utils from "../utils/utils";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import api from "../services/api";

const OrderHistory = () => {
  const apiRequest =  api()
  const [state, setState] = useState("pending");
  const env = useSelector(
    (state: RootState) => state.env.env
  );
  const { data: delivered, isLoading } = useQuery(["DELIVERED_ORDERS", env],  () => apiRequest.getOrderHistoryDelivered(env));
  const { data: orders } = useQuery(["PENDING_ORDERS", env],  () => apiRequest.getAllOrders(env), {
    onError: () => toast.error("Error fetching pending orders")
  });

  const PendingOrders = orders?.filter(
    (order: any) => order?.orderStatus !== "delivered"
  );

  if (isLoading) return <Loader />;

  return (
    <Wrapper>
      <ContentHeader>
        <Tab>
          <PendingTypes stage={state} onClick={() => setState("pending")}>
            Pending Orders
          </PendingTypes>
          <DeliveredTypes stage={state} onClick={() => setState("delivered")}>
            Completed Orders
          </DeliveredTypes>
        </Tab>

        <ListHeaderdiv>
          <ListHeader>Date Placed</ListHeader>
          <ListHeader>Order ID</ListHeader>
          <ListHeader style={{ textTransform: "capitalize", width: "15%" }}>
            Pickup Address
          </ListHeader>
          <ListHeader style={{ textTransform: "capitalize", width: "14%" }}>
            Delivery Address
          </ListHeader>
          <ListHeader>Phone Number</ListHeader>
          <ListHeader>Delivery Type</ListHeader>
          <ListHeader>Price</ListHeader>
          {state === "pending" && (
            <ListHeader style={{ textAlign: "center" }}>Status</ListHeader>
          )}
        </ListHeaderdiv>
      </ContentHeader>
      {state === "pending" && (
        <Content>
          {PendingOrders?.length > 0 ? (
            PendingOrders?.map((info: any) => (
              <Link key={info?.orderId} to={info?.orderId}>
                <Listdiv>
                  <ListItems
                  >
                    <span>Date Placed </span>
                    {`${utils.getDate(info?.createdAt)}` || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Order ID: </span>
                    {info?.orderId}
                  </ListItems>
                  <ListItems
                    style={{ textTransform: "capitalize", width: "14%" }}
                  >
                    <span>Pickup Address: </span>
                    {info?.pickUpDetails[0]?.fullAddress || "Not available"}
                  </ListItems>
                  <ListItems
                    style={{ textTransform: "capitalize", width: "14%" }}
                  >
                    <span>Delivery Address: </span>
                    {info?.deliveryDetails[0]?.fullAddress || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Delivery Phone-number: </span>
                    {info?.deliveryDetails[0]?.phoneNumber || "Not available"}
                  </ListItems>
                  <ListItems style={{ textTransform: "capitalize" }}>
                    <span>Delivery Type: </span>
                    {info?.deliveryType || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Price:</span>N
                    {utils.formatAmount(info?.estimatedCost / 100)}
                  </ListItems>
                  <ListItems
                    style={{
                      textTransform: "capitalize",
                      padding: "5px 0px",
                      textAlign: "center",
                      background: utils.switchstatus(info?.orderStatus),
                    }}
                  >
                    <span>Status:</span>
                    {utils.switchOrderStatus(info?.orderStatus)}
                  </ListItems>
                </Listdiv>
              </Link>
            ))
          ) : (
            <p>You dont have any orders yet</p>
          )}
        </Content>
      )}
      {state === "delivered" && (
        <Content>
          {delivered?.length > 0 ? (
            delivered?.map((info: any) => (
              <Link key={info?.orderId} to={info?.orderId}>
                <Listdiv>
                <ListItems
                  >
                    <span>Date Placed </span>
                    {`${utils.getDate(info?.createdAt)}` || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Order ID: </span>
                    {info?.orderId}
                  </ListItems>
                  <ListItems
                    style={{ textTransform: "capitalize", width: "15%" }}
                  >
                    <span>Pickup Address: </span>
                    {info?.pickUpDetails[0]?.fullAddress || "Not available"}
                  </ListItems>
                  <ListItems
                    style={{ textTransform: "capitalize", width: "15%" }}
                  >
                    <span>Delivery Address: </span>
                    {info?.deliveryDetails[0]?.fullAddress || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Delivery Phone-number: </span>
                    {info?.deliveryDetails[0]?.phoneNumber || "Not available"}
                  </ListItems>
                  <ListItems style={{ textTransform: "capitalize" }}>
                    <span>Delivery Type: </span>
                    {info?.deliveryType || "Not available"}
                  </ListItems>
                  <ListItems>
                    <span>Price:</span>N
                    {utils.formatAmount(info?.estimatedCost / 100)}
                  </ListItems>
                </Listdiv>
              </Link>
            ))
          ) : (
            <p>You dont have any orders yet</p>
          )}
        </Content>
      )}
    </Wrapper>
  );
};

export default OrderHistory;

const Wrapper = styled.main`
  min-height: 100vh;
  height: 100%;
  background-color: ${colours.background};
  /* display: flex; */
  width: 100%;
  /* justify-content: flex-start; */
  /* flex-direction: column; */
  @media (max-width: 600px) {
    width: 100vw;
  }
`;

const Content = styled.section`
  background: #ffffff;
  border-radius: 10px;
  width: 100%;
  max-width: 1040px;
  margin: auto;
  /* height: 88%; */
  padding: 30px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0px;
  }
`;
const ContentHeader = styled.section`
  border-radius: 10px;
  width: 100%;
  max-width: 1040px;
  margin: auto;
  padding: 30px;

  @media screen and (max-width: 600px) {
    width: 100%;
    margin-top: 0px;
  }
`;

const Listdiv = styled.div`
  display: flex;
  align-items: center;
  /* gap: 100px; */
  border-bottom: 1px solid #e0e0e0;
  width: 100%;
  justify-content: space-between;
  height: 40px;
  margin: 0 auto;
  gap: 2%;
  /* margin-top: 100px; */
  &:hover {
    height: 45px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  }
  @media (max-width: 600px) {
    width: 100%;
    margin: 0 auto;
    flex-direction: column;
    height: 100%;
    border: 1px solid #e0e0e0;
    margin-bottom: 10px;
  }
`;
const ListHeaderdiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 2%;
  justify-content: space-between;
  margin: 0 auto;
  @media (max-width: 600px) {
    width: 95%;
    margin: 0 auto;
    display: none;
  }
`;

const ListHeader = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  width: 12%;
  text-align: left;
`;

const ListItems = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #333333;
  width: 11%;
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  span {
    display: none;
  }
 
  @media (max-width: 600px) {
    width: 100% !important;
    height: 42px;
    padding: 10px !important;
    text-align: left !important;
    span {
      display: inline-block;
      font-weight: 700;
      color: #000;
      margin-right: 10px;
      /* width: 40%; */
    }
  }
`;

const Tab = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: auto;
  margin-top: 100px;
  margin-bottom: 30px;
  gap: 20px;
  border-bottom: 1px solid #e0e0e0;

  p {
    cursor: pointer;
  }
`;

const PendingTypes = styled.p<{ stage: string }>`
  border-bottom: 2px solid
    ${(props) => (props.stage === "pending" ? "#F66D00" : "#ffffff")};
  cursor: pointer;
`;

const DeliveredTypes = styled.p<{ stage: string }>`
  border-bottom: 2px solid
    ${(props) => (props.stage === "delivered" ? "#F66D00" : "#ffffff")};
  cursor: pointer;
`;
