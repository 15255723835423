

import Internationalscreen from "../components/International";
import ResponsiveDrawer from "../components/Sidebar";

const International = () => {
  return (
    <ResponsiveDrawer title="Home > International" content={<Internationalscreen/>}/>
  );
};

export default International;