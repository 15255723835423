import { createSlice } from "@reduxjs/toolkit";

export interface OrderInfo {
  order: any | null;
}

const initialState: OrderInfo = {
  order: {},
};

export const OrderSlice = createSlice({
  name: "order",
  initialState,
  reducers: {
    getOrder: (state, action) => {
      state.order = action.payload;
    },
  },
});

export const { getOrder } = OrderSlice.actions;
export default OrderSlice.reducer;
