import { useEffect, useState } from "react";
import axios from "axios";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";
import Button from "../components/Button";
import styled from "styled-components";
import ReactPixel from 'react-facebook-pixel';
import Terms from "./Terms";
import { Link } from "react-router-dom";
import BasicModal from "../components/Modal";
import colours from "../styles/appColours";
import { useNavigate } from "react-router-dom";
import Spinner from "../components/Spinner";
import { toast } from "react-toastify";
import utils from "../utils/utils";

const Signup = () => {
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const [passwordShown, setPasswordShown] = useState(false);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [openTerms, setOpenTerms] = useState(false);
  const [formData, setFormData] = useState({
    email: "",
    accountType: "business",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    businessName: "",
  });
  const [errormessage, setErrorMessage]=useState({
    email: "",
    accountType: "",
    firstName: "",
    lastName: "",
    password: "",
    phoneNumber: "",
    businessName: "",
  })
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const handleChange = (e: any) => {
    setError("");
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!formData.email.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$")) {
      setError("must be a valid email format");
      return;
    }
    if (!formData.password.match(/^(?=.*[0-9])(?=.*[!@#$%^&*()_\-+=~`[\]{}|\\:;"'<>,./?])(?=.*[a-zA-Z]).{8,}$/)) {
      setError("password must have atleast 8 character, a number and symbol");
      return;
    }
    if (formData.accountType === "individual") {
      if (formData.firstName.length < 2 || formData.lastName.length < 2) {
        setError("Name must have atleast 2 characters");
        return;
      }
    }
    if (formData.accountType === "business") {
      if (formData.businessName.length < 2) {
        setError("Name must have atleast 2 characters");
        return;
      }
    }
    if (formData.phoneNumber.length < 11) {
      setError("Please put in a valid phone number");
      return;
    }
    setIsLoading(true)
    axios
      .post(baseUrl + "api/auth/register", formData)
      .then((res: any) => {
        localStorage.setItem("eftd-register", JSON.stringify({token: res?.data?.data?.data?.token, email: formData.email }));
        navigate("/verify");
      })
      .catch((err) => {
        toast.error(err?.response?.data?.error?.message);
        setIsLoading(false)
      });
  };
  useEffect(() => {
    ReactPixel.pageView();
  }, []);
  return (
    <Wrapper onSubmit={handleSubmit}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginBottom: "30px",
          gap: "10px",
        }}
      >
        <img width={50} height={50} src="/logo2.svg" alt="" />
        <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
          eFTD Africa
        </p>
      </div>
      <SignupTop>
        <Title>Create an Account </Title>
        <SignupForm>
          {error ? <Error>{error}</Error> : ""}
          <p style={{ textAlign: "left", marginBottom: "20px" }}>
                Sign up as
              </p>
              <div
                style={{ display: "flex", flexDirection: "row", gap: "20px" }}
              >
                <input
                  onChange={handleChange}
                  type="radio"
                  name="accountType"
                  checked={formData.accountType === "business"}
                  id="business"
                  value="business"
                  // defaultValue='document'
                />
                <label htmlFor="business">Business</label>
                <input
                  onChange={handleChange}
                  type="radio"
                  name="accountType"
                  checked={formData.accountType === "individual"}
                  id="individual"
                  value="individual"
                />
                <label htmlFor="individual">Individual</label>
              </div>
          {/* <InputWrapper>
            <Input
              type="text"
              name="otp"
              onChange={handleChange}
              value={formData.accountType}
              placeholder="I Am"
            />
            <img
              onClick={() => setOpenModal(true)}
              src={"/arrowdown.png"}
              height={20}
              width={20}
              alt="arrow down"
            />
            <BasicModal
              height={"200px"}
              width={"400px"}
              open={openModal}
              close={() => setOpenModal(false)}
              content={
                <ModalContent>
                  <ModalIndividual onClick={handleIndividual}>
                    <img
                      src={"/profileicon.png"}
                      height={20}
                      width={20}
                      alt="individual"
                    />
                    <Text>Individual</Text>
                  </ModalIndividual>
                  <ModalIndividual onClick={handleBusiness}>
                    {" "}
                    <img
                      src={"/business.png"}
                      height={20}
                      width={20}
                      alt="business"
                    />
                    <Text>Business</Text>
                  </ModalIndividual>
                </ModalContent>
              }
            />
          </InputWrapper> */}
          {formData.accountType === "individual" && (
            <>
            <div>
              <InputWrapper>
                <Input
                  type="text"
                  name="firstName"
                  onChange={handleChange}
                  placeholder="First Name"
                  value={formData.firstName}
                  onBlur={(evt) => {
                    if (formData.accountType === "individual"){
                    if ((evt.target.value).trim().length < 2) {
                      setErrorMessage((prev)=>{
                        return {...prev, firstName: 'First Name must be atleast 2 characters'}
                      })
                    }else{
                      setErrorMessage((prev)=>{
                        return {...prev, firstName: ''}
                      })
                    }
                  }}
                }
                />
              </InputWrapper>
              {errormessage?.firstName && <Error>{errormessage?.firstName}</Error>}
              </div>
              <div>
              <InputWrapper>
                <Input
                  type="text"
                  name="lastName"
                  onChange={handleChange}
                  value={formData.lastName}
                  placeholder="Last Name"
                  onBlur={(evt) => {
                    if (formData.accountType === "individual"){
                    if ((evt.target.value).trim().length < 2) {
                      setErrorMessage((prev)=>{
                        return {...prev, lastName: 'Last Name must be at least 2 characters'}
                      })
                    }else{
                      setErrorMessage((prev)=>{
                        return {...prev, lastName: ''}
                      })
                    }
                  }}
                }
                />
              </InputWrapper>
              {errormessage?.lastName && <Error>{errormessage?.lastName}</Error>}
              </div>
            </>
          )}
          {formData.accountType === 'business' && (
            <div>
            <InputWrapper>
              <Input
                type="text"
                name="businessName"
                onChange={handleChange}
                placeholder="Business Name"
                value={formData.businessName}
                onBlur={(evt) => {
                  if (formData.accountType === "business"){
                  if ((evt.target.value).trim().length < 2) {
                    setErrorMessage((prev)=>{
                      return {...prev, businessName: 'Business Name must have atleast 2 characters'}
                    })
                  }else{
                    setErrorMessage((prev)=>{
                      return {...prev, businessName: ''}
                    })
                  }
                }}
              }
              />
            </InputWrapper>
            {errormessage?.businessName && <Error>{errormessage?.businessName}</Error>}
            </div>
          )}
          <div>
          <InputWrapper>
            <Input
              type="text"
              name="phoneNumber"
              onChange={handleChange}
              placeholder="Phone Number"
              value={utils.AllowOnlyNumbers(formData?.phoneNumber)}
              maxLength={11}
              onBlur={(evt) => {
                if ((evt.target.value).trim().length !== 11) {
                  setErrorMessage((prev)=>{
                    return {...prev, phoneNumber: 'Please put in a valid phone number'}
                  })
                }else{
                  setErrorMessage((prev)=>{
                    return {...prev, phoneNumber: ''}
                  })
                }
              }}
            />

          </InputWrapper>
          {errormessage?.phoneNumber && <Error>{errormessage?.phoneNumber}</Error>}
          </div>
          <div>
          <InputWrapper>
            <Input
              type="text"
              name="email"
              onChange={handleChange}
              value={formData.email}
              placeholder="Email"
              onBlur={(evt) => {
                if (!evt.target.value.match("[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,}$")) {
                  setErrorMessage((prev)=>{
                    return {...prev, email: 'Input must be a valid email format'}
                  })
                }else{
                  setErrorMessage((prev)=>{
                    return {...prev, email: ''}
                  })
                }
              }}
            />
          </InputWrapper>
          {errormessage?.email && <Error>{errormessage?.email}</Error>}
          </div>
          <div>
          <InputWrapper>
            <Input
              type={passwordShown ? "text" : "password"}
              name="password"
              value={formData.password}
              onChange={handleChange}
              placeholder="Password"
              onBlur={(evt) => {
               
                if (!evt.target.value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=[\]{}|:;"'<>,./?])(?!.*\s).{8,}$/)) {
                // if (!evt.target.value.match(/^(?=.*[0-9])(?=.*[!@#$%^&*()_\-+=~`[\]{}|\\:;"'<>,./?])(?=.*[a-zA-Z]).{8,}$/)) {
                  setErrorMessage((prev)=>{
                    return {...prev, password: 'Password must have atleast 8 character, have a symbol and number'}
                  })
                }else{
                  setErrorMessage((prev)=>{
                    return {...prev, password: ''}
                  })
                }
              }}
            />
            <EyeIcon onClick={handleToggle}>
              {!passwordShown ? <BsEyeFill /> : <BsEyeSlash/>}
            </EyeIcon>
          </InputWrapper>
          {errormessage?.password && <Error>{errormessage?.password}</Error>}
          </div>
        </SignupForm>
       
        <TermsCon>
          By Clicking the “Create your Account” button, you agree to eFTD’s
          <TermsLink onClick={() => setOpenTerms(true)}>
            &nbsp;Terms and Conditions
          </TermsLink>
        </TermsCon>
        <Button
          style={{ color: "#ffffff", width: "80%", backgroundColor: "#000" }}
          text={isLoading ? <Spinner /> : "Create my Account"}
        ></Button>
      </SignupTop>
      <SignupLogin>
        Already have an account?{" "}
        <Link to={"/login"}>
          <Links>&nbsp;Login</Links>
        </Link>
      </SignupLogin>
      <BasicModal
        open={openTerms}
        close={() => setOpenTerms(false)}
        height={'100%'}
        width={"80%"}
        content={
          <TermsWrap>
            <Terms/>
          </TermsWrap>
        }
      />
    </Wrapper>
  );
};

export default Signup;
//styles
const Wrapper = styled.form`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  @media (max-width: 600px) {
    background-color: #ffffff;
  }
`;

export const Title = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;
const TermsWrap = styled.main`
  height: 100vh;
  overflow: scroll;
`;
const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const Icon = styled.p`
  width: 40vw;
  text-align: left;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 425px) {
    width: 90vw;
  }
`;

export const TermsCon = styled.p`
  width: 70%;
  margin: auto;
  @media (max-width: 425px) {
    width: 90vw;
  }
`;

export const Links = styled.span`
  color: ${colours.primary}
`;

const TermsLink = styled.span`
  color: ${colours.primary};
  cursor: pointer;
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;
const Error = styled.p`
  color: red;
`;
export const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  &.error {
    border: 2px solid rgb(255, 86, 97);
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;
const SignupTop = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
    padding: 0px;
  }
`;

const SignupLogin = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
`;


