import { ReactNode } from "react";
import styled from "styled-components";

type PickupDetails = {
    senderName: string;
    fullAddress: string;
    state: string;
    code: string;
    phoneNumber: string;
}

type DeliveryDetails = {
    recepientName: string;
    fullAddress?: string;
    state: string;
    code: string;
    phoneNumber: string;
    cityName: string;
    postalCode?: string;
    countryCode: string;
    email: string;
}

type ShipmentDetails = {
  weight: string;
  height: string;
  width: string;
  length: string;
  monetaryAmount: string | number;

}

interface OrderDetails {
    onSubmit: (e:any) => void;
    pickupDetails: PickupDetails;
    deliveryDetails: DeliveryDetails;
    shipmentDetails: ShipmentDetails;
    buttonText: ReactNode;
    deliveryType?: string;
    shouldButtonShow: boolean
}

const Ordersummary = ({onSubmit, pickupDetails, deliveryDetails, shipmentDetails, buttonText, deliveryType, shouldButtonShow }: OrderDetails) => {
  return (
    <Wrapper>
      <Content>
        <Contentinfo>
          <h1>Pickup Details</h1>
          <p>{pickupDetails?.senderName}</p>
          <p>{pickupDetails?.fullAddress}</p>
          <p>{pickupDetails?.state}</p>
          <p>{pickupDetails?.code + pickupDetails?.phoneNumber}</p>
        </Contentinfo>
        <Contentinfo>
          <h1>Delivery Details</h1>
          <p>{deliveryDetails?.recepientName}</p>
          <p>{deliveryDetails?.fullAddress}</p>
          <p>{deliveryDetails?.cityName || ''}</p>
          <p>{deliveryDetails?.state}</p>
          <p>{deliveryDetails?.countryCode || ""}</p>
          <p>{deliveryDetails?.postalCode || ''}</p>
          <p>{deliveryDetails?.email || ''}</p>
          <p>{deliveryDetails?.code + deliveryDetails?.phoneNumber}</p>
        </Contentinfo>
       {deliveryType !== 'domestic' && <Contentinfo>
          <h1>Shipment Details</h1>
          <p>Monetary Value:{shipmentDetails?.monetaryAmount} </p>
          <h1>Package</h1>
          <p>Weight: {shipmentDetails?.weight}kg</p>
          <p>{`(${shipmentDetails?.length} * ${shipmentDetails?.width} * ${shipmentDetails?.height}cm)`}</p>
          {/* <p>Quantity: 1</p> */}
        </Contentinfo>
      }
      </Content>
      {shouldButtonShow &&
      <EstimateButton onClick={onSubmit}>{buttonText}</EstimateButton>
}
    </Wrapper>
  );
};

export default Ordersummary;

const Wrapper = styled.div`
background-color: #ffffff;
`;

const EstimateButton = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 18px;
  width: 70%;
  margin: 0 auto;
  height: 50px;
  color: #ffffff;
  background: #13131a;
  border-radius: 5px;
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900;
  font-size: 18px;
  line-height: 24px;
  border: none;
`;

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  margin-bottom: 30px;
  gap: 20px;
`;

const Contentinfo = styled.div`
text-align: left;
width: 45%;
margin-bottom: 20px;
  h1 {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #333333;
  }

  p {
    font-family: "Red Hat Display";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #4f4f4f;
  }
`;
