import React from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { useQuery, useQueryClient, useMutation } from "react-query";
import colours from "../styles/appColours";
import { BsPerson } from "react-icons/bs";
import Loader from "./Loader";
import CopyContent from "./Copyable";
import { getDate, getTime } from "../utils/utils";
import api from "../services/api";

function ProfileScreen() {
  const apiRequest = api();
  const queryClient = useQueryClient();
  const { data: user, isLoading: loading } = useQuery("USER_PROFILE", () =>
    apiRequest.getUserProfile()
  );

  const { data: generateKeys } = useQuery(
    "GENERATE_KEYS",
    () => apiRequest.getUserProfile(),
    {
      onSuccess: () => queryClient.invalidateQueries("USER_PROFILE"),
      onError: () => toast.error("There was an error generating Api keys"),
    }
  );

  const { data: sessions } = useQuery("USER_SESSION", () =>
    apiRequest.getUserSessions()
  );

  const deleteUserSession = useMutation((id:string) => apiRequest.deleteSession(id), {
    onSuccess: () => queryClient.invalidateQueries("USER_SESSION")
  });

  const handleDelete =  async (id:string) => {
    await deleteUserSession.mutate(id)
  }


  if (loading) return <Loader />;
  return (
    <Wrapper>
      <UserProfile>
        <UserImage>
          <BsPerson style={{ fontSize: "50px" }} />
        </UserImage>
        <UserName>{`${user?.firstName} ${user?.lastName}`}</UserName>
        <div
          style={{
            width: "80%",
            display: "flex",
            alignItems: "flex-start",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "5px",
            }}
          >
            <UserRole>
              <img height={18} src="/subtype.svg" alt="" />{" "}
              <span>{user?.subscription?.name}</span>
            </UserRole>
            <p>
              Expiry Date:{" "}
              <span>
                {user?.subscription
                  ? getDate(user?.subscription?.subscriptionEnd)
                  : "N/A"}
              </span>
            </p>
          </div>
          <UserRole>
            <img height={18} src="/ordersleft.svg" alt="" />
            <span>
              {user?.subscription?.noOfOrders || 0} deliveries remaining
            </span>
          </UserRole>
        </div>

        <UserDatas>
          <Title>Personal Details</Title>
          <label htmlFor="">Full Name</label>
          <UserInfo
            disabled={true}
            value={`${user?.firstName} ${user?.lastName}`}
          />
          <label htmlFor="">Business Name(Optional)</label>
          <UserInfo
            disabled={true}
            style={{ background: "#F2F2F2" }}
            value={user?.businessName}
          />
          <label htmlFor="">Email Address</label>
          <UserInfo
            disabled={true}
            style={{ background: "#F2F2F2" }}
            value={user?.email}
          />
          <label htmlFor="">Phone Number</label>
          <UserInfo disabled={true} value={user?.phoneNumber} />
        </UserDatas>

        {user?.apiKey ? (
          <UserDatas>
            <Title>Developer</Title>
            <p>API configuration Key</p>
            <label htmlFor="">Live Secret Key</label>
            <Copyable>
              <CopyContent content={user?.apiKey} />
            </Copyable>
            <label htmlFor="">Test Secret Key</label>
            <Copyable>
              <CopyContent content={user?.testApiKey} />
            </Copyable>
          </UserDatas>
        ) : (
          <UserDatas>
            <Title>Developer</Title>
            <button onClick={generateKeys}>Generate Api Keys</button>
          </UserDatas>
        )}

        {sessions?.length > 0 && (
          <UserDatas>
            <Title>Sessions</Title>
            {sessions.map((session:any) => (
              <SessionProfileWrapper>
              <SessionProfile isActive={session.isActive}>
                <h1>{session?.deviceName}</h1>
                <p>{`Logged in at ${getDate(session?.createdAt)} ${getTime(session?.createdAt)}`}</p>
                <p>{session?.isActive ? 'Active' : 'Inactive'}</p>
              </SessionProfile>
              <span onClick={()=>handleDelete(session._id)} style={{cursor: 'pointer'}}><img src="/logout.png" width={25} alt="" /></span>
              </SessionProfileWrapper>
            ))}
          </UserDatas>
        )}
      </UserProfile>
    </Wrapper>
  );
}

export default ProfileScreen;

export const Wrapper = styled.form`
  padding: 0;
  background-color: #f5f6f6;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  gap: 50px;
  /* justify-content: space-between; */
  align-items: center;
`;

const UserProfile = styled.main`
  background-color: #ffffff;
  width: 60%;
  margin: 100px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  border-radius: 16px;
  padding: 20px 0px 60px 0px;

  @media (max-width: 600px) {
    width: 95%;
  }
`;

const SessionProfile = styled.main<{ isActive: boolean }>`
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  h1 {
    font-size: 18px;
  }
  p:last-child{
    color: ${(props) => props.isActive ? "green" : "#ba2626"};;
    font-weight:500;
  }
`;


const SessionProfileWrapper = styled.div`
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const UserImage = styled.div`
  height: 88px;
  width: 88px;
  background-color: ${colours.background};
  border-radius: 50%;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const UserName = styled.h2`
  color: #333333;
  font-size: 16px;
`;

const UserRole = styled.p`
  display: flex;
  align-items: flex-start;
  gap: 5px;
`;
const UserDatas = styled.div`
  display: flex;
  padding: 30px;
  flex-direction: column;
  width: 80%;
  border: 0.5px solid #13131a;
  text-align: left;
  border-radius: 5px;
  margin-top: 20px;

  label {
    color: #333333;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    margin-top: 10px;
  }

  @media (max-width: 600px) {
    width: 95%;
  }
`;
const UserInfo = styled.input`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  padding: 10px;
`;

const Copyable = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #333333;
  border-radius: 5px;
  border: 0.5px solid #13131a;
  background: #f2f2f2;
  padding: 10px;
`;
const Title = styled.h2`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;

  /* New Orange */

  color: #f66d00;
`;
