import React from "react";
import styled from "styled-components";
import colours from "../styles/appColours";

const Terms = () => {
  return (
    <Container>
      <Wrapper>
        <Headers>Important Notice</Headers>
        <Text>
          When ordering eFTD Africa services you, as “Shipper”, are agreeing, on
          your behalf and on behalf of the receiver of the Shipment (“Receiver”)
          and anyone else with an interest in the Shipment that these Terms and
          Conditions shall apply “Shipment” means all documents or parcels that
          travel under one waybill and which may be carried by any means eFTD
          Africa chooses, including air, road or any other carrier. A “waybill”
          shall include any Shipment identifier or document produced by eFTD
          Africa or Shipper automated systems such as a label, barcode, waybill
          or consignment note as well as any electronic version thereof. Every
          Shipment is transported on a limited liability basis as provided
          herein. If Shipper requires greater protection, then insurance may be
          arranged at an additional cost. (Please see below for further
          information). “eFTD” means any member of the eFTD Africa Team.
        </Text>
        <Headers>Customs Clearance</Headers>
        <Text>
          eFTD Africa may perform any of the following activities on Shipper’s
          or Receiver’s behalf in order to provide its services: i. Complete any
          documents, amend product or service codes, and pay any duties, taxes
          or penalties required under applicable laws and regulations (“Customs
          Duties”), ii. Act as Shipper’s forwarding agent for customs and export
          control purposes and as Receiver solely for the purpose of designating
          a customs broker to perform customs clearance and entry and iii.
          Redirect the Shipment to Receiver’s customs broker or other address
          upon request by any person who eFTD Africa believes in its reasonable
          opinion to be authorized.
        </Text>
        <Headers>Unacceptable Shipments</Headers>
        <Text>
          A Shipment is deemed unacceptable if: i. No customs declaration is
          made when required by applicable customs regulations, ii. It contains
          counterfeit goods, animals, bullion, currency, gemstones; weapons,
          explosives and ammunition; human remains; illegal items, such as ivory
          and narcotics, iii. It is classified as hazardous material, dangerous
          goods, prohibited or restricted articles by IATA (International Air
          Transport Association), ICAO (International Civil Aviation
          Organization), ADR (European Road Transport Regulation on dangerous
          goods) or other relevant organization (“Dangerous Goods”), iv. Its
          address is incorrect or not properly marked or its packaging is
          defective or inadequate to ensure safe transportation with ordinary
          care in handling, v. It contains any other item which eFTD Africa
          decides cannot be carried safely or legally Deliveries and
          Undeliverable Shipments cannot be delivered to PO boxes or postal
          codes. Shipments are delivered to the Receiver’s address given by
          Shipper but not necessarily to the named Receiver personally.
          Shipments to addresses with a central receiving area will be delivered
          to that area. eFTD Africa may notify the Receiver of an upcoming
          delivery or a missed delivery. Receivers may be offered alternative
          delivery options such as delivery on another day, no signature
          required, redirection or collection at a eFTD Africa Outstation.
          Shippers may exclude some delivery options on request. If the Shipment
          is deemed to be unacceptable as described in Section 2, or it has been
          undervalued for customs purposes, or Receiver cannot be reasonably
          identified or located, or Receiver refuses delivery or to pay Customs
          Duties or other Shipment charges, eFTD Africa shall use reasonable
          efforts to return the Shipment to Shipper at Shipper’s cost, failing
          which the Shipment may be released, disposed of or sold without
          incurring any liability whatsoever to Shipper or anyone else, with the
          proceeds applied against Customs Duties, Shipment charges and related
          administrative costs with the balance of the proceeds of a sale to be
          returned to Shipper. eFTD Africa shall have the right to destroy any
          Shipment which any law prevents eFTD Africa from returning to Shipper
          as well as any Shipment of Dangerous Goods.
        </Text>
        <Headers>Inspection</Headers>
        <Text>
          eFTD Africa has the right to open and inspect a Shipment without
          notice for safety, security, customs or other regulatory reasons.
          Shipment Charges and Fees eFTD Africa’ Shipment charges are calculated
          according to the higher of actual or volumetric weight per piece and
          any piece may be re-weighed and re-measured by eFTD Africa to confirm
          this calculation. Shipper, or the Receiver when eFTD Africa acts on
          Receiver’s behalf, shall pay or reimburse eFTD Africa for all Shipment
          or other charges due, or Customs Duties owed for services provided by
          eFTD Africa or incurred by eFTD Africa on Shipper’s or Receiver’s
          behalf. Payment of Customs Duties may be requested prior to delivery.
        </Text>
        <Headers>eFTD Africa Liability</Headers>
        <Text>
          6.1 eFTD Africa liability is strictly limited to direct loss and
          damage to a Shipment only and to the per kilogram limits. All other
          types of loss or damage are excluded (including but not limited to
          lost profits, income, interest, future business), whether such loss or
          damage is special or indirect, and even if the risk of such loss or
          damage was brought to eFTD Africa attention. eFTD Africa will make
          every reasonable effort to deliver the Shipment according to eFTD
          Africa regular delivery schedules, but these schedules are not binding
          and do not form part of the contract. eFTD Africa is not liable for
          any damages or loss caused by delay, but for certain Shipments,
          Shipper may be able to claim limited delay compensation under the
          Money Back Guarantee terms and conditions.
        </Text>
        <Headers>Claims</Headers>
        <Text>
          All claims must be submitted in writing to eFTD Africa within thirty
          (30) days from the date that eFTD Africa accepted the Shipment,
          failing which eFTD Africa shall have no liability whatsoever. Claims
          are limited to one claim per Shipment, settlement of which will be
          full and final settlement for all loss or damage in connection
          therewith.
        </Text>
        <Headers>Shipment Insurance</Headers>
        <Text>
          eFTD Africa may be able to arrange insurance covering the value in
          respect of loss of or damage to the Shipment, provided that the
          Shipper so instructs eFTD Africa in writing, including by completing
          the insurance section on the front of the waybill or by eFTD Africa’
          automated systems and pays the applicable premium. Shipment insurance
          does not cover indirect loss or damage, or loss or damage caused by
          delays. Circumstances Beyond eFTD Africa Control eFTD Africa is not
          liable for any loss or damage arising out of circumstances beyond eFTD
          Africa’ control. These include but are not limited to electrical or
          magnetic damage to, or erasure of, electronic or photographic images,
          data or recordings; any defect or characteristic related to the nature
          of the Shipment, even if known to eFTD Africa; any act or omission by
          a person not employed or contracted by eFTD Africa - e.g. Shipper,
          Receiver, third party, customs or other government official; “Force
          Majeure” - e.g. earthquake, cyclone, storm, flood, fog, war, plane
          crash, embargo, riot, civil commotion, or industrial action. Shipper’s
          Warranties and Indemnities Shipper shall indemnify and hold eFTD
          Africa harmless for any loss or damage arising out of Shipper’s
          failure to comply with the following warranties and representations: •
          all information provided by Shipper or its representatives is complete
          and accurate. • The Shipment is acceptable for transport under Section
          2 above. • The Shipment was prepared in secure premises by reliable
          persons and was protected against unauthorized interference during
          preparation, storage and any transportation to eFTD Africa. • Shipper
          has complied with all applicable customs, import, export, data
          protection laws, sanctions, embargoes and other laws and regulations;
          and • Shipper has obtained all necessary consents in relation to
          personal data provided to eFTD Africa including Receiver’s data as may
          be required for transport, customs clearance and delivery, such as
          e-mail address and mobile phone number.
        </Text>
        <Headers>Routing</Headers>
        <Text>
          Shipper agrees to all routing and diversion, including the possibility
          that the Shipment may be carried via intermediate stopping places.
        </Text>
        <Headers>Governing Law</Headers>
        <Text>
          Any dispute arising under or in any way connected with these Terms and
          Conditions shall be subject, for the benefit of eFTD Africa, to the
          non-exclusive jurisdiction of the courts of, and governed by the law
          of the country of origin of the Shipment and Shipper irrevocably
          submits to such jurisdiction, unless contrary to applicable law
        </Text>
        <Headers> Severability</Headers>
        <Text>
          The invalidity or unenforceability of any provision shall not affect
          any other part of these Terms and Conditions.
        </Text>
        {/* <ButtonWrapper>
          <Buttons onClick={handleclick}>I Agree</Buttons>
        </ButtonWrapper> */}
      </Wrapper>
    </Container>
  );
};

export default Terms;

const Container = styled.main`
  background-color: ${colours.background};
`;

const Wrapper = styled.div`
  width: 80%;
  margin: auto;
  padding: 20px;
`;

const Text = styled.p`
  font-weight: 300;
  font-size: 16px;
  font-style: normal;
  text-align: left;
  margin-bottom: 20px;
`;

const Headers = styled.h1`
  color: ${colours.primary};
  font-size: 24px;
`;
