import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import styled from "styled-components";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "none",
  boxShadow: 2,
  borderRadius: "4px",
  p: 2,
  outline: "none",
};

type Props = {
message?: string;
  feedback: string;
  open: boolean;
  close: any;
  height?: any;
};

export default function Feedback({
  message,
  open,
  close,
  height,
  feedback,
}: Props) {
  return (
    <>
      {open ? (
        <Modal
          open={true}
          onClose={close}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={{ ...style, height: height }}>
            <Typography
              style={{
                borderBottom: "1px solid #E0E0E0",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
              id="modal-modal-title"
              variant="h6"
              component="h2"
            >
              <span style={{ textTransform: "capitalize" }}>{feedback}</span>
              <span style={{cursor: 'pointer'}} onClick={close}>
                <img src="/close.png" width={20} height={20} alt="" />
              </span>
            </Typography>
            <div style={{ margin: "30px 0px" }} id="modal-modal-description">
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "5px",
                  marginTop: "20px",
                }}
              >
                <img src={feedback === "success" ? "/success.png" : "/error.png"} alt="" width={40} height={40} />
                <Text>{message}</Text>
              </div>
            </div>
            <Button onClick={close}>Continue</Button>
          </Box>
        </Modal>
      ) : null}
    </>
  );
}


const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  color: #000000;
`;

const Button = styled.button`
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 24px 16px;
  width: 300px;
  height: 45px;
  background: #04030b;
  border-radius: 10px;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  color: #ffffff;
  border: none;
  margin-bottom: 20px;
`;
