import FundWalletScreen from "../components/FundWalletScreen";
import ResponsiveDrawer from "../components/Sidebar";

const Fundwallet = () => {
  return (
    <ResponsiveDrawer title="Fund wallet" content={<FundWalletScreen />} />
  );
};

export default Fundwallet;
