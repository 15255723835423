import { ReactElement } from "react";

interface props {
  TrueCondition: ReactElement;
  FalseCondition: ReactElement;
  isTrue: Boolean; 
}

export const RenderBetween = ({ TrueCondition, FalseCondition, isTrue }: props) => {
  return isTrue ? TrueCondition : FalseCondition;
};
