import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { BsTwitter, BsInstagram, BsLinkedin, BsWhatsapp, BsMedium } from "react-icons/bs";
import "./footer.css";

export default function LandingFooter() {
  const toDate = new Date();
  const thisyear = toDate.getFullYear();
  return (
    <footer className="wrapper">
      <Top>
        <div>
          <img width={50} height={50} src="/logo2.svg" alt="eftd logo" />
          {/* <p>Reliability, efficiency and sustainability……</p> */}
        </div>
        <section>
          <ul>
            <h4>Company</h4>
            <li>
              <Link to={'/about'}>About Us</Link>
              </li>
            <li>
             
              <a href="/#why-choose-us">Why Choose Us?</a>
              </li>
              <li>
              <Link to={'/policy'}>Privacy Policy</Link>
              </li>
              <li>
              <Link to={'/riderterms'}>Rider's Terms</Link>
              </li>
          </ul>
          <ul>
            <h4>Our Service</h4>
            <li><a href="/#last-mile-delivery">Last-mile Delivery</a> </li>
            <li><a href="/#battery-swapping">Battery Swapping</a> </li>
            <li><a href="/#charging-stations">Charging Stations</a> </li>
            <li><a href="/#ebike-sales">eBike Sales and Financing</a> </li>
          </ul>
          <ul>
            <h4>Contact Us</h4>
            <li>info@eftdafrica.com</li>
            <li>+2349154368523</li>
            <li>+2347012195355</li>
          </ul>
          <ul>
            <h4>Our Locations</h4>
            <li>42, Local Airport Road, Ikeja, Lagos.</li>
            <li>
              1, Bassie Ogamba str. Off Adeniran Ogunsanya, Surulere, Lagos.
            </li>
          </ul>
        </section>
      </Top>
      <Bottom>
        <div>
          <a href="https://twitter.com/eftdafrica">
            <div className="socials">
              <BsTwitter />
            </div>
          </a>
          <a href="https://instagram.com/eftdafrica">
            <div className="socials">
              <BsInstagram />
            </div>
          </a>
          <a href="https://linkedin.com/company/eftdafrica">
            <div className="socials">
              <BsLinkedin />
            </div>
          </a>
          <a href="https://wa.me/+2347034662242">
            <div className="socials">
              <BsWhatsapp />
            </div>
          </a>
          <a href="https://medium.com/@eftdafrica">
            <div className="socials">
              <BsMedium />
            </div>
          </a>
        </div>
        <div>&copy; {`${thisyear} eFTD Africa. All rights reserved.`}</div>
      </Bottom>
    </footer>
  );
}

const Top = styled.div`
  display: flex;
  max-width: 1920px;
  margin: 50px auto;
  padding: 100px 0px;

  > div {
    width: 20%;
    text-align: left;
    padding: 20px;

    > p {
      font-size: 18px;
    }
  }
  > section {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    > ul {
      width: 25%;
      padding: 20px;
      min-width: 180px;
      > h4 {
        font-size: 24px;
        line-height: 34px;
        color: #31eaf4;
        text-align: left;
      }

      > li {
        list-style: none;
        text-align: left;
        font-size: 18px;
        margin: 10px 0px;
        cursor: pointer;
      }

      > li:hover {
        color: #31eaf4;
      }
    }
  }

  @media screen and (max-width: 1440px) {
    width: 90%;
    padding: 20px 10px;
  }

  @media screen and (max-width: 768px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > section {
      width: 100%;
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: 600px) {
    flex-direction: column;
    > div {
      width: 100%;
    }
    > section {
      width: 100%;
    }
  }
`;
const Bottom = styled.div`
  max-width: 1920px;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #ffffff;
  padding: 20px 0px;
  > div {
    display: flex;
    gap: 30px;
  }

  .socials {
    height: 30px;
    width: 30px;
    background-color: #ffffff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #000000;
    cursor: pointer;
  }

  .socials:hover {
    color: #31eaf4;
    background-color: #000000;
  }

  @media screen and (max-width: 1440px) {
    width: 90%;
    padding: 20px 0px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 20px;
  }
`;
