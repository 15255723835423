import SubscriptionScreen from "../components/SubscriptionScreen";
import ResponsiveDrawer from "../components/Sidebar";

const Subscription = () => {
  return (
    <ResponsiveDrawer title="Subscriptions" content={<SubscriptionScreen />} />
  );
};

export default Subscription;
