import React, { useState } from "react";
import {useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Link } from "react-router-dom";
import {FaBars} from 'react-icons/fa'
import "./header.css"

interface NavObject{
  route: string;
  name: string;
}

type NavLists = {
  navlist: NavObject[];
};

const placeorderNavList = [
  { route: "/", name: "Home" },
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

export default function Header({navlist}:NavLists) {
  const location = useLocation()
  const route = '/' + location.pathname.split('/')[1]
  const token = localStorage.getItem('token')
  const [openNav, setOpenNav] = useState(false);
  const responsiveNav = () => {
    setOpenNav(!openNav)
  };
  return (
    <div className="wrap">
      <header className="wrapper-nav">
        <Link to={"/"}>
          <Logo>
            <img width={50} height={50} src="/logo2.svg" alt="eftd logo" />
          </Logo>
        </Link>
        <nav className={`navlist ${openNav ? "show" : "hidden"}`}>
          {placeorderNavList.map((list, index) => (
            <NavList style={{color: list.route === route ? '#f66d00': ''}} key={index}>
              <Link to={list.route}>{list.name}</Link>
            </NavList>
          ))}
          {!token && (
            <NavList>
              <Link to={"/signup"}>Signup</Link>
            </NavList>
          )}
          {!token && (
            <NavButton className="login-button">
              <Link to={"/login"}>Login</Link>
            </NavButton>
          )}
        </nav>
        <p className="icon" onClick={responsiveNav}>
          <FaBars color="#000000" fontSize={'30px'} />
        </p>
      </header>
    </div>
  );
}


const Logo = styled.div`
  width: 40%;
  text-align: left;
`;


const NavList = styled.li`
  color: #000000;
  &:hover {
    color: #f66d00;
  }
  @media screen and (max-width: 881px) {
    text-align: left;
    margin: 10px 0;
  }
`;

const NavButton = styled.li`
  background-color: #000000;
  color: #ffffff;
  padding: 10px 30px;
  border-radius: 5px;

  Link {
    color: #ffffff;
  }
  @media screen and (max-width: 600px) {
  }
`;
