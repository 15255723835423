import styled from "styled-components";
import { useState, useEffect } from "react";
import { BsPerson } from "react-icons/bs";
import { useParams, useLocation, Link } from "react-router-dom";
import axios from "axios";
import colours from "../styles/appColours";

function RiderDetails() {
  const { riderID } = useParams();
  const orderId = useLocation();
  const [data, setData] = useState<any>({});
  const token = localStorage.getItem("token");
  const baseUrl = process.env.REACT_APP_PUBLIC_BASE_URL as string;
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  useEffect(() => {
    axios
      .get(`${baseUrl}api/admin/riders/getriders/${riderID}`, config)
      .then((res) => {
        setData(res?.data?.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [riderID]);
  return (
    <Wrapper>
      <Content>
        <Otp>
          <Image>
            <img
              src={"/enterotp.png"}
              height={160}
              width={160}
              alt="enter otp"
            />
          </Image>
          <TopTitle>OTP</TopTitle>
          <Text>
            An OTP code has been sent to your registered phone number, kindly
            give this code to the Rider.
          </Text>
        </Otp>
        <Rider>
          <div>
            <Title>Rider Information</Title>
            <RiderInfo>
              {!data?.profilePhoto ? <BsPerson fontSize={'40px'}/> : <img
                style={{ borderRadius: "50%" }}
                src={data?.profilePhoto}
                height={50}
                width={50}
                alt="rider avatar"
              />}
              <RiderName>
                <RiderNameText>{data?.fullName}</RiderNameText>
                <RiderNumberText>Arrives soon</RiderNumberText>
                <RiderNumberText>{data?.phoneNumber}</RiderNumberText>
              </RiderName>
            </RiderInfo>
          </div>
          <div>
          <img
                style={{ borderRadius: "50%" }}
                src={"/riderotpimage.png"}
                height={50}
                width={50}
                alt="rider avatar"
              />
               <RiderNameText><Link to={`/order/${orderId?.state}`}>{orderId?.state}</Link></RiderNameText>
          </div>
          {/* <Link to = {}>
          <TrackOrderButton>Track your order</TrackOrderButton>
        </Link> */}
        </Rider>
      </Content>
    </Wrapper>
  );
}

export default RiderDetails;

const Wrapper = styled.main`
  width: 100%;
  height: 100%;
  min-height: 100vh;
  background-color: ${colours.background};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 60%;
  max-width: 700px;
  padding: 30px;
  margin: 0 auto;
  background-color: ${colours.white};
  border-radius: 30px;
  margin-top: 80px;
  margin-bottom: 70px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Otp = styled.section`
  height: 100%;
  text-align: center;
  width: 100%;
  padding-top: 40px;
`;

const Image = styled.div``;

const Rider = styled.aside`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colours.background};
  gap: 20px;
  /* padding-top: 40px; */
  padding: 20px 20px;
`;

const Text = styled.p`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  color: #333333;
`;
const RiderNameText = styled.p`
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 21px;
text-align: left;

/* Gray 1 */

color: #333333;
`
const RiderNumberText = styled.p`
font-family: 'Red Hat Display';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 19px;
text-align: left;
margin-top: 2px;

color: #333333;
`
const Title = styled.h4`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 21px;
  text-align: left;
  color: #04030b;
  margin-bottom: 10px;
`;

const TopTitle = styled.h4`
  font-family: "Red Hat Display";
  font-style: normal;
  font-weight: 900;
  font-size: 32px;
  line-height: 42px;

  /* Gray 1 */

  color: #333333;
  @media (max-width: 425px) {
    margin: 0px;
  }
`;
const RiderInfo = styled.div`
  margin: 0 auto;
  text-align: center;
  border-radius: 12px;
  display: flex;
  align-items: flex-start;
  gap: 15px;
`;

const RiderName = styled.div`
  text-align: left;
  @media (max-width: 425px) {
    width: 60%;
    font-size: 14px;
  }
`;

// const TrackOrderButton = styled.button`
//   background-color: ${colours.secondary};
//   height: 53px;
//   width: 80%;
//   border-radius: 8px;
//   border: none;
//   color: ${colours.black};
//   font-size: 18px;
//   font-weight: 500;
//   cursor: pointer;

//   @media (max-width: 425px) {
//     width: 80%;
//     margin-bottom: 30px;
//   }
// `;
