import styled from "styled-components";
import {useQuery} from "react-query"
import { useState } from "react";
import { RenderBetween } from "./RenderBetween";
import colours from "../styles/appColours";
import Loader from "./Loader";
import { getFormattedDate, getTime } from "../utils/utils";
import BasicModal from "./Modal";
import api from "../services/api";

const WalletBalanceScreen = () => {
  const apiRequest =  api()
  const [state, setState]=useState(false)
  const [hideAmount, setHideAmount] = useState(false);
  const { data: transactions, isLoading } = useQuery("TRANSACTIONS",  () => apiRequest.getTransactions());
  const { data: walletBalance } = useQuery("WALLET_BALANCE",  () => apiRequest.getWalletbalance());

  console.log({transactions})
  
  if (isLoading) return <Loader />;
  return (
    <Wrapper>
      <CardBalance>
        <Title>Wallet Balance</Title>
        <Card>
          <p>Balance</p>
          <RenderBetween
            isTrue={hideAmount}
            TrueCondition={
              <Amount>NGN{(Number(walletBalance) / 100).toLocaleString()}</Amount>
            }
            FalseCondition={
              <VisibleAmount>
                NGN{walletBalance ? (Number(walletBalance) / 100).toLocaleString() : "0.00"}
              </VisibleAmount>
            }
          />
          <FundWalletButton onClick={()=>setState(true)}>
              <img
                src={"/walleticon.png"}
                height={20}
                width={20}
                alt="wallet icon"
              />
              <span>Fund wallet</span>
            </FundWalletButton>
        </Card>
      </CardBalance>
      <Transaction>
        <Title>Transaction History</Title>
        <TransactionTable>
          {transactions?.length > 0 ? (
            transactions?.map((item: any, index: any) => (
              <TransactionData key={index}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    gap: "20px",
                    width: "100%",
                  }}
                >
                  <ImageIcon>
                    <img
                      src={
                        item.isInflow ? "/paidicon.svg" : "/receivedicon.svg"
                      }
                      alt="paid icon"
                    />
                  </ImageIcon>
                  <Date>
                    <p className="day">{getFormattedDate(item?.createdAt)}</p>
                    <p>{getTime(item?.createdAt)}</p>
                  </Date>
                </div>
                {item.isInflow ? (
                  <Paid>N{(item?.amount / 100).toLocaleString()}</Paid>
                ) : (
                  <Received>N{(item?.amount / 100).toLocaleString()}</Received>
                )}
              </TransactionData>
            ))
          ) : (
            <p>No transaction done yet</p>
          )}
        </TransactionTable>
      </Transaction>
      <BasicModal close={()=>setState(false)} open={state} content={
        <div style={{display:'flex', height: '100%',padding: '50px', alignItems:'center', flexDirection:'column', justifyContent: 'space-between'}}>
          <img width={100} height={100} src="/logo2.svg" alt="" />
          <p style={{ fontSize: '16px',fontWeight:'600'}}>Coming Soon</p>
          <button style={{width: '90%', border:'none', background:'#000000', color:'#ffffff', padding: '15px 0px', fontSize: '16px',fontWeight:'600', borderRadius:'4px'}} onClick={()=>setState(false)}>Ok</button>
        </div>
      } width={400} height={300}/>
    </Wrapper>
  );
};

export default WalletBalanceScreen;

const Wrapper = styled.main`
  height: 100%;
  min-height: 100vh;
  background-color: ${colours.background};
  width: 100%;
  display: flex;
  align-items: flex-start;
  overflow-y: hidden;
  @media (max-width: 1024px) {
    margin-bottom: 40px;
    flex-direction: column;
    overflow-y: scroll;
  }
`;

const CardBalance = styled.aside`
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
  margin-top: 150px;
  @media (max-width: 1024px) {
    gap: 20px;
    height: 100%;
    width: 100%;
  }
`;
const Transaction = styled.aside`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 150px;
  @media (max-width: 1024px) {
    width: 100%;
    margin: 10px 0;
  }
`;
const TransactionData = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 80%;
  margin: 20px auto;
`;
const TransactionTable = styled.div`
  width: 90%;
  background-color: #ffffff;
  height: 80vh;
  overflow: auto;
  border-radius: 12px;
  @media (max-width: 1024px) {
    height: 100%;
    margin-top: 20px;
  }
`;

const ImageIcon = styled.div`
  width: 10%;
`;

const Title = styled.h2`
  @media (max-width: 600px) {
    font-size: 1rem;
    padding-top: 0px;
  }
`;
const Span = styled.span`
  width: 55%;

  :last-child {
    width: 20%;
  }
`;

const Date = styled.div`
  text-align: left;
  width: 100%;
  p {
    color: #545454;
    font-weight: 400;
    font-size: 16px;
  }
`;

const Paid = styled(Span)`
  color: #e51e1e;
  font-weight: 500;
`;

const Received = styled(Span)`
  color: #1bb83e;
  font-weight: 500;
`;

const FundWalletButton = styled.button`
  width: 300px;
  color: ${colours.black};
  background-color: ${colours.secondary};
  height: 50px;
  border: none;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
  font-weight: 600;
  font-size: 16px;
  @media (max-width: 425px) {
    width: 80%;
  }
`;
const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 30px; */
  /* height: 150px; */
  width: 350px;
  background: #1c1d21;
  border-radius: 10px;
  padding: 30px;
  p{
    color: #ffffff;
    text-align: left;
    width: 100%;
    margin: 0px;
  }
  @media (max-width: 600px) {
    width: 80%;
  }
`;

const Amount = styled.h2`
  color: #ffffff;
  color: transparent;
  text-shadow: 0 0 15px #ffffff;
  text-align: left;
  width: 100%;
  margin-left: 30px;

`;

const VisibleAmount = styled.h2`
  color: #ffffff;
  text-align: left;
  width: 100%;
  margin-bottom: 30px;
`;


