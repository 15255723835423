import styled from "styled-components";

interface Toggletype {
    toggle: ()=>void;
}

const Subinfo = ({toggle}:Toggletype) => {
  return (
    <Wrapper>
      <button onClick={toggle}>x</button>
      <div>
        <img src="/logo2.svg" alt="" />
      </div>
      <h1>Terms of Service for Subscribers</h1>
      <ol>
        <li>
          Our pickup time is from 7 a.m. to 10 a.m. from Monday to Saturday.
          Items are sorted and batched at our hubs before delivery takes place.
        </li>
        <li>
          Packages that are picked up in the morning get delivered before the
          close of business on the same day
        </li>
        <li>
          The locations listed below are on the outskirts of Lagos, we do not
          pick up from these locations:
          <div className="list-div">
            <ul>
              <li>Badagry</li>
              <li>Ajangbadi</li>
              <li>Agbara,</li>
              <li>Opic</li>
            </ul>
            <ul>
              <li>Ojo</li>
              <li>Ibeju</li>
              <li>Awoyaya</li>
              <li>Epe</li>
            </ul>
            <ul>
              <li>Jankara</li>
              <li>Alakuko</li>
              <li>Alagbole</li>
              <li>Jankara</li>
            </ul>
            <ul>
              <li>Ishaga Road</li>
              <li>Ikorodu</li>
            </ul>
          </div>
          {/* however, to deliver to any of these locations kindly click{" "}
          <a href="https://www.eftdafrica.com/interstate">here</a> */}
        </li>
        <li>
          {" "}
          Packages weighing more than 3kg or larger than 245 cubic inches (7
          inches x 7 inches x 5 inches) are not covered under the subscription
          plan.
        </li>
        <li>
          We do not ship fragile & perishable packages such as food items.
        </li>
      </ol>
    </Wrapper>
  );
};

export default Subinfo;

const Wrapper = styled.main`
  background: #000000;
  color: #ffffff;
  height: 100%;
  overflow: scroll;
  padding: 30px 30px 30px 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 20px;


  > div {
  }

  ol {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  a {
    color: #eb5757;
    font-weight: 600;
  }
  .list-div {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;

    ul {
      width: 100px;
      max-width: 100px;
    }
  }
  li {
    font-size: 20px;
  }
  button {
    background: inherit;
    border: none;
    color: #fff;
    font-size: 24px;
    position: absolute;
    top: 0;
    left: 20px;
  }
  @media (max-width: 600px) {
    >div {
      margin-top: 300px;
    }

  }

  @media (max-width: 375px) {
    >div {
      margin-top: 400px;
    }
  }
`;
