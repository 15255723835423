import styled from "styled-components";
import axios from "axios";
import parse from "html-react-parser";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../components/footer/Footer";
import Header from "../components/header/Header";
import utils, { getDate } from "../utils/utils";

const placeorderNavList = [
  { route: "/about", name: "About Us" },
  { route: "/home", name: "Place Order" },
  { route: "/blog", name: "Blog" },
  { route: "/track", name: "Track Order" },
  { route: "/pricing", name: "Pricing" },
];

const Blog = () => {
  const [data, setData] = useState<any>([]);
  const navigate = useNavigate();

  useEffect(() => {
    axios
      .get(
        "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@eftdafrica"
      )
      .then((res) => {
        console.log(res?.data?.items);
        setData(res?.data?.items);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  return (
    <Wrapper>
      <Header navlist={placeorderNavList} />
      <Content>
        {data.length > 0
          ? data.map((content: any, index: any) => (
              <div onClick={()=>navigate(`/blog/${utils.getBlogTitle(content?.title)}`)} key={index}>
                   <h3>{parse(content?.title)}</h3>
                   <img src={content?.thumbnail} alt="" />
                   <p className="categories">{content?.categories.map((category: string)=> (
                    <span className="category">{category}</span>
                   ))}</p>
                    <p className="date">{getDate(content?.pubDate)}</p>
              </div>
            ))
          : null}
      </Content>
      <Footer />
    </Wrapper>
  );
};

export default Blog;

const Wrapper = styled.main`
  margin: 0;
  margin-top: 150px;
  @media screen and (max-width: 600px) {
    margin-top: 100px;
  }
`;

const Content = styled.main`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
  width: 90%;
  margin: auto;
  text-align: left;
  line-height: 28px;
  font-family: "Red Hat Display";
  font-weight: 400;
  font-size: 18px;

  >div {
    max-width: 600px;
    cursor: pointer;

    img {
      width: 100%;
      height: 500px;
    }
  }

  .date {
    font-weight: 500;
    color: #000; 
  }
  .categories{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    font-weight:400;
  }
  .category{
    background-color: #ec7216;
    background: #f5f6f6;
    padding: 5px;
    text-transform: capitalize;
  }

  .ellipsis {
      /* Ensure the element has a fixed width or max-width */
      width: 200px; /* Adjust to your desired width */

      /* Clip the text that overflows the container */
      overflow: hidden;

      /* Prevent text from wrapping to the next line */
      white-space: nowrap;

      /* Display ellipsis (...) when text is clipped */
      text-overflow: ellipsis;
    }

  h3 {
    font-size: 36px;
    margin: 20px 0;
    line-height: 36px;
    height: 150px;
  }

  h4 {
    margin-bottom: 20px;
  }

  p {
    margin: 20px 0;
  }

  figure {
    width: 100%;
    img {
      width: 100%;
    }
  }

  @media screen and (max-width: 600px) {
    margin-top: 100px;

    h3 {
    font-size: 24px;
    margin: 20px 0;
    line-height: 28px;
    height: 120px;
  }
  >div {
    max-width: 600px;
    cursor: pointer;

    img {
      width: 100%;
      height: 400px;
    }
  }
  }

  @media screen and (max-width: 450px) {
    margin-top: 100px;

    h3 {
    font-size: 24px;
    margin: 20px 0;
    line-height: 28px;
    height: 120px;
  }
  >div {
    max-width: 600px;
    cursor: pointer;
    
    img {
      width: 100%;
      height: 300px;
    }
  }
  }
`;
