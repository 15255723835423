import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../components/Button";
import { BsEyeFill, BsEyeSlash } from "react-icons/bs";
import Loader from "../components/Loader";
import colours from "../styles/appColours";
import Spinner from "../components/Spinner";

function Delete() {
  const [showName, setShowName] = useState({
    email: false,
    password: false,
  });
  const [passwordShown, setPasswordShown] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const handleToggle = () => {
    setPasswordShown(!passwordShown);
  };

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });
  const handleChange = (e: any) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault()
    setIsLoading(true)
    toast.success('Account Deleted')
    setFormData({email: "",password: "",})
    setIsLoading(false)
  }
  return (
    <Wrapper>
      <Link to="/">
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "30px",
            gap: "10px",
          }}
        >
          <img width={50} height={50} src="/logo2.svg" alt="" />

          <p style={{ color: "#333333", fontWeight: 700, fontSize: "2rem" }}>
            eFTD Africa
          </p>
        </div>
      </Link>

      <Top>
        <Title>Delete your account</Title>
        <section>
          <div style={{ textAlign: "left" }}>
            {showName?.email && <label>Email</label>}
            <InputWrapper>
              <Input
                type="text"
                name="email"
                onChange={(e) => {
                  handleChange(e);
                  setShowName((prev) => {
                    return { ...prev, email: true };
                  });
                }}
                required
                placeholder="Enter your Email Address"
              />
            </InputWrapper>
          </div>

          <div style={{ textAlign: "left" }}>
            {showName?.password && <label>Password</label>}
            <InputWrapper>
              <Input
                type={passwordShown ? "text" : "password"}
                name="password"
                onChange={(e) => {
                  handleChange(e);
                  setShowName((prev) => {
                    return { ...prev, password: true };
                  });
                }}
                placeholder="Password"
                required
              />
              <EyeIcon onClick={handleToggle}>
                {!passwordShown ? <BsEyeFill /> : <BsEyeSlash />}
              </EyeIcon>
            </InputWrapper>
          </div>
        </section>
        <AuthButtons
          style={{ color: "#ffffff", width: "100%", backgroundColor: "#000" }}
          onClick={handleSubmit}
        >{isLoading ? <Spinner /> : "Delete your account"}</AuthButtons>
      </Top>

    </Wrapper>
  );
}

export default Delete;

//styles
const Wrapper = styled.section`
  background-color: #f8f9fb;
  min-height: 100vh;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const AuthButtons = styled.button`
  height: 53px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;

  @media (max-width: 425px) {
    width: 90vw;
  }
`;

export const Input = styled.input`
  display: block;
  height: 53px;
  width: 100%;
  max-width: 640px;
  font-size: 20px;
  border-radius: 8px;
  background-color: #f2f2f2;
  outline: none;
  border: none;
  :-webkit-autofill {
    background-color: #f2f2f2;
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }

  &:autofill {
    box-shadow: 0 0 0px 1000px #ffffff inset;
    -webkit-box-shadow: 0 0 0px 1000px #ffffff inset;
  }
  @media (max-height: 700px) {
    /* height: 40px; */
    /* margin-bottom: 10px; */
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  gap: 20px;
  background-color: #ffffff;
  label {
    color: #bdbdbd;
  }
`;

export const TermsCon = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Title = styled.h1`
  font-weight: 500;
`;

export const Icon = styled.p`
  width: 40vw;
  text-align: left;
  cursor: pointer;
  margin-top: 30px;
  @media (max-width: 600px) {
    width: 90vw;
  }
`;

export const Checkbox = styled.input`
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;


export const Links = styled.span`
  color: ${colours.primary};
`;

export const Top = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  gap: 30px;
  margin-bottom: 50px;
  background-color: #ffffff;
  padding: 50px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const Bottom = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 30px;
`;

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40vw;
  max-width: 100%;
  border-radius: 5px;
  border: 1px solid #bdbdbd;
  background-color: #f2f2f2;
  padding: 0 10px;
  margin-top: 10px;
  @media (max-width: 1200px) {
    width: 50vw;
  }
  @media (max-width: 600px) {
    width: 90vw;
  }
  @media (max-height: 700px) {
    height: 40px;
  }
`;

export const EyeIcon = styled.span`
  cursor: pointer;
`;
